import React, { useEffect, useState } from "react";
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsTreemap from "highcharts/modules/treemap";
import highchartsHeatmap from "highcharts/modules/heatmap";
import highchartsMore from "highcharts/highcharts-more";
import Spinner from "../Spinner";
import { ChartComponentTypes } from "../../types";
import { exportToCsv } from "./ChartExport";
import {
    newChannelsByTypeConfig,
    newCommunityEngagement,
    newCommunityMembers,
    newCommunityPostType,
    newEngagementPerPostTypeConfig,
    newMessagesByDayConfig,
    newMessagesHeatmapConfig,
    newPopularFeedConfig,
    newPostsAndCommentsConfig,
    newPostTypesConfig,
    newSocialHeatMapConfig,
    newTopCommentersConfig,
    newTopInfluencersConfig,
    newTopPostersConfig,
    newTopReactorsConfig,
    newTotalMessagesByChannelConfig,
    newTotalMessagesByTypeConfig,
    newUserEngagementConfig,
    newUserPercentageConfig,
    newUsersByDayConfig,
    newUserTrends,
    createPieChartConfig} from "./ChartOptions";
import Select from "react-select";

highchartsTreemap(Highcharts);
highchartsMore(Highcharts);
highchartsHeatmap(Highcharts);

const ChartComponent = ({chartData}: {chartData: ChartComponentTypes}) => {
    const [keyOptions, setKeyOptions] = useState({});
    var [currentIntervalOpt, setCurrentIntervalOpt] = useState('monthly');

    const intervalOptions = [
        { value: "daily", label: "Daily" },
        { value: "weekly", label: "Weekly" },
        { value: "monthly", label: "Monthly" },
    ];

    const monthIntervalOptions = [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
    ];

    let communityMemberDummyData = [
        {name: 'Join', y: 1226, details: ['234208543', '984572398', '293102908', '2341029470', '1123421231']},
        {name: 'Leave', y: 366, details: ['234208543', '984572398', '293102908', '2341029470', '1123421231']},
    ]

    useEffect(() => {

        setKeyOptions({
            "User Trends": newUserTrends(chartData),
            "New Posts & Comments": newPostsAndCommentsConfig(chartData),
            "Post Types": newPostTypesConfig(chartData),
            "Popular Feed" : newPopularFeedConfig(chartData),
            "Top Influencers" : newTopInfluencersConfig(chartData),
            "Top Posters" : newTopPostersConfig(chartData),
            "Top Commenters" : newTopCommentersConfig(chartData),
            "Top Reactors" : newTopReactorsConfig(chartData),
            "User Engagement" : newUserEngagementConfig(chartData),
            "User Engagement Heatmap" : newSocialHeatMapConfig(),
            "New Messages by day" : newMessagesByDayConfig(chartData),
            "Total Messages by Type" : newTotalMessagesByTypeConfig(chartData),
            "Users  by Day" : newUsersByDayConfig(chartData),
            "New Channel by Types" : newChannelsByTypeConfig(chartData),
            "Total Messages by Channel" : newTotalMessagesByChannelConfig(chartData),
            "Channels Count by Type": createPieChartConfig(chartData),
            "Messages Heatmap" : newMessagesHeatmapConfig(chartData),
            "Community Engagement" : newCommunityEngagement(),
            "Community Post Type" : newCommunityPostType(),
            "Community Member": newCommunityMembers(communityMemberDummyData),
            "Posts, Comments & Reactions": newUserPercentageConfig(),
            "Engagement by Post Type": newEngagementPerPostTypeConfig(),
        });

        return () => {
            setKeyOptions({});
        };
    }, [chartData]);

    // const onIntervalChange = (intervalBy: { label: string; value: string }) => {
    //     setCurrentIntervalOpt(intervalBy.value);
    //     chartData.mauType(intervalBy.value)
    // };

    // const onMonthIntervalChange = (intervalBy: { label: string; value: string }) => {
    //     chartData.mauMonth(intervalBy.value)
    // };

    return (
        <div className="card card-xl-stretch mb-5 mb-xl-8">

            <div className="card-header border-0 pt-5">
                <div className="flex">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder fs-3 mb-1">{chartData.cardTitle}</span>
                        <span className="text-muted fw-bold fs-7">{chartData.subtitle}</span>
                    </h3>
                    {/* {chartData.cardTitle == 'User Trends' &&
                    <div className="flex">
                        <div className="ml-3 flex">
                            <Select
                                onChange={onIntervalChange}
                                options={intervalOptions}
                                defaultValue={{
                                    label: "Monthly",
                                    value: "monthly",
                                }}
                                isDisabled={chartData.isLoading}
                            />
                        </div>
                        {
                            currentIntervalOpt !== 'daily' &&
                            <div className="ml-3 flex">
                                <span className="ml-3 mt-3 flex-column">Last</span>
                                <Select
                                    onChange={onMonthIntervalChange}
                                    options={monthIntervalOptions}
                                    defaultValue={{
                                        label: "3",
                                        value: "3",
                                    }}
                                    isDisabled={chartData.isLoading || currentIntervalOpt === 'daily'}
                                    className="ml-2"
                                />
                                <span className="ml-2 mt-3 flex-column">months</span>
                            </div>
                        }
                    </div>
                } */}
                </div>
                <div className="d-flex">
                    {/* {chartData.cardTitle == 'User Trends' &&
                        <div className="d-flex align-items-center">
                            <button className="bg-gray-500 text-white px-4 py-2 rounded mr-2" onClick={()=>chartData.reloadMau()} disabled={chartData.isLoading}>
                                <i className="fa fa-undo text-white" />
                            </button>
                        </div>
                    } */}
                    <div className="d-flex align-items-center">
                        <button className="bg-gray-500 text-white px-4 py-2 rounded" onClick={()=>exportToCsv(chartData)} disabled={chartData.isLoading}>
                            <i className="fa fa-download text-white" />
						</button>
                    </div>
                </div>
            </div>
                <div className="card-body">
                    {!chartData.isLoading &&
                        <HighchartsReact
                        highcharts={Highcharts}
                        options={keyOptions[chartData.cardTitle]} />
                    }
                    {
                        <Spinner loading={chartData.isLoading} />
                    }
                </div>
        </div>
    );
}

export default ChartComponent;

