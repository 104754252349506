import { API } from 'aws-amplify';
import { AmityEvent, AmityUser } from '../types';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime)

export async function getEvents(networkId: string, from = 0, size = 10) {// : NetworkInfo[]{
    const resp: { data: AmityEvent[], count: number } = await API.get(
        "dataapi",
        `/events?networkId=${networkId}&from=${from}&size=${size}`, {});
    // Cut unique id suffix out
    // resp.data.forEach(d=>(d as any).id = Math.random());
    return resp;
}

export function extractVersion(event: string) {
    const eventArray = (event != undefined) ? event.split(".") : [];
    return eventArray.length > 2 ? eventArray[0] : "-";
}
export function extractType(event: string) {
    const eventArray = (event != undefined) ? event.split(".") : [];
    return eventArray[eventArray.length - 2];
}
export function extractAction(event: string) {
    const eventArray = (event != undefined) ? event.split(".") : [];
    return eventArray[eventArray.length - 1];
}
export function extractTimestamp(event: AmityEvent) {
    return new Date(event.sentTime).toLocaleString();
}
export function extractActor(event: AmityEvent) {
    let user: AmityUser | undefined
    if (event.eventType != undefined){
        if (event.eventType.indexOf("user.")>=0){
            user = event.data.user[0] as AmityUser;
        }
    }
    return user
}
export function extractDetail(event: AmityEvent) {
    return JSON.stringify(event.data);
}

export function extractReadableString(eventName: string) {
    return extractType(eventName) + "/" + extractAction(eventName).replace('did', '');
}