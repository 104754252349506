import React, { useState } from "react";
import Header from "../components/Header";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../state/States";
import { DateRangePicker } from "rsuite";
import { ListGroup } from 'react-bootstrap';
import * as dayjs from "dayjs";
import ChartComponent from "../components/Analytics/ChartComponent";
const { combine, afterToday, allowedMaxDays } = DateRangePicker;

const SocialCommunities = () => {
    const user = useRecoilValue(userState);
    const { networkId } = useParams();
    const network = user.networks.filter((n) => n.networkId === networkId)[0];
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
        dayjs().subtract(7, "day").startOf("day"),
        dayjs().subtract(1, "day").endOf("day"),
    ] as [dayjs.Dayjs, dayjs.Dayjs]);
    console.log("Rendering with DR: ", dateRange);

    const groups = [
        { id: 1, name: 'Community A' },
        { id: 2, name: 'Community B' },
        { id: 3, name: 'Community C' },
        { id: 4, name: 'Community D' },
        { id: 5, name: 'Community E' },
        { id: 6, name: 'Community F' },
        { id: 7, name: 'Community A' },
        { id: 8, name: 'Community B' },
        { id: 9, name: 'Community C' },
        { id: 10, name: 'Community D' },
        { id: 11, name: 'Community E' },
        { id: 12, name: 'Community F' },
        { id: 13, name: 'Community A' },
        { id: 14, name: 'Community B' },
        { id: 15, name: 'Community C' },
        { id: 16, name: 'Community D' },
        { id: 17, name: 'Community E' },
        { id: 18, name: 'Community F' },
    ];

    const datePicker = (
        <div className="d-flex align-items-center me-5">
          <div className="align-items-center">
            <div className="d-flex align-items-center">
              <span className="fs-7 text-gray-700 fw-bolder pe-3 d-none d-xxl-block">
                Date:
              </span>
              <div className="d-flex">
                <div className="d-flex align-items-center">
                  <DateRangePicker
                    placeholder="Select Date Range"
                    defaultValue={dateRange.map((d) => d.toDate()) as [Date, Date]}
                    character={" to "}
                    cleanable={false}
                    disabledDate={combine(afterToday(), allowedMaxDays(365))}
                    placement={"bottomEnd"}
                    onChange={(dateRange) => {
                      setDateRange([
                        dayjs(dateRange[0]).startOf("day"),
                        dayjs(dateRange[1]).endOf("day"),
                      ]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
    );

    const handleSelect = (group) => {
        setSelectedGroup(group);
        onSelect(group);
    }

    const activeStyle = {
        backgroundColor: '#77B5EE',
        borderColor: '#73AFE5',
    };

    const communityCardStyle = {
        maxHeight: '600px',
    };

    const communityListStyle = {
        maxHeight: '450px',
        overflow: 'auto'
    }

    const onSelect = (group) => {
        console.log('Selected group:', group);
    }

    return (
        <div>
            <Header
                pageTitle={"Communities"}
                pageDescription={`Community usage stats & analytics of `}
                networkName={network ? network.name : "no network"}
                networkId={network ? network.networkId : "no network"}
                children={datePicker}
            />
            <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
            >
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container-xxl">
                        <div className="row g-5 g-xl-8">
                            <div className="col-xl-4">
                                <div className="card card-xl-stretch mb-5 mb-xl-8" style={communityCardStyle}>
                                    <div className="card-header border-0 pt-5">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bolder fs-3 mb-1">Communities</span>
                                            <span className="text-muted fw-bold fs-7">Choose communities below to get analytic data</span>
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="d-flex flex-column">
                                            <input
                                                type="text"
                                                className="form-control w-100"
                                                placeholder="Search communities ..."
                                            />
                                            <div style={communityListStyle}>
                                                <ListGroup className="mt-5">
                                                    {groups.map((group) => (
                                                        <ListGroup.Item 
                                                        className="pt-4 pb-4" 
                                                        key={group.id} 
                                                        active={selectedGroup && selectedGroup.id === group.id} 
                                                        style={selectedGroup && selectedGroup.id === group.id ? activeStyle : null}
                                                        onClick={() => handleSelect(group)}>
                                                            <span className="text-gray-800 fw-bold">
                                                                {group.name}
                                                            </span>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <div className="row g-5 g-xl-8">
                                    <div className="col-xl-12">
                                        <ChartComponent chartData={{cardTitle: "Community Engagement", subtitle:"# of reactions and comments", postCommentChartsData: undefined, isLoading:false, fromDate: dateRange[0], untilDate: dateRange[1]}}/>
                                    </div>    
                                    <div className="col-xl-6">
                                        <ChartComponent chartData={{cardTitle: "Community Post Type", subtitle:"# of posts, votes and video streams", postCommentChartsData: undefined, isLoading:false, fromDate: dateRange[0], untilDate: dateRange[1]}}/>
                                    </div>  
                                    <div className="col-xl-6">
                                        <ChartComponent chartData={{cardTitle: "Community Member", subtitle:"# of join and leave users", postCommentChartsData: undefined, isLoading:false, fromDate: dateRange[0], untilDate: dateRange[1]}}/>
                                    </div>                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SocialCommunities;