import { API } from "aws-amplify";
import { MessageSearchQuery } from "../types";
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime)


export type SortBy = 'createdAt' | 'lastActivity' | 'engagementCount' | 'comment-reactionsCount' | 'flagCount' | 'comment-flagCount';
export interface MessagesSearch {
    data: MessageSearchQuery[], count: number
}

export async function getMessagesByChannel(channelId: string, sortBy: SortBy = 'createdAt', from = 0, size = 10) {
    const resp: MessagesSearch = await API.get(
        "dataapi",
        `/messages/?channelId=${channelId}&from=${from}&size=${size}&sortBy=${sortBy}`, {});
    return resp;
}