import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import { useRecoilValue } from "recoil";
import { userState } from "../state/States";
import { DateRangePicker } from "rsuite";
import * as dayjs from "dayjs";
import { ChatUserCharts } from "../components/Analytics/Chat/ChatUserCharts";

const { combine, afterToday, allowedMaxDays } = DateRangePicker;

export function ChatUsers() {
  const user = useRecoilValue(userState);
  const { networkId } = useParams();
  const network = user?.networks.filter((n) => n.networkId === networkId)[0];
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().subtract(7, "day").startOf("day"),
    dayjs().subtract(1, "day").endOf("day"),
  ] as [dayjs.Dayjs, dayjs.Dayjs]);

  return (
    <div>
      <Header
        pageTitle={"Users"}
        pageDescription={`Users usage stats & analytics of `}
        networkName={network ? network.name : "no network"}
        networkId={network ? network.networkId : "no network"}
      />
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <ChatUserCharts/>
          </div>
        </div>
      </div>
    </div>
  );
}
