import {
    userState,
  } from '../state/States'
import { useRecoilValue } from 'recoil'
import { useParams } from 'react-router-dom'
function HeaderFilter({ onSelect }: { onSelect: Function }) {
    const { networkId } = useParams();
    const user = useRecoilValue(userState);
    const network = user.networks.filter(n=>n.networkId === networkId)[0];
    return (
        <div className="d-flex align-items-center me-5">
            <span className="fs-7 fw-bolder text-gray-700 pe-4 text-nowrap d-none d-xxl-block">Post Type:</span>
            <select className="form-select form-select-sm form-select-solid w-200px w-xxl-150px"
                data-control="select2" data-placeholder="Latest" data-hide-search="true" onChange={(e) => onSelect(e.target.value)}>
                <option value="all" selected={true}>All</option>
                <option value="liveStream" >LiveStream</option>
                <option value="video">Video</option>
                <option value="image">Image</option>
                {network.adminToken && <option value="poll">Poll</option>}
            </select>
        </div>
    );
}

export { HeaderFilter }