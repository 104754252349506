import React from 'react';
import { useState, useEffect } from 'react';
import { logOut } from '../services/AuthService';
function Header({ pageTitle, pageDescription, networkName, networkId, children }:
	{ pageTitle: string, pageDescription: string, networkName: string, networkId: string, children?: JSX.Element }) {
	const [expand, setExpand] = useState(true);

	useEffect(() => {
		if (expand) {
			document.body.setAttribute("data-kt-aside-minimize", "off");
		} else {
			document.body.setAttribute("data-kt-aside-minimize", "on");
		}
	}, [expand])
	return (

		<div id="kt_header" className="header align-items-stretch">
			<div className="header-brand">
				<a href="/">
					<img alt="Logo" src="assets/media/logos/logo.png" className="h-50px h-lg-50px" />
				</a>
				<div id="kt_aside_toggle" className="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">

					<div className="page-title flex-column me-5" onClick={() => logOut()}>
						<h1 className="d-flex flex-column svg-icon svg-icon-1 me-n1 minimize-default  text-end text-dark fw-bolder fs-3 mb-0">
							<span className="menu-icon">
								<span className="svg-icon svg-icon-2">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="black">
										<rect opacity="0.3" width="24" height="2" rx="1" transform="matrix(-1 0 0 1 15.5 11)" fill="black" />
										<path d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z" fill="black" />
										<path d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z" fill="#C4C4C4" />
									</svg>
								</span>
							</span>
						</h1>
					</div>
				</div>
			</div >
			<div className="toolbar">
				<div className="container-fluid py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
					<div className="page-title d-flex flex-column me-5">
						<div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
							<div className="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
								<span className="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
										<path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
									</svg>
								</span>
							</div>
						</div>
						<div className="container-fluid py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
							<div className="page-title d-flex flex-column me-5">
								<h1 className="d-flex flex-column text-dark fw-bolder fs-3 mb-0">{pageTitle}</h1>
								<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 pt-1">
									&nbsp; <li className="breadcrumb-item text-muted">{pageDescription}{networkName} {networkId}</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				{children ? children : null}
				{/*<div className="page-title flex-column me-5">
					<h1 className="d-flex flex-column bg-hover-white  text-end text-dark fw-bolder fs-3 mb-0">
						<span className="menu-icon">
							<span className="svg-icon svg-icon-2">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="black">
									<path d="M10 9.408l2.963 2.592-2.963 2.592v-1.592h-8v-2h8v-1.592zm-2-4.408v4h-8v6h8v4l8-7-8-7zm6-3c-1.787 0-3.46.474-4.911 1.295l.228.2 1.396 1.221c1.004-.456 2.114-.716 3.287-.716 4.411 0 8 3.589 8 8s-3.589 8-8 8c-1.173 0-2.283-.26-3.288-.715l-1.396 1.221-.228.2c1.452.82 3.125 1.294 4.912 1.294 5.522 0 10-4.477 10-10s-4.478-10-10-10z" fill="black" />
								</svg>
							</span>
						</span>
					</h1>
				</div>*/}
			</div>
		</div >
	);
}

export default Header