import React from "react";
import { AmityComment, AmityPost } from "../../types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { getFileUrl } from "../../services/FileService";
import {
  extractText,
  extractTextFromStream,
  extractCreatedTime,
  extractLastActivityTime,
  getCommentsForPost,
  extractMedia,
  extractTargetName,
} from "../../services/PostService";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { deletePost, pinPost, unPinPost } from "../../services/AdminService";
import {
  deletedPostIdsState,
  pinPostIdState,
  userState,
} from "../../state/States";
import { useState, useEffect } from "react";
import { CommentEntry } from "./CommentEntry";
import { PostMediaVideo, PostMediaPoll } from "./PostMedia";
import { Gallery } from "react-grid-gallery";
import { Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
dayjs.extend(relativeTime);
export function PostEntry({
  post,
  children,
}: {
  post: AmityPost;
  children: { [key: string]: AmityPost };
}) {
  const [mediaInPost, setMediaInPost] = useState([]);
  const entryId = Math.round(Math.random() * 1000000);
  const { networkId } = useParams();
  const user = useRecoilValue(userState);
  const network = user?.networks.filter((n) => n.networkId === networkId)[0];
  const pinPostId = useRecoilValue(pinPostIdState);
  const [comments, setComments] = useState<AmityComment[]>([]);
  const [commentLoading, setCommentLoading] = useState<boolean>(false);
  const [postTitle, setPostTitle] = useState("");

  useEffect(() => {
    setPostTitle(extractText(post));
    const media = extractMedia(post, children);
    const temp = [];
    const imageTemp = [];
    const addMediaToPost = async () => {
      for (let i = 0; i < media.length; i++) {
        const mediaTemp = await onGetMediaInPost(media[i]);
        if (media[i].type === "image") {
          imageTemp.push(mediaTemp);
        } else {
          temp.push(mediaTemp);
        }
      }
      if (imageTemp.length > 0) {
        temp.push(<Gallery images={imageTemp} />);
      }
      setMediaInPost(temp);
    };
    addMediaToPost();
  }, []);

  const onGetMediaInPost = async (m: any) => {
    if (network) {
      if (m.type === "image") {
        return {
          key: m.fileId,
          src: getFileUrl(m.fileId, network.region),
          thumbnail: getFileUrl(m.fileId, network.region),
          thumbnailWidth: 320,
          thumbnailHeight: 174,
        };
      } else if (m.type === "liveStream") {
        const { data, render } = await PostMediaVideo(network, { media: m });
        setPostTitle(extractTextFromStream(data));
        return <div key={`${entryId}-${m.fileId}}`}>{render}</div>;
      } else if (m.type === "video") {
        const data = await PostMediaVideo(network, { media: m });
        return <div key={`${entryId}-${m.fileId}}`}>{data}</div>;
      } else if (m.type === "poll") {
        const { render } = await PostMediaPoll(network, { media: m });
        return <div key={`${entryId}-${m.pollId}`}>{render}</div>;
      }
    }
  };

  const loadComment = async () => {
    if (network) {
      setCommentLoading(true);
      const comments = await getCommentsForPost(network.networkId, post.postId);
      setCommentLoading(false);
      setComments(comments);
    }
  };
  const setDeletedPostIdsState = useSetRecoilState(deletedPostIdsState);
  const doDeletePost = async () => {
    if (network)
      await deletePost(network.adminToken, network.region, post.postId);
    console.log("Delete completed, altering state");
    setDeletedPostIdsState((currentDeletedPosts) => [
      ...currentDeletedPosts,
      post.postId,
    ]);
  };
  const setPinPostIdState = useSetRecoilState(pinPostIdState);
  const doPinPost = async () => {
    if (network) await pinPost(network.networkId, post.postId);
    console.log("Pin completed, altering state");
    setPinPostIdState(post.postId);
  };
  const doUnPinPost = async () => {
    if (network) await unPinPost(network.networkId);
    console.log("UnPin completed, altering state");
    setPinPostIdState(null);
  };
  const onCommentDeleted = (comment: AmityComment) => {
    const clist = JSON.parse(JSON.stringify(comments));
    for (let i = 0; i < clist.length; i++) {
      if (clist[i].commentId === comment.commentId) {
        clist.splice(i, 1);
      }
    }
    setComments(clist);
  };
  return (
    <div className="card mb-5 mb-xl-8">
      <div className="card-body pb-0">
        <div className="d-flex align-items-center mb-3">
          <div className="d-flex align-items-center flex-grow-1">
            <div className="symbol symbol-45px me-5">
              {network && post.user && (
                <img
                  src={getFileUrl(post.user.avatarFileId, network.region)}
                  alt=""
                />
              )}
            </div>

            <div className="d-flex flex-column">
              <span className="text-gray-900 fs-6">
                {network && post.user && <b>{post.user.displayName}</b>} posted
                to <b>{extractTargetName(post)}</b>{" "}
                <span className="text-gray-500 fs-9">
                  {post.targetType == "user"
                    ? " (user)"
                    : post.targetType == "content"
                    ? "content"
                    : " (community)"}
                </span>
              </span>
              <span className="text-gray-400 fw-bold">
                Created: {extractCreatedTime(post)}
              </span>
              <span className="text-gray-400 fw-bold">
                Last active: {extractLastActivityTime(post)}
              </span>
            </div>
          </div>
          {network && network.adminToken && (
            <div className="my-0">
              <Dropdown>
                <Dropdown.Toggle className="btn btn-sm btn-icon btn-light-primary arrowless-dropdown">
                  <span className="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect
                          x="5"
                          y="5"
                          width="5"
                          height="5"
                          rx="1"
                          fill="#000000"
                        />
                        <rect
                          x="14"
                          y="5"
                          width="5"
                          height="5"
                          rx="1"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <rect
                          x="5"
                          y="14"
                          width="5"
                          height="5"
                          rx="1"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <rect
                          x="14"
                          y="14"
                          width="5"
                          height="5"
                          rx="1"
                          fill="#000000"
                          opacity="0.3"
                        />
                      </g>
                    </svg>
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {pinPostId == post.postId ? (
                    <Dropdown.Item onClick={doUnPinPost}>Unpin</Dropdown.Item>
                  ) : (
                    <Dropdown.Item onClick={doPinPost}>Pin this</Dropdown.Item>
                  )}
                  <Dropdown.Item onClick={doDeletePost}>Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
        <div className="mb-7">
          <div className="d-flex flex-column">
            <div className="text-gray-800 mb-5">{postTitle}</div>
          </div>
          <div className="mb-5 d-flex flex-column">
            <div className="embed-responsive-item rounded w-100">
              {mediaInPost.length > 0 && mediaInPost}
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center mb-5">
              <button
                onClick={loadComment}
                disabled={commentLoading}
                className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
              >
                <span className="svg-icon svg-icon-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z"
                      fill="black"
                    />
                    <rect
                      x="6"
                      y="12"
                      width="7"
                      height="2"
                      rx="1"
                      fill="black"
                    />
                    <rect
                      x="6"
                      y="7"
                      width="12"
                      height="2"
                      rx="1"
                      fill="black"
                    />
                  </svg>
                </span>
                {commentLoading ? "Loading..." : post.commentsCount}
              </button>
              <div className="btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 me-4">
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M18.3721 4.65439C17.6415 4.23815 16.8052 4 15.9142 4C14.3444 4 12.9339 4.73924 12.003 5.89633C11.0657 4.73913 9.66 4 8.08626 4C7.19611 4 6.35789 4.23746 5.62804 4.65439C4.06148 5.54462 3 7.26056 3 9.24232C3 9.81001 3.08941 10.3491 3.25153 10.8593C4.12155 14.9013 9.69287 20 12.0034 20C14.2502 20 19.875 14.9013 20.7488 10.8593C20.9109 10.3491 21 9.81001 21 9.24232C21.0007 7.26056 19.9383 5.54462 18.3721 4.65439Z"
                      fill="black"
                    />
                  </svg>
                </span>
                {post.reactionsCount}
              </div>
              <div className="btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2">
                <span className="svg-icon svg-icon-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 447.514 447.514"
                    fill="none"
                  >
                    <path
                      d="M389.183,10.118c-3.536-2.215-7.963-2.455-11.718-0.634l-50.653,24.559c-35.906,17.409-77.917,16.884-113.377-1.418
	c-38.094-19.662-83.542-18.72-120.789,2.487V20c0-11.046-8.954-20-20-20s-20,8.954-20,20v407.514c0,11.046,8.954,20,20,20
	s20-8.954,20-20V220.861c37.246-21.207,82.694-22.148,120.789-2.487c35.46,18.302,77.47,18.827,113.377,1.418l56.059-27.18
	c7.336-3.557,11.995-10.993,11.995-19.146V20.385C394.866,16.212,392.719,12.333,389.183,10.118z"
                      fill="black"
                    ></path>
                  </svg>
                </span>
                {post.flagCount}
              </div>
            </div>
          </div>
        </div>

        {/* Comment Container */}
        <div className="mb-7 ps-10">
          {comments.map((comment) => (
            <CommentEntry
              key={`${entryId}-${comment.commentId}}`}
              comment={comment}
              onCommentDeleted={onCommentDeleted}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
