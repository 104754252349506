import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Header from "../components/Header";
import { PostList } from "../components/Posts/PostList";
import { getPosts, PostListData } from "../services/PostService";
import { userState } from "../state/States";
import { AmityPost } from "../types";
function PopularPostPage() {
    const user = useRecoilValue(userState);
    const { networkId } = useParams();
    const network = user.networks.filter(n => n.networkId === networkId)[0];

	// START WORKING ON IMAGE POST, DUPLICATE FIX FROM DASHBOARD TO ESEARCH EXTENSION, THEN ONTO ANALYTICS
	return (<div>
		<Header pageTitle={"Popular Posts"} pageDescription={"Popular posts & comments in "}  networkName={network ? network.name : "no network"} networkId={network ? network.networkId : "no network"} />
		<div className="content d-flex flex-column flex-column-fluid" id="kt_content">

			<div className="post d-flex flex-column-fluid" id="kt_post">

				<div id="kt_content_container" className="container-xxl">

					<div className="row g-5 g-xl-8">
						<PostList sortBy={"engagementCount"} />
						<PostList sortBy={"comment-reactionsCount"} />
					</div>

				</div>

			</div>

		</div></div>
	)
}

export default PopularPostPage