import { addMonths, startOfMonth, subDays } from "date-fns";

export function truncate(str: string, length: number) {
  return str.length > length ? str.substring(0, length - 1) + "&hellip;" : str;
}

export function downloadFile(data: string, fileName: string, fileType: string) {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
}

export function getPreviousDay(date = new Date(), lastAmountDay: number) {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - lastAmountDay);
  return previous;
}
export function formatDate(dateString: string): string {
  if (!dateString) {
    return ""; // or any other default value you prefer
  }

  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const formattedDate = date.toLocaleString(undefined, options);
  return formattedDate.replace(",", ""); // Remove the comma after the day
}

export const predefinedDateRanges: any[] = [
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
  },
  {
    label: "Last 6 Months",
    value: [startOfMonth(addMonths(new Date(), -6)), new Date()],
  },
  {
    label: "This year",
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
  },
];