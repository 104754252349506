import { Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { deleteComment } from "../../services/AdminService";
import { getFileUrl } from "../../services/FileService";
import { extractCommentCreatedTime, extractText } from "../../services/PostService";
import { userState } from "../../state/States";
import { AmityComment } from "../../types";

export function CommentEntry({ comment, onCommentDeleted }: { comment: AmityComment, onCommentDeleted: (comment: AmityComment) => void }) {
    
    const user = useRecoilValue(userState);
    const { networkId } = useParams();
    const network = user.networks.filter(n => n.networkId === networkId)[0];
    const doDeleteComment = async () => {
        await deleteComment(network.adminToken, network.region, comment.commentId);
        onCommentDeleted(comment);
    }
    return (
        <div className="d-flex mb-5">

            <div className="symbol symbol-45px me-5">
                <img src={getFileUrl(comment.user?.avatarFileId, network.region)} alt="" />
            </div>


            <div className="d-flex flex-column flex-row-fluid">

                <div className="d-flex align-items-center flex-wrap mb-1">
                    <a href="#" className="text-gray-800 text-hover-primary fw-bolder me-2">{comment.user?.displayName}</a>
                    <span className="text-gray-400 fw-bold fs-7">{extractCommentCreatedTime(comment)}</span>
                </div>


                <span className="text-gray-800 fs-7 fw-normal pt-1">{extractText(comment)}</span>

                <div className="d-flex align-items-center mb-3 mt-3">
                    <div className="btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 me-4">

                        <span className="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M18.3721 4.65439C17.6415 4.23815 16.8052 4 15.9142 4C14.3444 4 12.9339 4.73924 12.003 5.89633C11.0657 4.73913 9.66 4 8.08626 4C7.19611 4 6.35789 4.23746 5.62804 4.65439C4.06148 5.54462 3 7.26056 3 9.24232C3 9.81001 3.08941 10.3491 3.25153 10.8593C4.12155 14.9013 9.69287 20 12.0034 20C14.2502 20 19.875 14.9013 20.7488 10.8593C20.9109 10.3491 21 9.81001 21 9.24232C21.0007 7.26056 19.9383 5.54462 18.3721 4.65439Z" fill="black" />
                            </svg>
                        </span>
                        {comment.reactionsCount}</div>
                    <div className="btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2">

                        <span className="svg-icon svg-icon-3"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 447.514 447.514" fill="none"><path d="M389.183,10.118c-3.536-2.215-7.963-2.455-11.718-0.634l-50.653,24.559c-35.906,17.409-77.917,16.884-113.377-1.418
	c-38.094-19.662-83.542-18.72-120.789,2.487V20c0-11.046-8.954-20-20-20s-20,8.954-20,20v407.514c0,11.046,8.954,20,20,20
	s20-8.954,20-20V220.861c37.246-21.207,82.694-22.148,120.789-2.487c35.46,18.302,77.47,18.827,113.377,1.418l56.059-27.18
	c7.336-3.557,11.995-10.993,11.995-19.146V20.385C394.866,16.212,392.719,12.333,389.183,10.118z" fill="black"></path></svg>
                        </span>
                        {comment.flagCount}</div>
                </div>

            </div>

            {network.adminToken && <div className="my-0">
                <Dropdown>
                    <Dropdown.Toggle className="btn btn-sm btn-icon btn-light-primary arrowless-dropdown">
                        <span className="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
                                    <rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                                    <rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                                    <rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={doDeleteComment}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>}
        </div>
    )
}