import React from "react";
import { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getCurrentUser, getDefaultPage } from "../services/AuthService";
import { userState } from "../state/States";
import ChannelBrowser from "../views/ChannelBrowser";
import ChannelChatView from "../views/ChannelChatView";
import ChatAnalytics from "../views/ChatAnalytics";
import EventPage from "../views/EventPage";
import FlaggedPostPage from "../views/FlaggedPostPage";
import LatestPostPage from "../views/LatestPostPage";
import LiveStreamPostPage from "../views/LiveStreamPostPage";
import PopularPostPage from "../views/PopularPostPage";
import SocialAnalytics from "../views/SocialAnalytics";
import Sidebar from "./Sidebar";
import { logOut } from "../services/AuthService";
import { ChatUsers } from "../views/ChatUsers";
import { SocialUsers } from "../views/SocialUsers";
import { UserProfilePage } from "../views/UserProfilePage";
import UserSocialPage from "../views/UserSocialPage";
import SocialCommunities from "../views/SocialCommunities";

//router
function Main(props: { user }) {
  const setUser = useSetRecoilState(userState);
  const location = useLocation();
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const match = useMatch("/:networkId/*");
  const networkId = match ? match.params.networkId : null;
  const defaultRedirect = user
    ? "/" + networkId + getDefaultPage(user, user.networks[0].networkId)
    : null;
  useEffect(() => {
    getCurrentUser(props.user).then((authUser) => {
      if (user && authUser.username === user.username) return;
      if (authUser.networks.length <= 0) {
        logOut();
      }
      console.log("USER: ", authUser);
      setUser(authUser);
      const currentNetworkId = location.pathname.split("/")[1];
      if (currentNetworkId.length === 24) {
        navigate(location.pathname);
      } else navigate("/" + authUser.networks[0].networkId);
    });
  }, [props.user]);
  return user ? (
    <div id="app" className="App page d-flex flex-row flex-column-fluid">
      {networkId ? <Sidebar /> : null}
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <Routes>
          <Route path="/:networkId/activity/events" element={<EventPage />} />
          {/*  <Route path="/:networkId/activity/reports" element={<ReportPage />} /> */}
          <Route
            path="/:networkId/social/latest-posts"
            element={<LatestPostPage />}
          />
          <Route
            path="/:networkId/social/liveStream-posts"
            element={<LiveStreamPostPage />}
          />
          <Route
            path="/:networkId/social/popular-posts"
            element={<PopularPostPage />}
          />
          <Route
            path="/:networkId/social/flagged-posts"
            element={<FlaggedPostPage />}
          />
          <Route
            path="/:networkId/social/analytics"
            element={<SocialAnalytics />}
          />
          <Route
            path="/:networkId/social/communities"
            element={<SocialCommunities />}
          />
          <Route path="/:networkId/social/users" element={<SocialUsers />} />
          <Route
            path="/:networkId/social/users/:userId"
            element={<UserSocialPage />}
          />
          <Route
            path="/:networkId/chat/analytics"
            element={<ChatAnalytics />}
          />
          <Route
            path="/:networkId/chat/channels"
            element={<ChannelBrowser />}
          />
          <Route
            path="/:networkId/chat/channels/view"
            element={<ChannelChatView />}
          />
          <Route path="/:networkId/chat/users" element={<ChatUsers />} />
          <Route
            path="/:networkId/chat/users/:userId"
            element={<UserProfilePage />}
          />
          <Route
            path="/:networkId/"
            element={<Navigate to={defaultRedirect!} />}
          />
          <Route
            path="/:networkId/"
            element={<Navigate to={defaultRedirect!} />}
          />
        </Routes>
      </div>
    </div>
  ) : null;
}
export default Main;
