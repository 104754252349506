import { API } from "aws-amplify";
import { AmityChannelFromWebhookEvent } from "../types";


export type SortBy = 'createdAt' | 'reactionsCount';
export interface ChannelListData {
    data: AmityChannelFromWebhookEvent[], count: number
}
export async function getChannels(networkId: string, sortBy: SortBy = 'createdAt', from = 0, size = 10) {
    const resp: ChannelListData = await API.get(
        "dataapi",
        `/channels?networkId=${networkId}&from=${from}&size=${size}&sortBy=${sortBy}`, {});
    return resp;
}

export async function viewChannels(networkId: string, sortBy: SortBy = 'createdAt', from = 0, size = 10) {
    const resp: ChannelListData = await API.get(
        "dataapi",
        `/view_channels?networkId=${networkId}&from=${from}&size=${size}&sortBy=${sortBy}`, {});
    return resp;
}