//router
import React from 'react'
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import aws_exports from "./aws-exports"; //router
import "./App.css";
import { AuthUser } from "./services/AuthService";
import Highcharts from "highcharts";
import "./rsuite.css";
import "@aws-amplify/ui-react/styles.css";
import Main from "./components/Main";

Amplify.configure(aws_exports);
Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: "Poppins,Helvetica,sans-serif",
    },
  },
  time: {
    timezoneOffset: new Date().getTimezoneOffset(),
  },
});
function App(params: {
  isPassedToWithAuthenticator: boolean;
  signOut: () => void;
  user: AuthUser;
}) {
  // const setUser = useSetRecoilState(userState);
  // const setNetwork = useSetRecoilState(networkState);
  // const user = useRecoilValue(userState);
  // if(params.user){
  //   console.log("USER: ", params.user);
  //   setUser(params.user);
  //   setNetwork(params.user.networks[0]);
  // }
  return (
    <Authenticator>{({ signOut, user }) => <Main user={user} />}</Authenticator>
  );
}
export default App;
