// import { AmityChannelFromWebhookEvent } from "../../types";

// function addDisable(classname, channelType) {
//     if (channelType == "conversation") {
//         classname = classname.replace("text-gray-800", "text-gray-400")
//         classname = classname.replace("text-gray-700", "text-gray-400")
//         return classname + " disabled"
//     } else {
//         return classname
//     }
// }

// function viewChat(channelId) {
//     return "chat/channels/view" + "?channelId=" + channelId;
// }
import { useEffect, useState } from "react";
import { getCurrentUser } from '../../services/AuthService';
function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}
function formatDate(date) {
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-') +
        ' ' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes())
        ].join(':')
    );
}
export function MessagesShow(data) {
    const [username, setUser] = useState<string>("");
    const message = data.message;
    const today = new Date()
    const todayDay = [today.getDate(), today.getMonth() + 1, today.getFullYear()].join("-")
    const messageDate = new Date(message.createdAt);
    const messageDay = [messageDate.getDate(), messageDate.getMonth() + 1, messageDate.getFullYear()].join("-")
    const time = formatDate(messageDate);
    useEffect(function () {
        getCurrentUser().then((user) => {
            setUser(user.username);
        })
    }, [])
    return (<>

        {(username == message.userId) ? <>
            <div className="d-flex justify-content-end">
                <div className="card mt-5 message-box my-message">
                    <div className="p-3 d-flex flex-row-reverse w-100">
                        <div className="d-flex flex-column m-5">
                            <div className="d-flex justify-content-between w-100">
                                <div className="my-message-history">{message.data.text}</div>
                                <div className="time-history">
                                    <span className="time-message text-gray-400">{(todayDay == messageDay) ? time.split(" ")[1] : time}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </> : <>
                <div className="d-flex justify-content-start">
                    <div className="card mt-5 message-box">
                        <div className="p-3 d-flex flex-row w-100">
                            <div className="d-flex flex-center symbol symbol-50px symbol-circle">
                                <span className="symbol-label bg-light-danger text-danger fs-1 fw-bolder">{(message.userId) ? message.userId.substring(0, 1).toUpperCase() : ""}</span>
                            </div>
                            <div className="d-flex flex-column m-5">
                                <div className="d-flex justify-content-between w-100">
                                    <div>
                                        <b>{message.userId}</b>
                                    </div>
                                    <div className="time-history">
                                        <span className="time-message text-gray-400">{(todayDay == messageDay) ? time.split(" ")[1] : time}</span>
                                    </div>
                                </div>
                                <div className="message-history">{message.data.text}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </>}
    </>
    )
}