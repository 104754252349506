import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { extractAction, extractDetail, extractTimestamp, extractType, extractActor, extractVersion, getEvents } from "../../services/EventService";
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import { AmityEvent } from "../../types";
import * as dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';
import { useParams } from "react-router-dom";
dayjs.extend(relativeTime)

export function EventTable() {
	const { networkId } = useParams();
	const [tableState, setTableState] = useState({
		data: [], loading: false, totalRows: 0, perPage: 10
	});
	const columns = [
		{
			name: 'Type',
			selector: data => extractType(data.eventType),
			sortable: true,
			sortField: 'title',
		},
		{
			name: 'Action',
			selector: data => extractAction(data.eventType),
		},
		{
			name: 'Timestamp',
			selector: data => extractTimestamp(data),
		},
		{
			name: 'Actor',
			selector: data => extractActor(data),
		}
	];
	const calculateFrom = (page) => {
		console.log("PAGE: " + page);
		if (page <= 0) return 0;
		return (page - 1) * tableState.perPage;
	}
	const fetchEvents = async page => {
		setTableState({
			data: tableState.data, loading: true, totalRows: tableState.totalRows, perPage: tableState.perPage
		});
		const { data, count } = await getEvents(networkId, calculateFrom(page));
		setTableState({
			data: data, loading: false, totalRows: count, perPage: tableState.perPage
		});
	};
	const handlePageChange = async page => {
		await fetchEvents(calculateFrom(page));
	};
	const ExpandedComponent: React.FC<ExpanderComponentProps<AmityEvent>> = ({ data }) => {
		return <pre>{JSON.stringify(data, null, 2)}</pre>;
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		console.log(newPerPage)
		setTableState({
			data: tableState.data, loading: true, totalRows: tableState.totalRows, perPage: newPerPage
		});
		const response = await fetchEvents(page);
	};
	// const handleSort = async (column, sortDirection) => {
	// 	/// reach out to some API and get new data using or sortField and sortDirection
	// 	// e.g. https://api.github.com/search/repositories?q=blog&sort=${column.sortField}&order=${sortDirection}
	// 	console.log("COLUMN: ", column);
	// 	console.log("SORTDIR: ", sortDirection);
	// 	//   setData(remoteData);
	// };

	useEffect(() => {
		fetchEvents(1); // fetch page 1 of users
	}, [networkId]);

	return (<>
		<DataTable
			title="Activity Log"
			columns={columns}
			data={tableState.data}
			progressPending={tableState.loading}
			pagination
			paginationServer
			paginationTotalRows={tableState.totalRows}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
			// onSort={handleSort}
			// sortServer
			expandableRows expandableRowsComponent={ExpandedComponent}
		/>
	</>
	);
}