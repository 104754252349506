import { ClipLoader } from "react-spinners";
import React from "react";

const Spinner = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ClipLoader color={"#50cd89"} loading={props.loading} />
    </div>
  );
};

export default Spinner;
