import ReactGA from "react-ga";

export function sendAnalyticsEventTracker(
  category: String,
  action: String,
  label: String
) {
  if (
    typeof category == "string" &&
    typeof action == "string" &&
    typeof label == "string"
  ) {
    ReactGA.event({ category, action, label });
  }
}
