import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import Header from "../components/Header";
import { EventTable } from "../components/Events/EventTable";
import { userState } from "../state/States";
import { useParams } from "react-router-dom";
function EventPage() {
  const user = useRecoilValue(userState);
  const { networkId } = useParams();
  const network = user?.networks.filter((n) => n.networkId === networkId)[0];
  useEffect(() => {
    (async () => {})();
  }, []);
  return (
    <div>
      <Header
        pageTitle={"Activity Log"}
        pageDescription={"Events and activities in "}
        networkName={network ? network.name : "no network"}
        networkId={network ? network.networkId : "no network"}
      />
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <div className="card">
              <div className="card-body pt-0">
                <EventTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventPage;
