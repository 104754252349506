import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  PostListData,
  SortBy,
  getPostsByType,
} from "../../services/PostService";
import { deletedPostIdsState } from "../../state/States";
import { PostEntry } from "./PostEntry";
import { useParams } from "react-router-dom";
import React from "react";

function getSortByTitle(sortBy: SortBy) {
  if (sortBy === "createdAt") return "Newest posts";
  if (sortBy === "lastActivity") return "Last active posts";
  if (sortBy === "comment-reactionsCount") return "Posts with popular comments";
  if (sortBy === "comment-flagCount")
    return "Posts with comment with most flags";
  if (sortBy === "flagCount") return "Posts with most flags";
  return "Most engaged posts";
}

export function PostList({ sortBy, type }: { sortBy: SortBy; type: string }) {
  const listId = Math.round(Math.random() * 1000000);
  const { networkId } = useParams();
  const [posts, setPosts] = useState<PostListData>({
    data: [],
    children: {},
    count: 0,
  });
  const [postLoading, setPostLoading] = useState<boolean>(false);
  const deletedPostIds = useRecoilValue(deletedPostIdsState);
  const loadMorePosts = async function () {
    console.log("Loading more posts....");
    setPostLoading(true);
    const newPosts = networkId
      ? await getPostsByType(
          networkId,
          "commentsCount",
          type,
          posts.data.length,
          type == "poll" ? 5 : 10
        )
      : { count: 0, data: [], children: [] };
    const testPostss = networkId
      ? await getPostsByType(
          networkId,
          "reactionsCount",
          'liveStream',
          posts.data.length,
          type == "poll" ? 5 : 1000
        )
      : { count: 0, data: [], children: [] };
    console.log("testPostss", JSON.stringify(testPostss));
    posts.count = newPosts.count;
    posts.data.push(...newPosts.data);
    Object.assign(posts.children, newPosts.children);
    console.log("Posts loaded: ", posts);
    setPostLoading(false);
    setPosts(JSON.parse(JSON.stringify(posts)));
  };
  useEffect(() => {
    posts.data = [];
    posts.count = 0;
    posts.children = {};
    loadMorePosts();
  }, [networkId]);
  useEffect(() => {
    console.log(
      "Filtering ",
      deletedPostIds,
      " from ",
      posts.data.map((post) => post.postId)
    );
    posts.data = posts.data.filter((p) => deletedPostIds.indexOf(p.postId) < 0);
    console.log(
      "Filtered result ",
      posts.data.map((post) => post.postId)
    );
    setPosts(JSON.parse(JSON.stringify(posts)));
  }, [deletedPostIds]);
  console.log("Rendering with posts ", posts);
  return (
    <div>
      <h2>{getSortByTitle(sortBy)}</h2>
      {posts.data.map((p) => {
        return (
          <div className="col-lg">
            <PostEntry
              key={"entry-" + listId + "-" + p.postId}
              post={p}
              children={posts.children}
            />
          </div>
        );
      })}

      <button
        onClick={loadMorePosts}
        className="btn btn-primary w-100 text-center"
        disabled={postLoading}
        id="kt_widget_5_load_more_btn"
      >
        {postLoading ? (
          <span className="indicator-label">Loading...</span>
        ) : (
          <span className="indicator-label">More Posts</span>
        )}
      </button>
    </div>
  );
}
