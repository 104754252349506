import React from "react";
import { userIsAccessible } from "../services/AuthService";
import { Link, useNavigate, useMatch, useLocation } from "react-router-dom";
import Select from "react-select";
import { useRecoilValue } from "recoil";
import { userState } from "../state/States";
import { sendAnalyticsEventTracker } from "../services/GAService";
import { sideMenu } from "../utils/SideMenu";

const Sidebar = () => {
  const user = useRecoilValue(userState);
  const match = useMatch("/:networkId/*");
  const networkId = match?.params.networkId;
  const network = user?.networks.filter((n) => n.networkId === networkId)[0];
  const location = useLocation();
  const navigate = useNavigate();
  const onNetworkChanged: any = (newNetwork: {
    labe: string;
    value: string;
  }) => {
    eventTracker("sidebar", "clicked", "network-changed");
    if (user && networkId) {
      for (let n of user.networks) {
        if (n.networkId === newNetwork.value) {
          console.log("Setting network to ", n);
          navigate(location.pathname.replace(networkId, n.networkId));
          break;
        }
      }
    }
  };
  const networkOptions = user?.networks.map((network) => {
    return { value: network.networkId, label: network.name };
  });

  const eventTracker = (category, action, label) => {
    sendAnalyticsEventTracker(category, action, label);
  };

  return (
    <div
      id="kt_aside"
      className="aside"
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      <div className="aside-toolbar flex-column-auto" id="kt_aside_toolbar">
        <div className="aside-user d-flex align-items-sm-center justify-content-center py-5">
          <div className="symbol symbol-50px">
            <img src="assets/media/avatars/profile.png" alt="" />
          </div>
          <div className="aside-user-info flex-row-fluid flex-wrap ms-5">
            <div className="d-flex">
              {user && (
                <div className="flex-grow-1 me-2">
                  <a
                    href="#"
                    className="text-white text-hover-primary fs-6 fw-bold"
                  >
                    {user.name}
                  </a>
                  <span className="text-gray-600 fw-bold d-block fs-8 mb-1">
                    {user.networks.length} networks
                  </span>
                  <div className="d-flex align-items-center text-success fs-9">
                    <span className="bullet bullet-dot bg-success me-1"></span>
                    {user.username}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="aside-search py-5">
          <Select
            onChange={onNetworkChanged}
            options={networkOptions}
            defaultValue={{
              label: network ? network.name : "no network",
              value: network ? network.networkId : "no network",
            }}
          />
        </div>
      </div>
      <div className="aside-menu flex-column-fluid">
        <div
          className="hover-scroll-overlay-y px-2 my-5 my-lg-5"
          id="kt_aside_menu_wrapper"
          data-kt-scroll="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="{default: '#kt_aside_toolbar, #kt_aside_footer', lg: '#kt_header, #kt_aside_toolbar, #kt_aside_footer'}"
          data-kt-scroll-wrappers="#kt_aside_menu"
          data-kt-scroll-offset="5px"
        >
          <div
            className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
            id="#kt_aside_menu"
            data-kt-menu="true"
          >
            {Object.keys(sideMenu).map((key) => {
              return (
                <div>
                  <div className="menu-item">
                    <div className="menu-content pb-2">
                      <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                        {key}
                      </span>
                    </div>
                  </div>
                  {sideMenu[key].map((menu) => {
                    return (
                      userIsAccessible(user!, networkId!, menu.link) && (
                        <Link
                          to={networkId + menu.link}
                          onClick={() => {
                            eventTracker("sidebar", "clicked", menu.link);
                          }}
                        >
                          <div className="menu-item">
                            <span className="menu-link">
                              <span className="menu-icon">
                                <span className="svg-icon svg-icon-2">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: menu.icon,
                                    }}
                                  />
                                </span>
                              </span>
                              <span className="menu-title">{menu.title}</span>
                            </span>
                          </div>
                        </Link>
                      )
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
