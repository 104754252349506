import React from "react";
import { useRecoilValue } from "recoil";
import Header from "../components/Header";
import { DateRangePicker } from "rsuite";
import { useState } from "react";
import * as dayjs from "dayjs";
import { SocialCharts } from "../components/Analytics/Social/SocialCharts";
import { userState } from "../state/States";
import { useParams } from "react-router-dom";
import { predefinedDateRanges } from "../utils/Helper";

function SocialAnalytics() {
  const user = useRecoilValue(userState);
  const { networkId } = useParams();
  const network = user?.networks.filter((n) => n.networkId === networkId)[0];
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().subtract(7, "day").startOf("day"),
    dayjs().subtract(1, "day").endOf("day"),
  ] as [dayjs.Dayjs, dayjs.Dayjs]);
  console.log("Rendering with DR: ", dateRange);

  const datePicker = (
    <div className="d-flex align-items-center me-5">
      <div className="align-items-center">
        <div className="d-flex align-items-center">
          <span className="fs-7 text-gray-700 fw-bolder pe-3 d-none d-xxl-block">
            Date:
          </span>
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <DateRangePicker
                placeholder="left"
                ranges={predefinedDateRanges}
                defaultValue={dateRange.map((d) => d.toDate()) as [Date, Date]}
                character={" to "}
                cleanable={false}
                placement={"bottomEnd"}
                onChange={(dateRange) => {
                  if (dateRange && dateRange?.length > 0)
                    setDateRange([
                      dayjs(dateRange[0]).startOf("day"),
                      dayjs(dateRange[1]).endOf("day"),
                    ]);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Header
        pageTitle={"Social Analytics"}
        pageDescription={"Social usage stats & analytics of "}
        networkName={network ? network.name : "no network"}
        networkId={network ? network.networkId : "no network"}
        children={datePicker}
      />
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <SocialCharts dateRange={dateRange} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialAnalytics;