
import React from "react";
import { useRecoilValue } from "recoil";
import Header from "../components/Header";
import { userState } from "../state/States";
import { ChannelListData, getChannels } from '../services/ChannelService';
import { useEffect, useState } from "react";
import { ChannelEntry } from "../components/Chat/ChannelEntry";
import { channel } from "diagnostics_channel";
import { useParams } from "react-router-dom";
import Spinner from "../components/Spinner";

function ChannelBrowser() {
    const user = useRecoilValue(userState);
    const { networkId } = useParams();
    const network = user?.networks.filter(n => n.networkId === networkId)[0];
	const [channels, setChannels] = useState<ChannelListData>({ data: [], count: 0 });
	const [channelLoading, setChannelLoading] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);
	const loadMoreChannels = async function () {
		console.log("Loading more messages....");
		setChannelLoading(true);
		const newChannels = await getChannels(network!.networkId, 'createdAt', channels.data.length, 6);
		channels.count = newChannels.count;
		channels.data.push(...newChannels.data);
		setChannelLoading(false);
		setChannels(JSON.parse(JSON.stringify(channels)));
		setIsLoading(false);
	}
	useEffect(() => {
		setIsLoading(true);
		channels.data = [];
		channels.count = 0;
		loadMoreChannels();
	}, [network]);
	return (<div>
		<div id="kt_header" className="header align-items-stretch">
			<Header pageTitle={"Channel"} pageDescription={"Channel usage stats & analytics of "} networkName={network ? network.name : "no network"} networkId={network ? network.networkId : "no network"} />
		</div>
		<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
			{
				<Spinner loading={isLoading} />
			}
			{!isLoading &&
			<div className="post d-flex flex-column-fluid" id="kt_post">
				<div id="kt_content_container" className="container-xxl">
					<div className="row g-6 g-xl-9">
						{channels.data.map(channel => <ChannelEntry key={channel.channelId} channel={channel} />)}
					</div>
					<div className="row">
						<button className="btn btn-primary text-center mt-10 col-md-4 offset-md-4"
							disabled={channelLoading}
							onClick={loadMoreChannels}
						>
							<span className="indicator-label">{channelLoading ? "Loading..." : "Load more channels"}</span>
						</button>
					</div>
				</div>
			</div>
			}
		</div>
	</div>
	)
}

export default ChannelBrowser