import { useEffect, useState } from "react";
import { userIsAccessible } from "../services/AuthService";
import { useNavigate, useMatch, useSearchParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Header from "../components/Header";
import { PostList } from "../components/Posts/PostList";
import { PostList as PostListByType } from "../components/Posts/PostListByType";
import { userState, pinPostIdState } from "../state/States";
import { HeaderFilter } from "../components/HeaderFilter";
import { PostEntry } from "../components/Posts/PostEntry";
import {
  getPinPost,
  getPostsById,
  PostListData,
} from "../services/PostService";
import React from "react";

function LatestPostPage() {
  const user = useRecoilValue(userState);
  const match = useMatch("/:networkId/*");
  const networkId = match?.params.networkId;
  const network = user?.networks.filter((n) => n.networkId === networkId)[0];
  let navigate = useNavigate();
  const pinPostId = useRecoilValue(pinPostIdState);
  const [searchParams] = useSearchParams();
  let temp = searchParams.get("type");
  const [selectType, setSelectType] = useState("all");
  const setPinPostIdState = useSetRecoilState(pinPostIdState);
  const [pinPost, setPinPost] = useState<PostListData>({
    data: [],
    children: {},
    count: 0,
  });

  useEffect(() => {
    typeof temp === "string" ? setSelectType(temp) : setSelectType("all");
  }, []);

  useEffect(() => {
    typeof temp === "string" ? setSelectType(temp) : setSelectType("all");
  }, [temp]);

  useEffect(() => {
    if (selectType === "all") checkPinPost();
    else {
      setPinPost({
        data: [],
        children: {},
        count: 0,
      });
    }
  }, [network, selectType, pinPostId]);

  const checkPinPost = async () => {
    setPinPost({
      data: [],
      children: {},
      count: 0,
    });
    const pinPost = networkId ? await getPinPost(networkId) : { data: null };
    if (typeof pinPost.data == "object" && networkId) {
      const pinPostData = await getPostsById(
        networkId,
        `${networkId}--${pinPost.data.posts[0].postId}`
      );
      setPinPost(pinPostData);
      setPinPostIdState(pinPost.data.posts[0].postId);
    }
  };

  return (
    <div>
      <Header
        pageTitle={"Latest Posts"}
        pageDescription={"Latest posts & comments in "}
        networkName={network ? network.name : "no network"}
        networkId={network ? network.networkId : "no network"}
        children={
          <HeaderFilter
            onSelect={(e) => {
              if (user && networkId) {
                if (
                  networkId === "63e3b8045a454a6dca905d40" ||
                  networkId === "64b52b8becf00f80902047cf"
                ) {
                  userIsAccessible(
                    user,
                    networkId,
                    "/social/liveStream-posts"
                  ) && navigate(`?type=${e}`);
                } else {
                  userIsAccessible(user, networkId, "/social/latest-posts") &&
                    navigate(`?type=${e}`);
                }
              }
            }}
          />
        }
      />
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            {pinPost.data.length > 0 && (
              <div className="row g-5 g-xl-8">
                <div className="col-xl-6">
                  <h2>Pinned Post</h2>
                  {
                    <PostEntry
                      key={"pinPost"}
                      post={pinPost.data[0]}
                      children={pinPost.children}
                    />
                  }
                </div>
              </div>
            )}
            {selectType === "all" ? (
              <div className="row g-5 g-xl-8">
                <PostList
                  key={selectType + "createdAt"}
                  sortBy={"createdAt"}
                  search={""}
                />
                <PostList
                  key={selectType + "lastActivity"}
                  sortBy={"lastActivity"}
                  search={""}
                />
              </div>
            ) : (
              <PostListByType
                key={selectType}
                sortBy={"createdAt"}
                type={selectType}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LatestPostPage;
