import React from "react";
import { useRecoilValue } from "recoil";
import { getChannels } from "../services/ChannelsService";
import Header from "../components/Header";
import { SelectPicker, DateRangePicker } from "rsuite";
import { useEffect, useState } from "react";
import * as dayjs from "dayjs";
import { ChatCharts } from "../components/Analytics/Chat/ChatCharts";
import { userState } from "../state/States";
import { useParams } from "react-router-dom";
import { predefinedDateRanges } from "../utils/Helper";
const { combine, afterToday, allowedMaxDays } = DateRangePicker;

function ChatAnalytics() {
  interface Selection {
    label: string;
    value: string;
  }
  const user = useRecoilValue(userState);
  const { networkId } = useParams();
  const network = user?.networks.filter((n) => n.networkId === networkId)[0];
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().subtract(7, "day").startOf("day"),
    dayjs().subtract(1, "day").endOf("day"),
  ] as [dayjs.Dayjs, dayjs.Dayjs]);
  const [channelSections, setChannelSections] = useState<Array<Selection>>([]);
  const [selectChannel, setSelectChannel] = useState<String>("");
  useEffect(() => {
    if (network) {
      // Promise.all([
      // 	getChannels("getChannelByNetworkId", null, network.networkId, null, null, 0, 100)
      // ]).then(([getChannelsData, eventStats]) => {
      // 	let data = []
      // 	getChannelsData.data.forEach(element => {
      // 		data.push({ label: element.channelId, value: element.channelId })
      // 	});
      // 	setChannelSections(data)
      // })
    }
  }, [network, dateRange, selectChannel]);

  // console.log("Channel ID Selection", selectChannel);
  // console.log("Rendering with DR: ", dateRange);
  // console.log("Data",getChannelsData);
  return (
    <div>
      <Header
        pageTitle={"Chat Analytics"}
        pageDescription={"Chat usage stats & analytics of"}
        networkName={network ? network.name : "no network"}
        networkId={network ? network.networkId : "no network"}
        children={
          <div className="align-items-stretch">
            <div className="d-flex align-items-center">
              <span className="fs-7 text-gray-700 fw-bolder pe-3 d-none d-xxl-block">
                Date:
              </span>
              <div className="d-flex">
                <div className="d-flex align-items-center">
                  <DateRangePicker
                    placeholder="left"
                    ranges={predefinedDateRanges}
                    defaultValue={
                      dateRange.map((d) => d.toDate()) as [Date, Date]
                    }
                    character={" to "}
                    cleanable={false}
                    placement={"bottomEnd"}
                    onChange={(dateRange) => {
                      if (dateRange && dateRange?.length > 0)
                        setDateRange([
                          dayjs(dateRange[0]).startOf("day"),
                          dayjs(dateRange[1]).endOf("day"),
                        ]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />

      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <ChatCharts dateRange={dateRange} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatAnalytics;