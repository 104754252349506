import { useRecoilValue } from 'recoil'
import {
  getFileUrl,
  getStreamUrl,
  getPollData,
} from '../../services/FileService'
import ReactPlayer from 'react-player'
import { PollComponents } from './PollComponent'
import { useParams } from 'react-router-dom'
import { userState } from '../../state/States'

export function PostMedia({
  media,
}: {
  media: { type: 'image' | 'video'; fileId: string }
}) {
  const { networkId } = useParams();
  const user = useRecoilValue(userState);
  const network = user.networks.filter(n => n.networkId === networkId)[0];
  return (
    <div className="mb-5">
      <img
        className="embed-responsive-item rounded w-100"
        height={300}
        style={{ objectFit:'contain' }}
        src={getFileUrl(media.fileId, network.region)}
      />
    </div>
  )
}

export async function PostMediaVideo(
  network,
  { media }: { media: { type: 'liveStream'; fileId: string } },
) {
  if (media.type === 'liveStream') {
    const { data } = await getStreamUrl(
      network.adminToken,
      media.fileId,
      network.region,
    )
    if (data.videoStreamings[0].recordings) {
      return {
        data: data,
        render: (
          <div className="mb-5 d-flex align-items-center">
            <ReactPlayer
              style={{ marginLeft: '25%' }}
              width={'50%'}
              height={'20%'}
              controls={true}
              url={data.videoStreamings[0].recordings[0].mp4.url}
            ></ReactPlayer>
          </div>
        ),
      }
    } else if (data.videoStreamings[0].watcherUrl) {
      const url = data.videoStreamings[0].watcherUrl.flv.url.replace(
        'http',
        'https',
      )
      return {
        data: data,
        render: (
          <div className="mb-5 d-flex align-items-center">
            <ReactPlayer
              style={{ marginLeft: '25%' }}
              width={'50%'}
              height={'50%'}
              controls={true}
              url={url}
            ></ReactPlayer>
          </div>
        ),
      }
    } else
      return (
        <div className="mb-5">
          <p>There is a plobrem with video URL</p>
        </div>
      )
  } else if (media.type === 'video') {
    return (
      <div className="mb-5 d-flex align-items-center">
        <ReactPlayer
          style={{ marginLeft: '25%' }}
          width={'50%'}
          height={'50%'}
          controls={true}
          url={getFileUrl(media.fileId, network.region, 'video')}
        ></ReactPlayer>
      </div>
    )
  }
}

export async function PostMediaPoll(
  network,
  { media }: { media: { type: 'poll'; pollId: string } },
) {
  if (media.type === 'poll') {
    const { data } = await getPollData(
      network.adminToken,
      media.pollId,
      network.region,
    )
    const pollData = []
    if (data != undefined && data.polls.length > 0) {
      for (let i = 0; i < data.polls[0].answers.length; i++) {
        pollData.push({
          network: network,
          pollId: data.polls[0].pollId,
          key: data.polls[0].answers[i].id,
          option: data.polls[0].answers[i].data,
          votes: data.polls[0].answers[i].voteCount,
        })
      }
    }
    return {
      render: <PollComponents question={data.question} answers={pollData} />,
    }
  }
}
