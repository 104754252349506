import React from 'react';
import { useEffect, useState } from 'react';
import { getChannelStatData, getMessageStatData, getMessageDateHistogramByHour } from '../../../services/StatService'
import * as dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { ChannelChatComponentDataTypes, ChatComponentDataTypes } from '../../../types';
import ChartComponent from '../ChartComponent';

export function ChatCharts({ dateRange: [fromDate, untilDate] }: { dateRange: [dayjs.Dayjs, dayjs.Dayjs] }) {
    const networkId: string = useParams().networkId as string;
    const [isLoading, setIsLoading] = useState(false);
    const [chatChartsData, setChatChartsData] = useState<ChatComponentDataTypes>({ uniqueUserData: [], messageByChannel: [], messageByDay: [], messageByType: [], messageByHour: [] });

    const [channelChartsData, setChannelChartsData] = useState<ChannelChatComponentDataTypes>({ channelByTypeByDay: [],getChannelCount: [] });

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            getMessageStatData(networkId, fromDate, untilDate),
            getChannelStatData(networkId, fromDate, untilDate)
        ]).then(([chatStats, channelStats]) => {
            setChatChartsData({
                messageByDay: [
                    { name: "Messages sent", data: chatStats.messageByDay.count }
                ],
                uniqueUserData: [
                    { name: "Users who sent message", data: chatStats.messageByDay.unique_user },
                ],
                messageByChannel: chatStats.messageByChannel.map(ch => { return { name: ch.name, y: ch.count } }),
                messageByType: chatStats.messageByType.map(ch => { return { name: ch.name, y: ch.count } }),
                messageByHour: chatStats.messageByHour
            });
            setChannelChartsData(prevData => ({
                ...prevData,
                channelByTypeByDay: channelStats.channelByType.map(ev => ({
                    name: ev.name,
                    data: ev.byDay
                })),
                getChannelCount: channelStats.getChannelCount
            }));


            setIsLoading(false);
        })
    }, [networkId, fromDate, untilDate]);

    return (
        <div>
            <div className="row g-5 g-xl-8">
                <div className="col-xl-8">
                    <ChartComponent chartData={{cardTitle: "New Messages by day", subtitle:"# of new messages sent", chatChartsData: chatChartsData, isLoading:isLoading, fromDate: fromDate, untilDate: untilDate}} />
                </div>
                <div className="col-xl-4">
                    <ChartComponent chartData={{cardTitle: "Channels Count by Type", subtitle:"# of channel by Type", channelChartsData: channelChartsData, isLoading:isLoading, fromDate: fromDate, untilDate: untilDate}} />
                </div>
                <div className="col-xl-4">
                    <ChartComponent chartData={{cardTitle: "Total Messages by Type", subtitle:"Total # of created messages by types", chatChartsData: chatChartsData, isLoading:isLoading, fromDate: fromDate, untilDate: untilDate}} />
                </div>
                <div className="col-xl-4">
                    <ChartComponent chartData={{cardTitle: "Users  by Day", subtitle:"# of unique users who sent message by day", chatChartsData: chatChartsData, isLoading:isLoading, fromDate: fromDate, untilDate: untilDate}} />
                </div>
                <div className="col-xl-4">
                    <ChartComponent chartData={{cardTitle: "New Channel by Types", subtitle:"# of channel created by types", channelChartsData: channelChartsData, isLoading:isLoading, fromDate: fromDate, untilDate: untilDate}} />
                </div>
                <div className="col-xl-4">
                    <ChartComponent chartData={{cardTitle: "Total Messages by Channel", subtitle:"Total # of created messages by channel", chatChartsData: chatChartsData, isLoading:isLoading, fromDate: fromDate, untilDate: untilDate}} />
                </div>
                <div className="col-xl-8">
                    <ChartComponent chartData={{cardTitle: "Messages Heatmap", subtitle:"# of message sent per hour", chatChartsData: chatChartsData, isLoading:isLoading, fromDate: fromDate, untilDate: untilDate}} />
                </div>
            </div>
        </div>
    )
}