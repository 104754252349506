import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import SVG from "react-inlinesvg";
import { DateRangePicker } from "rsuite";
import * as dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { userState } from "../state/States";
import { getUserStatByDate } from "../services/StatService";
import Spinner from "../components/Spinner";

const { combine, afterToday, allowedMaxDays } = DateRangePicker;

export function UserProfilePage() {
  const user = useRecoilValue(userState);
  const networkId: string = useParams().networkId as string;
  const network = user?.networks.filter((n) => n.networkId === networkId)[0];
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().subtract(7, "day").startOf("day"),
    dayjs().subtract(1, "day").endOf("day"),
  ] as [dayjs.Dayjs, dayjs.Dayjs]);
  const userId: any = useParams().userId as any;
  const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;
  const [isLoading, setIsLoading] = useState(false);

  const [userStats, setUserStats] = useState<{
    stats: {
      communityComment: number;
      communityMessages: number;
      communityPost: number;
      flaggedMessages: number;
      flaggedPost: number;
      participatedChannel: number;
      participatedCommunity: number;
    };
  }>({
    stats: {
      communityComment: 0,
      communityMessages: 0,
      communityPost: 0,
      flaggedMessages: 0,
      flaggedPost: 0,
      participatedChannel: 0,
      participatedCommunity: 0,
    },
  });

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getUserStatByDate(networkId, userId, dateRange[0], dateRange[1]),
    ]).then((userStats) => {
      setUserStats({
        stats: {
          communityComment: userStats[0].data.communityComment,
          communityMessages: userStats[0].data.communityMessages,
          communityPost: userStats[0].data.communityPost,
          flaggedMessages: userStats[0].data.flaggedMessages,
          flaggedPost: userStats[0].data.flaggedPost,
          participatedChannel: userStats[0].data.participatedChannel,
          participatedCommunity: userStats[0].data.participatedCommunity,
        },
      });
      setIsLoading(false);
    });
  }, [networkId, dateRange]);

  return (
    <div>
      <Header
        pageTitle={"User"}
        pageDescription={`User-${userId} stats & analytics of `}
        networkName={network ? network.name : "no network"}
        networkId={network ? network.networkId : "no network"}
        children={
          <div className="align-items-stretch">
            <div className="d-flex align-items-center">
              <span className="fs-7 text-gray-700 fw-bolder pe-3 d-none d-xxl-block">
                Date:
              </span>
              <div className="d-flex">
                <div className="d-flex align-items-center">
                  <DateRangePicker
                    placeholder="Select Date Range"
                    className="marginright-3"
                    defaultValue={
                      dateRange.map((d) => d.toDate()) as [Date, Date]
                    }
                    character={" to "}
                    cleanable={false}
                    disabledDate={combine(afterToday(), allowedMaxDays(31))}
                    placement={"bottomEnd"}
                    onChange={(dateRange) => {
                      setDateRange([
                        dayjs(dateRange[0]).startOf("day"),
                        dayjs(dateRange[1]).endOf("day"),
                      ]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
      <div id="kt_content_container" className="container-xxl">
        {<Spinner loading={isLoading} />}
        {!isLoading && (
          <div>
            <div className="row g-5 g-xl-8">
              <div className="col-xl-4">
                <div className="card card-xl-stretch mt-5 mb-xl-8">
                  {/* begin::Body */}
                  <div className="card-body d-flex align-items-center pt-3 pb-0">
                    <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">
                      <a
                        href="#"
                        className="fw-bold text-dark fs-4 mb-2 text-hover-primary"
                      >
                        Username
                      </a>

                      <span
                        className="fw-semibold text-muted fs-5"
                        dangerouslySetInnerHTML={{ __html: userId }}
                      ></span>
                    </div>

                    <img
                      src={toAbsoluteUrl(
                        "/assets/media/svg/avatars/029-boy-11.svg"
                      )}
                      alt=""
                      className="align-self-end h-100px"
                    />
                  </div>
                  {/* end::Body */}
                </div>
              </div>
            </div>
            <div className="row g-5 g-xl-8 mt-sm-1">
              <div className="col-xl-4">
                <div
                  className={`card bg-success hoverable card-xl-stretch mb-5`}
                >
                  <div className="card-body">
                    <span
                      className={`svg-icon svg-icon-white svg-icon-3x ms-n1`}
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/assets/media/icons/duotune/communication/com010.svg"
                        )}
                        className="mh-30px"
                      />
                    </span>
                    <div className={`text-white fw-bold fs-2 mb-2 mt-3`}>
                      Messages
                    </div>

                    <div className={`fw-semibold text-white`}>
                      {userStats.stats.communityMessages}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div
                  className={`card bg-primary hoverable card-xl-stretch mb-5 mb-xl-8`}
                >
                  <div className="card-body">
                    <span
                      className={`svg-icon svg-icon-white svg-icon-3x ms-n1 text-white fw-bold fs-4`}
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/assets/media/icons/duotune/art/art005.svg"
                        )}
                        className="mh-30px"
                      />
                        Flagged
                    </span>
                    <div className="row">
                        <div className="col-xl-6">
                            <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>
                                Messages
                            </div>

                            <div className={`fw-semibold text-white`}>
                            {userStats.stats.flaggedMessages}
                            </div>
                        </div>
                        
                        <div className="col-xl-6">
                            <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>
                                Posts
                            </div>

                            <div className={`fw-semibold text-white`}>
                            {userStats.stats.flaggedPost}
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div
                  className={`card bg-danger hoverable card-xl-stretch mb-5 mb-xl-8`}
                >
                  <div className="card-body">
                    <span
                      className={`svg-icon svg-icon-white svg-icon-3x ms-n1`}
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/assets/media/icons/duotune/abstract/abs027.svg"
                        )}
                        className="mh-30px"
                      />
                    </span>
                    <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>
                      Channels
                    </div>

                    <div className={`fw-semibold text-white`}>
                      {userStats.stats.participatedChannel}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-5 g-xl-8">
              <div className="col-xl-4">
                <div className={`card bg-dark hoverable card-xl-stretch mb-5`}>
                  <div className="card-body">
                    <span
                      className={`svg-icon svg-icon-white svg-icon-3x ms-n1`}
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/assets/media/icons/duotune/ecommerce/ecm008.svg"
                        )}
                        className="mh-30px"
                      />
                    </span>
                    <div className={`text-white fw-bold fs-2 mb-2 mt-3`}>
                      Posts
                    </div>

                    <div className={`fw-semibold text-white`}>
                      {userStats.stats.communityPost}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div
                  className={`card bg-info hoverable card-xl-stretch mb-5 mb-xl-8`}
                >
                  <div className="card-body">
                    <span
                      className={`svg-icon svg-icon-white svg-icon-3x ms-n1`}
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/assets/media/icons/duotune/communication/com012.svg"
                        )}
                        className="mh-30px"
                      />
                    </span>
                    <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>
                      Comments
                    </div>

                    <div className={`fw-semibold text-white`}>
                      {userStats.stats.communityComment}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div
                  className={`card bg-warning hoverable card-xl-stretch mb-5 mb-xl-8`}
                >
                  <div className="card-body">
                    <span
                      className={`svg-icon svg-icon-white svg-icon-3x ms-n1`}
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/assets/media/icons/duotune/general/gen025.svg"
                        )}
                        className="mh-30px"
                      />
                    </span>
                    <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>
                      Communities
                    </div>
                    <div className={`fw-semibold text-white`}>
                      {userStats.stats.participatedCommunity}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
