import Highcharts, { chart } from "highcharts"
import { ChartComponentTypes, MauComponentDataTypes } from "../../types"

export const newCommunityEngagement = () => {
    return {
        title: {
            text: "",
        },
        series: [
            { name: "Comments", data: [
                [1681750800000, 1448],
                [1681837200000, 1706],
                [1681923600000, 2096],
                [1682010000000, 1428],
                [1682096400000, 1690],
                [1682182800000, 1503],
                [1682269200000, 1529]
            ] },
            { name: "Reactions", data: [
                [1681750800000, 820],
                [1681837200000, 1108],
                [1681923600000, 1352],
                [1682010000000, 886],
                [1682096400000, 1153],
                [1682182800000, 973],
                [1682269200000, 998]
            ] },
        ],
        yAxis: { max: 2096, min: 0, title: "" },
        xAxis: {
            type: "datetime",
            dateTimeLabelFormats: {
            day: "%d %b %Y", //ex- 01 Jan 2016
            },
        },
        chart: {
            type: "column",
            height: "250px",
        },
        credits: { enabled: false },
    }
}

export const newCommunityPostType = () => {
    return {
        title: {
            text: ''
        },
        colors: ['#ED561B', '#DDDF00', '#0072C6'],
        series: [{
            name: 'Post Type',
            data: [
                {name: 'Posts', y: 3226},
                {name: 'Votes', y: 766},
                {name: 'Video Stream', y: 69},
            ]
        }],
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%d %b %Y'    //ex- 01 Jan 2016
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: '300px'
        },
        credits: { enabled: false }
    }
}

export const newCommunityMembers = (data) => {
    return {
        title: {
            text: ''
        },
        colors: ['#a8c686', '#669BBC'],
        series: [{
            name: 'Members',
            data: data.map((item) => ({
                name: item.name,
                y: item.y,
            }))
        }],
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%d %b %Y'    //ex- 01 Jan 2016
            }
        },
        plotOptions: {
            // column: {
            //     stacking: 'normal',
            //     dataLabels: {
            //         enabled: true
            //     }
            // },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                },
                showInLegend: true
            },
        },
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: '300px'
        },
        credits: { enabled: false }
    }
}

export const newUserTrends = (chartData: ChartComponentTypes) => {
    return {
        chart: {
            type: 'column',
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: chartData.mauData?.date || [],
            crosshair: true
        },
        yAxis: {
            title: {
                text: 'Number of users'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        legend: {
            reversed: true,
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        credits: { enabled: false },
        series: chartData.mauData?.mau || [],
    }
}

export const newUserPercentageConfig = () => {
    return {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: [
                '2023-04-01',
                '2023-04-02',
                '2023-04-03',
                '2023-04-04',
                '2023-04-05',
                '2023-04-06',
                '2023-04-07',
                '2023-04-08',
                '2023-04-09',
                '2023-04-10',
                '2023-04-11',
                '2023-04-12'
            ],
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Users (%)'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        credits: { enabled: false },
        series: [{
            name: 'Total',
            data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4,
                194.1, 95.6, 54.4]

        }, {
            name: 'Post',
            data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5,
                106.6, 92.3]

        }, {
            name: 'Comment',
            data: [48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3,
                51.2]

        }, {
            name: 'React',
            data: [42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8,
                51.1]

        }]
    }
}

export const newEngagementPerPostTypeConfig = () => {
    return {
        chart: {
            type: 'column'
        },
        colors: ['#ED561B', '#DDDF00', '#0072C6', '#64E572', '#8085E8'],
        title: {
            text: ''
        },
        xAxis: {
            categories: [
                '2023-04-01',
                '2023-04-02',
                '2023-04-03',
                '2023-04-04',
                '2023-04-05',
                '2023-04-06',
                '2023-04-07',
                '2023-04-08',
                '2023-04-09',
                '2023-04-10',
                '2023-04-11',
                '2023-04-12'
            ],
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Engagement (%)'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        credits: { enabled: false },
        series: [{
            name: 'Text',
            data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4,
                194.1, 95.6, 54.4]

        }, {
            name: 'Image',
            data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5,
                106.6, 92.3]

        }, {
            name: 'Video',
            data: [48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3,
                51.2]

        }, {
            name: 'Poll',
            data: [42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8,
                51.1]

        },
        {
            name: 'LiveStream',
            data: [42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8,
                51.1]

        }]
    }
}

export const newPostsAndCommentsConfig = (chartData: ChartComponentTypes) => {
    return {
        title: {
            text: "",
        },
        series: chartData.postCommentChartsData?.count,
        yAxis: { max: chartData.postCommentChartsData?.max, min: 0, title: "" },
        xAxis: {
            type: "datetime",
            dateTimeLabelFormats: {
            day: "%d %b %Y", //ex- 01 Jan 2016
            },
        },
        chart: {
            type: "column",
            height: "250px",
        },
        credits: { enabled: false },
    }
}


export const newPostTypesConfig = (chartData: ChartComponentTypes) => {
    return {
        title: {
            text: ''
        },
        colors: ['#ED561B', '#DDDF00'],
        series: [{
            name: 'Post Type',
            data: chartData.postCommentChartsData?.postByTypeData
        }],
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%d %b %Y'    //ex- 01 Jan 2016
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: '250px'
        },
        credits: { enabled: false }
    }
}


export const newPopularFeedConfig = (chartData: ChartComponentTypes) => {
    return {
        title: {
            text: ''
        },
        series: [{
            name: 'Regions',
            type: 'treemap',
            layoutAlgorithm: 'squarified',
            allowDrillToNode: true,
            animationLimit: 1000,
            dataLabels: {
                enabled: false
            },
            levels: [{
                level: 1,
                dataLabels: {
                    enabled: true
                },
                borderWidth: 3,
                levelIsConstant: false
            }, {
                level: 1,
                dataLabels: {
                    style: {
                        fontSize: '14px'
                    }
                }
            }],
            data: chartData.postCommentChartsData?.postByTargetData
        }],
        chart: {
            height: '250px'
        },
        credits: { enabled: false }
    }
}

export const newTopInfluencersConfig = (chartData: ChartComponentTypes) => {
    return {
        title: {
            text: ''
        },
        series: [{ name: 'Engagement count', data: chartData.postCommentChartsData?.engagementByPosterData.map(d => d.value) }],
        xAxis: {
            categories: chartData.postCommentChartsData?.engagementByPosterData.map(d => d.name),
            title: {
                text: null
            }
        },
        colors: ["#8085E8"],
        legend: { enabled: false },
        chart: {
            type: 'bar',
            height: '300px'
        },
        yAxis: {
            title: { text: "" }
        },
        credits: { enabled: false }
    }
}

export const newTopPostersConfig = (chartData: ChartComponentTypes) => {
    return {
        title: {
            text: ''
        },
        series: [{ name: 'Post count', data: chartData.postCommentChartsData?.topPostersData.map(d => d.value) }],
        xAxis: {
            categories: chartData.postCommentChartsData?.topPostersData.map(d => d.name),
            title: {
                text: null
            }
        },
        colors: ["#8085E8"],
        legend: { enabled: false },
        chart: {
            type: 'bar',
            height: '300px'
        },
        yAxis: {
            title: { text: "" }
        },
        credits: { enabled: false }
    }
}

export const newTopCommentersConfig = (chartData: ChartComponentTypes) => {
    return {
        title: {
            text: ''
        },
        series: [{ name: 'Comment count', data: chartData.postCommentChartsData?.topCommentersData.map(d => d.value) }],
        xAxis: {
            categories: chartData.postCommentChartsData?.topCommentersData.map(d => d.name),
            title: {
                text: null
            }
        },
        colors: ["#8085E8"],
        legend: { enabled: false },
        chart: {
            type: 'bar',
            height: '300px'
        },
        yAxis: {
            title: { text: "" }
        },
        credits: { enabled: false }
    }
}

export const newTopReactorsConfig = (chartData: ChartComponentTypes) => {
    return {
        title: {
            text: ''
        },
        series: [{ name: 'React count', data: chartData.postCommentChartsData?.topReactorsData.map(d => d.value) }],
        xAxis: {
            categories: chartData.postCommentChartsData?.topReactorsData.map(d => d.name),
            title: {
                text: null
            }
        },
        colors: ["#8085E8"],
        legend: { enabled: false },
        chart: {
            type: 'bar',
            height: '300px'
        },
        yAxis: {
            title: { text: "" }
        },
        credits: { enabled: false }
    }
}

export const newUserEngagementConfig = (chartData: ChartComponentTypes) => {
    return {
        title: {
            text: ''
        },
        colors: ['#24CBE5', '#64E572'],
        series: chartData.postCommentChartsData?.uniqueUserData,
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%d %b %Y'    //ex- 01 Jan 2016
            }
        },
        yAxis: { title: { text: '' } },
        chart: {
            type: 'column',
            height: '250px'
        },
        credits: { enabled: false }
    }
}


export const newMessagesByDayConfig = (chartData: ChartComponentTypes) => {
    return {
        title: {
            text: ''
        },
        series: chartData.chatChartsData?.messageByDay,
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%d %b %Y'    //ex- 01 Jan 2016
            }
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },
        colors: ['#97D8C4'],
        chart: {
            type: 'column',
            height: '250px'
        },
        credits: { enabled: false }
    }
}

export const newTotalMessagesByTypeConfig = (chartData: ChartComponentTypes) => {
    return {
        title: {
            text: ''
        },
        colors: ['#a8c686', '#669BBC'],
        series: [{
            name: 'Messages',
            data: chartData.chatChartsData?.messageByType
        }],
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    format: '<b>{point.name}</b>: {point.y:,.0f}',
                }
            }
        },
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: '250px'
        },
        credits: { enabled: false }
    }
}

export const newUsersByDayConfig = (chartData: ChartComponentTypes) => {
    return {
        title: {
            text: ''
        },
        series: chartData.chatChartsData?.uniqueUserData,
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%d %b %Y'    //ex- 01 Jan 2016
            }
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },
        colors: ['#9C3848'],
        chart: {
            type: 'column',
            height: '250px'
        },
        credits: { enabled: false }
    }
}

export const

newChannelsByTypeConfig = (chartData: ChartComponentTypes) => {
    return {
        title: {
            text: ''
        },
        series: chartData.channelChartsData?.channelByTypeByDay,
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%d %b %Y'    //ex- 01 Jan 2016
            }
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },
        // colors: ['#f4b942'],
        chart: {
            type: 'column',
            height: '250px'
        },
        credits: { enabled: false }
    }
}

export const newTotalMessagesByChannelConfig = (chartData: ChartComponentTypes) => {
    return {
        title: {
            text: ''
        },
        colors: ['#ED561B', '#DDDF00'],
        series: [{
            name: 'Messages',
            data: chartData.chatChartsData?.messageByChannel
        }],
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                }
            }
        },
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: '250px'
        },
        credits: { enabled: false }
    }
}

export const createPieChartConfig = (chartData: ChartComponentTypes) => {
    console.log(chartData.cardTitle)
    const channelData = chartData.channelChartsData?.getChannelCount;

    if (!channelData) {

        console.log("No createPieChartConfig data");
        return null; // Handle case where data is not available
    }

    const pieData = channelData.map(item => ({
        name: item.type,
        y: Number(item.totalCreatedChannels)- Number(item.totalDeletedChannels)
    })) as {name: string, y: number}[];
    if (!pieData) {

        console.log("No piechart data");
        return null; // Handle case where data is not available
    }
    console.log(`pieData: ${JSON.stringify(pieData)}`)
     return {
        title: {
            text: ''
        },
        colors: ['#a8c686', '#669BBC'],
        series: [{
            name: 'Messages',
            data: pieData
        }],
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                }
            }
        },
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: '250px'
        },
        credits: { enabled: false }
    }
};




const arrHours = createHours()
const arrDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
function getPointCategoryName(point, dimension) {
    var series = point.series,
        isY = dimension === 'y',
        axis = series[isY ? 'yAxis' : 'xAxis'];
    return axis.categories[point[isY ? 'y' : 'x']];
}
function createHours() {
    let max_hour = 23
    let min_hour = 0
    let time_arr: string[] = []
    for (let i = min_hour; i <= max_hour; i++) {
        time_arr.push(`${String(i).padStart(2, '0')}:00`)
    }
    return time_arr;
}

function createData(d, h, b) {
    let data_out = []
    let day_arr = d
    let hour_arr = h
    for (let day = 0; day < d.length; day++) {
        for (let hour = 0; hour < h.length; hour++) {
            let value = 0
            data_out.push([hour, day, value])
        }
    }

    if (b) {
        for (let index = 0; index < b.length; index++) {
            let dayData = day_arr.indexOf(b[index].key_as_string.split(" ")[0])
            let makeHourFormat = (b[index].key_as_string.split(" ")[2].split(':')[0].length === 1) ? '0' + b[index].key_as_string.split(" ")[2].split(':')[0] + ":00" : b[index].key_as_string.split(" ")[2].split(':')[0] + ":00"
            let hourData = hour_arr.indexOf(makeHourFormat)
            let indexData = data_out.indexOf(data_out.find((element => element[0] === hourData && element[1] === dayData)))
            data_out[indexData][2] += b[index].doc_count
        }
    }

    return data_out;
}
export const newMessagesHeatmapConfig = (chartData: ChartComponentTypes) => {
    return {
        title: {
            text: ''
        },
        xAxis: {
            categories: arrHours
        },

        yAxis: {
            categories: arrDays,
            title: null,
            reversed: true
        }, accessibility: {
            point: {
                descriptionFormatter: function (point) {
                    var ix = point.index + 1,
                        xName = getPointCategoryName(point, 'x'),
                        yName = getPointCategoryName(point, 'y'),
                        val = point.value;
                    return ix + '. ' + xName + ' sales ' + yName + ', ' + val + '.';
                }
            }
        },
        chart: {
            type: 'heatmap',
            marginTop: 40,
            marginBottom: 80,
            plotBorderWidth: 1
        },
        credits: { enabled: false },
        colorAxis: {
            min: 0,
            minColor: '#FFFFFF',
            maxColor: (Highcharts.getOptions().colors as any[])[0]
        },

        legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 25,
            symbolHeight: 280
        },

        tooltip: {
            formatter: function () {
                return '<b>' +
                    this.point.value + '</b> messages on '+getPointCategoryName(this.point, 'y')+
                    ' ' + getPointCategoryName(this.point, 'x');
            }
        },

        series: [{
            name: 'Messages sent',
            borderWidth: 1,
            data: createData(arrDays, arrHours, chartData.chatChartsData?.messageByHour),
            dataLabels: {
                enabled: true,
                color: '#000000'
            }
        }],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    yAxis: {
                        labels: {
                            formatter: function () {
                                return this.value.charAt(0);
                            }
                        }
                    }
                }
            }]
        }
    }
}

export const newSocialHeatMapConfig = () => {
    const data = [
        {
            "key_as_string": "Tue 18/04/2023 0:00:00",
            "key": 1681750800000,
            "doc_count": 88
        },
        {
            "key_as_string": "Tue 18/04/2023 1:00:00",
            "key": 1681754400000,
            "doc_count": 162
        },
        {
            "key_as_string": "Tue 18/04/2023 2:00:00",
            "key": 1681758000000,
            "doc_count": 878
        },
        {
            "key_as_string": "Tue 18/04/2023 3:00:00",
            "key": 1681761600000,
            "doc_count": 1401
        },
        {
            "key_as_string": "Tue 18/04/2023 4:00:00",
            "key": 1681765200000,
            "doc_count": 47
        },
        {
            "key_as_string": "Tue 18/04/2023 5:00:00",
            "key": 1681768800000,
            "doc_count": 37
        },
        {
            "key_as_string": "Tue 18/04/2023 6:00:00",
            "key": 1681772400000,
            "doc_count": 62
        },
        {
            "key_as_string": "Tue 18/04/2023 7:00:00",
            "key": 1681776000000,
            "doc_count": 75
        },
        {
            "key_as_string": "Tue 18/04/2023 8:00:00",
            "key": 1681779600000,
            "doc_count": 65
        },
        {
            "key_as_string": "Tue 18/04/2023 9:00:00",
            "key": 1681783200000,
            "doc_count": 38
        },
        {
            "key_as_string": "Tue 18/04/2023 10:00:00",
            "key": 1681786800000,
            "doc_count": 58
        },
        {
            "key_as_string": "Tue 18/04/2023 11:00:00",
            "key": 1681790400000,
            "doc_count": 39
        },
        {
            "key_as_string": "Tue 18/04/2023 12:00:00",
            "key": 1681794000000,
            "doc_count": 55
        },
        {
            "key_as_string": "Tue 18/04/2023 13:00:00",
            "key": 1681797600000,
            "doc_count": 87
        },
        {
            "key_as_string": "Tue 18/04/2023 14:00:00",
            "key": 1681801200000,
            "doc_count": 59
        },
        {
            "key_as_string": "Tue 18/04/2023 15:00:00",
            "key": 1681804800000,
            "doc_count": 53
        },
        {
            "key_as_string": "Tue 18/04/2023 16:00:00",
            "key": 1681808400000,
            "doc_count": 51
        },
        {
            "key_as_string": "Tue 18/04/2023 17:00:00",
            "key": 1681812000000,
            "doc_count": 94
        },
        {
            "key_as_string": "Tue 18/04/2023 18:00:00",
            "key": 1681815600000,
            "doc_count": 86
        },
        {
            "key_as_string": "Tue 18/04/2023 19:00:00",
            "key": 1681819200000,
            "doc_count": 115
        },
        {
            "key_as_string": "Tue 18/04/2023 20:00:00",
            "key": 1681822800000,
            "doc_count": 170
        },
        {
            "key_as_string": "Tue 18/04/2023 21:00:00",
            "key": 1681826400000,
            "doc_count": 556
        },
        {
            "key_as_string": "Tue 18/04/2023 22:00:00",
            "key": 1681830000000,
            "doc_count": 197
        },
        {
            "key_as_string": "Tue 18/04/2023 23:00:00",
            "key": 1681833600000,
            "doc_count": 106
        },
        {
            "key_as_string": "Wed 19/04/2023 0:00:00",
            "key": 1681837200000,
            "doc_count": 62
        },
        {
            "key_as_string": "Wed 19/04/2023 1:00:00",
            "key": 1681840800000,
            "doc_count": 41
        },
        {
            "key_as_string": "Wed 19/04/2023 2:00:00",
            "key": 1681844400000,
            "doc_count": 148
        },
        {
            "key_as_string": "Wed 19/04/2023 3:00:00",
            "key": 1681848000000,
            "doc_count": 488
        },
        {
            "key_as_string": "Wed 19/04/2023 4:00:00",
            "key": 1681851600000,
            "doc_count": 210
        },
        {
            "key_as_string": "Wed 19/04/2023 5:00:00",
            "key": 1681855200000,
            "doc_count": 57
        },
        {
            "key_as_string": "Wed 19/04/2023 6:00:00",
            "key": 1681858800000,
            "doc_count": 96
        },
        {
            "key_as_string": "Wed 19/04/2023 7:00:00",
            "key": 1681862400000,
            "doc_count": 142
        },
        {
            "key_as_string": "Wed 19/04/2023 8:00:00",
            "key": 1681866000000,
            "doc_count": 92
        },
        {
            "key_as_string": "Wed 19/04/2023 9:00:00",
            "key": 1681869600000,
            "doc_count": 32
        },
        {
            "key_as_string": "Wed 19/04/2023 10:00:00",
            "key": 1681873200000,
            "doc_count": 43
        },
        {
            "key_as_string": "Wed 19/04/2023 11:00:00",
            "key": 1681876800000,
            "doc_count": 44
        },
        {
            "key_as_string": "Wed 19/04/2023 12:00:00",
            "key": 1681880400000,
            "doc_count": 66
        },
        {
            "key_as_string": "Wed 19/04/2023 13:00:00",
            "key": 1681884000000,
            "doc_count": 60
        },
        {
            "key_as_string": "Wed 19/04/2023 14:00:00",
            "key": 1681887600000,
            "doc_count": 46
        },
        {
            "key_as_string": "Wed 19/04/2023 15:00:00",
            "key": 1681891200000,
            "doc_count": 25
        },
        {
            "key_as_string": "Wed 19/04/2023 16:00:00",
            "key": 1681894800000,
            "doc_count": 94
        },
        {
            "key_as_string": "Wed 19/04/2023 17:00:00",
            "key": 1681898400000,
            "doc_count": 126
        },
        {
            "key_as_string": "Wed 19/04/2023 18:00:00",
            "key": 1681902000000,
            "doc_count": 71
        },
        {
            "key_as_string": "Wed 19/04/2023 19:00:00",
            "key": 1681905600000,
            "doc_count": 72
        },
        {
            "key_as_string": "Wed 19/04/2023 20:00:00",
            "key": 1681909200000,
            "doc_count": 57
        },
        {
            "key_as_string": "Wed 19/04/2023 21:00:00",
            "key": 1681912800000,
            "doc_count": 119
        },
        {
            "key_as_string": "Wed 19/04/2023 22:00:00",
            "key": 1681916400000,
            "doc_count": 65
        },
        {
            "key_as_string": "Wed 19/04/2023 23:00:00",
            "key": 1681920000000,
            "doc_count": 57
        },
        {
            "key_as_string": "Thu 20/04/2023 0:00:00",
            "key": 1681923600000,
            "doc_count": 121
        },
        {
            "key_as_string": "Thu 20/04/2023 1:00:00",
            "key": 1681927200000,
            "doc_count": 36
        },
        {
            "key_as_string": "Thu 20/04/2023 2:00:00",
            "key": 1681930800000,
            "doc_count": 30
        },
        {
            "key_as_string": "Thu 20/04/2023 3:00:00",
            "key": 1681934400000,
            "doc_count": 22
        },
        {
            "key_as_string": "Thu 20/04/2023 4:00:00",
            "key": 1681938000000,
            "doc_count": 44
        },
        {
            "key_as_string": "Thu 20/04/2023 5:00:00",
            "key": 1681941600000,
            "doc_count": 54
        },
        {
            "key_as_string": "Thu 20/04/2023 6:00:00",
            "key": 1681945200000,
            "doc_count": 61
        },
        {
            "key_as_string": "Thu 20/04/2023 7:00:00",
            "key": 1681948800000,
            "doc_count": 121
        },
        {
            "key_as_string": "Thu 20/04/2023 8:00:00",
            "key": 1681952400000,
            "doc_count": 58
        },
        {
            "key_as_string": "Thu 20/04/2023 9:00:00",
            "key": 1681956000000,
            "doc_count": 48
        },
        {
            "key_as_string": "Thu 20/04/2023 10:00:00",
            "key": 1681959600000,
            "doc_count": 52
        },
        {
            "key_as_string": "Thu 20/04/2023 11:00:00",
            "key": 1681963200000,
            "doc_count": 65
        },
        {
            "key_as_string": "Thu 20/04/2023 12:00:00",
            "key": 1681966800000,
            "doc_count": 80
        },
        {
            "key_as_string": "Thu 20/04/2023 13:00:00",
            "key": 1681970400000,
            "doc_count": 38
        },
        {
            "key_as_string": "Thu 20/04/2023 14:00:00",
            "key": 1681974000000,
            "doc_count": 48
        },
        {
            "key_as_string": "Thu 20/04/2023 15:00:00",
            "key": 1681977600000,
            "doc_count": 40
        },
        {
            "key_as_string": "Thu 20/04/2023 16:00:00",
            "key": 1681981200000,
            "doc_count": 49
        },
        {
            "key_as_string": "Thu 20/04/2023 17:00:00",
            "key": 1681984800000,
            "doc_count": 66
        },
        {
            "key_as_string": "Thu 20/04/2023 18:00:00",
            "key": 1681988400000,
            "doc_count": 60
        },
        {
            "key_as_string": "Thu 20/04/2023 19:00:00",
            "key": 1681992000000,
            "doc_count": 77
        },
        {
            "key_as_string": "Thu 20/04/2023 20:00:00",
            "key": 1681995600000,
            "doc_count": 79
        },
        {
            "key_as_string": "Thu 20/04/2023 21:00:00",
            "key": 1681999200000,
            "doc_count": 87
        },
        {
            "key_as_string": "Thu 20/04/2023 22:00:00",
            "key": 1682002800000,
            "doc_count": 151
        },
        {
            "key_as_string": "Thu 20/04/2023 23:00:00",
            "key": 1682006400000,
            "doc_count": 112
        },
        {
            "key_as_string": "Fri 21/04/2023 0:00:00",
            "key": 1682010000000,
            "doc_count": 144
        },
        {
            "key_as_string": "Fri 21/04/2023 1:00:00",
            "key": 1682013600000,
            "doc_count": 170
        },
        {
            "key_as_string": "Fri 21/04/2023 2:00:00",
            "key": 1682017200000,
            "doc_count": 58
        },
        {
            "key_as_string": "Fri 21/04/2023 3:00:00",
            "key": 1682020800000,
            "doc_count": 38
        },
        {
            "key_as_string": "Fri 21/04/2023 4:00:00",
            "key": 1682024400000,
            "doc_count": 35
        },
        {
            "key_as_string": "Fri 21/04/2023 5:00:00",
            "key": 1682028000000,
            "doc_count": 19
        },
        {
            "key_as_string": "Fri 21/04/2023 6:00:00",
            "key": 1682031600000,
            "doc_count": 89
        },
        {
            "key_as_string": "Fri 21/04/2023 7:00:00",
            "key": 1682035200000,
            "doc_count": 227
        },
        {
            "key_as_string": "Fri 21/04/2023 8:00:00",
            "key": 1682038800000,
            "doc_count": 84
        },
        {
            "key_as_string": "Fri 21/04/2023 9:00:00",
            "key": 1682042400000,
            "doc_count": 22
        },
        {
            "key_as_string": "Fri 21/04/2023 10:00:00",
            "key": 1682046000000,
            "doc_count": 41
        },
        {
            "key_as_string": "Fri 21/04/2023 11:00:00",
            "key": 1682049600000,
            "doc_count": 62
        },
        {
            "key_as_string": "Fri 21/04/2023 12:00:00",
            "key": 1682053200000,
            "doc_count": 79
        },
        {
            "key_as_string": "Fri 21/04/2023 13:00:00",
            "key": 1682056800000,
            "doc_count": 52
        },
        {
            "key_as_string": "Fri 21/04/2023 14:00:00",
            "key": 1682060400000,
            "doc_count": 47
        },
        {
            "key_as_string": "Fri 21/04/2023 15:00:00",
            "key": 1682064000000,
            "doc_count": 34
        },
        {
            "key_as_string": "Fri 21/04/2023 16:00:00",
            "key": 1682067600000,
            "doc_count": 67
        },
        {
            "key_as_string": "Fri 21/04/2023 17:00:00",
            "key": 1682071200000,
            "doc_count": 88
        },
        {
            "key_as_string": "Fri 21/04/2023 18:00:00",
            "key": 1682074800000,
            "doc_count": 78
        },
        {
            "key_as_string": "Fri 21/04/2023 19:00:00",
            "key": 1682078400000,
            "doc_count": 64
        },
        {
            "key_as_string": "Fri 21/04/2023 20:00:00",
            "key": 1682082000000,
            "doc_count": 67
        },
        {
            "key_as_string": "Fri 21/04/2023 21:00:00",
            "key": 1682085600000,
            "doc_count": 103
        },
        {
            "key_as_string": "Fri 21/04/2023 22:00:00",
            "key": 1682089200000,
            "doc_count": 69
        },
        {
            "key_as_string": "Fri 21/04/2023 23:00:00",
            "key": 1682092800000,
            "doc_count": 81
        },
        {
            "key_as_string": "Sat 22/04/2023 0:00:00",
            "key": 1682096400000,
            "doc_count": 56
        },
        {
            "key_as_string": "Sat 22/04/2023 1:00:00",
            "key": 1682100000000,
            "doc_count": 108
        },
        {
            "key_as_string": "Sat 22/04/2023 2:00:00",
            "key": 1682103600000,
            "doc_count": 514
        },
        {
            "key_as_string": "Sat 22/04/2023 3:00:00",
            "key": 1682107200000,
            "doc_count": 546
        },
        {
            "key_as_string": "Sat 22/04/2023 4:00:00",
            "key": 1682110800000,
            "doc_count": 125
        },
        {
            "key_as_string": "Sat 22/04/2023 5:00:00",
            "key": 1682114400000,
            "doc_count": 23
        },
        {
            "key_as_string": "Sat 22/04/2023 6:00:00",
            "key": 1682118000000,
            "doc_count": 49
        },
        {
            "key_as_string": "Sat 22/04/2023 7:00:00",
            "key": 1682121600000,
            "doc_count": 52
        },
        {
            "key_as_string": "Sat 22/04/2023 8:00:00",
            "key": 1682125200000,
            "doc_count": 97
        },
        {
            "key_as_string": "Sat 22/04/2023 9:00:00",
            "key": 1682128800000,
            "doc_count": 52
        },
        {
            "key_as_string": "Sat 22/04/2023 10:00:00",
            "key": 1682132400000,
            "doc_count": 54
        },
        {
            "key_as_string": "Sat 22/04/2023 11:00:00",
            "key": 1682136000000,
            "doc_count": 91
        },
        {
            "key_as_string": "Sat 22/04/2023 12:00:00",
            "key": 1682139600000,
            "doc_count": 220
        },
        {
            "key_as_string": "Sat 22/04/2023 13:00:00",
            "key": 1682143200000,
            "doc_count": 87
        },
        {
            "key_as_string": "Sat 22/04/2023 14:00:00",
            "key": 1682146800000,
            "doc_count": 182
        },
        {
            "key_as_string": "Sat 22/04/2023 15:00:00",
            "key": 1682150400000,
            "doc_count": 53
        },
        {
            "key_as_string": "Sat 22/04/2023 16:00:00",
            "key": 1682154000000,
            "doc_count": 50
        },
        {
            "key_as_string": "Sat 22/04/2023 17:00:00",
            "key": 1682157600000,
            "doc_count": 68
        },
        {
            "key_as_string": "Sat 22/04/2023 18:00:00",
            "key": 1682161200000,
            "doc_count": 98
        },
        {
            "key_as_string": "Sat 22/04/2023 19:00:00",
            "key": 1682164800000,
            "doc_count": 235
        },
        {
            "key_as_string": "Sat 22/04/2023 20:00:00",
            "key": 1682168400000,
            "doc_count": 307
        },
        {
            "key_as_string": "Sat 22/04/2023 21:00:00",
            "key": 1682172000000,
            "doc_count": 914
        },
        {
            "key_as_string": "Sat 22/04/2023 22:00:00",
            "key": 1682175600000,
            "doc_count": 1235
        },
        {
            "key_as_string": "Sat 22/04/2023 23:00:00",
            "key": 1682179200000,
            "doc_count": 55
        },
        {
            "key_as_string": "Sun 23/04/2023 0:00:00",
            "key": 1682182800000,
            "doc_count": 127
        },
        {
            "key_as_string": "Sun 23/04/2023 1:00:00",
            "key": 1682186400000,
            "doc_count": 159
        },
        {
            "key_as_string": "Sun 23/04/2023 2:00:00",
            "key": 1682190000000,
            "doc_count": 67
        },
        {
            "key_as_string": "Sun 23/04/2023 3:00:00",
            "key": 1682193600000,
            "doc_count": 52
        },
        {
            "key_as_string": "Sun 23/04/2023 4:00:00",
            "key": 1682197200000,
            "doc_count": 39
        },
        {
            "key_as_string": "Sun 23/04/2023 5:00:00",
            "key": 1682200800000,
            "doc_count": 34
        },
        {
            "key_as_string": "Sun 23/04/2023 6:00:00",
            "key": 1682204400000,
            "doc_count": 53
        },
        {
            "key_as_string": "Sun 23/04/2023 7:00:00",
            "key": 1682208000000,
            "doc_count": 68
        },
        {
            "key_as_string": "Sun 23/04/2023 8:00:00",
            "key": 1682211600000,
            "doc_count": 41
        },
        {
            "key_as_string": "Sun 23/04/2023 9:00:00",
            "key": 1682215200000,
            "doc_count": 34
        },
        {
            "key_as_string": "Sun 23/04/2023 10:00:00",
            "key": 1682218800000,
            "doc_count": 48
        },
        {
            "key_as_string": "Sun 23/04/2023 11:00:00",
            "key": 1682222400000,
            "doc_count": 102
        },
        {
            "key_as_string": "Sun 23/04/2023 12:00:00",
            "key": 1682226000000,
            "doc_count": 95
        },
        {
            "key_as_string": "Sun 23/04/2023 13:00:00",
            "key": 1682229600000,
            "doc_count": 90
        },
        {
            "key_as_string": "Sun 23/04/2023 14:00:00",
            "key": 1682233200000,
            "doc_count": 218
        },
        {
            "key_as_string": "Sun 23/04/2023 15:00:00",
            "key": 1682236800000,
            "doc_count": 220
        },
        {
            "key_as_string": "Sun 23/04/2023 16:00:00",
            "key": 1682240400000,
            "doc_count": 106
        },
        {
            "key_as_string": "Sun 23/04/2023 17:00:00",
            "key": 1682244000000,
            "doc_count": 51
        },
        {
            "key_as_string": "Sun 23/04/2023 18:00:00",
            "key": 1682247600000,
            "doc_count": 60
        },
        {
            "key_as_string": "Sun 23/04/2023 19:00:00",
            "key": 1682251200000,
            "doc_count": 103
        },
        {
            "key_as_string": "Sun 23/04/2023 20:00:00",
            "key": 1682254800000,
            "doc_count": 1021
        },
        {
            "key_as_string": "Sun 23/04/2023 21:00:00",
            "key": 1682258400000,
            "doc_count": 918
        },
        {
            "key_as_string": "Sun 23/04/2023 22:00:00",
            "key": 1682262000000,
            "doc_count": 199
        },
        {
            "key_as_string": "Sun 23/04/2023 23:00:00",
            "key": 1682265600000,
            "doc_count": 69
        },
        {
            "key_as_string": "Mon 24/04/2023 0:00:00",
            "key": 1682269200000,
            "doc_count": 58
        },
        {
            "key_as_string": "Mon 24/04/2023 1:00:00",
            "key": 1682272800000,
            "doc_count": 53
        },
        {
            "key_as_string": "Mon 24/04/2023 2:00:00",
            "key": 1682276400000,
            "doc_count": 61
        },
        {
            "key_as_string": "Mon 24/04/2023 3:00:00",
            "key": 1682280000000,
            "doc_count": 43
        },
        {
            "key_as_string": "Mon 24/04/2023 4:00:00",
            "key": 1682283600000,
            "doc_count": 45
        },
        {
            "key_as_string": "Mon 24/04/2023 5:00:00",
            "key": 1682287200000,
            "doc_count": 34
        },
        {
            "key_as_string": "Mon 24/04/2023 6:00:00",
            "key": 1682290800000,
            "doc_count": 83
        },
        {
            "key_as_string": "Mon 24/04/2023 7:00:00",
            "key": 1682294400000,
            "doc_count": 152
        },
        {
            "key_as_string": "Mon 24/04/2023 8:00:00",
            "key": 1682298000000,
            "doc_count": 109
        },
        {
            "key_as_string": "Mon 24/04/2023 9:00:00",
            "key": 1682301600000,
            "doc_count": 51
        },
        {
            "key_as_string": "Mon 24/04/2023 10:00:00",
            "key": 1682305200000,
            "doc_count": 49
        },
        {
            "key_as_string": "Mon 24/04/2023 11:00:00",
            "key": 1682308800000,
            "doc_count": 38
        },
        {
            "key_as_string": "Mon 24/04/2023 12:00:00",
            "key": 1682312400000,
            "doc_count": 37
        },
        {
            "key_as_string": "Mon 24/04/2023 13:00:00",
            "key": 1682316000000,
            "doc_count": 52
        },
        {
            "key_as_string": "Mon 24/04/2023 14:00:00",
            "key": 1682319600000,
            "doc_count": 60
        },
        {
            "key_as_string": "Mon 24/04/2023 15:00:00",
            "key": 1682323200000,
            "doc_count": 54
        },
        {
            "key_as_string": "Mon 24/04/2023 16:00:00",
            "key": 1682326800000,
            "doc_count": 60
        },
        {
            "key_as_string": "Mon 24/04/2023 17:00:00",
            "key": 1682330400000,
            "doc_count": 66
        },
        {
            "key_as_string": "Mon 24/04/2023 18:00:00",
            "key": 1682334000000,
            "doc_count": 40
        },
        {
            "key_as_string": "Mon 24/04/2023 19:00:00",
            "key": 1682337600000,
            "doc_count": 83
        },
        {
            "key_as_string": "Mon 24/04/2023 20:00:00",
            "key": 1682341200000,
            "doc_count": 186
        },
        {
            "key_as_string": "Mon 24/04/2023 21:00:00",
            "key": 1682344800000,
            "doc_count": 416
        },
        {
            "key_as_string": "Mon 24/04/2023 22:00:00",
            "key": 1682348400000,
            "doc_count": 161
        },
        {
            "key_as_string": "Mon 24/04/2023 23:00:00",
            "key": 1682352000000,
            "doc_count": 84
        }
    ]
    return {
        title: {
            text: ''
        },
        xAxis: {
            categories: arrHours
        },

        yAxis: {
            categories: arrDays,
            title: null,
            reversed: true
        }, accessibility: {
            point: {
                descriptionFormatter: function (point) {
                    var ix = point.index + 1,
                        xName = getPointCategoryName(point, 'x'),
                        yName = getPointCategoryName(point, 'y'),
                        val = point.value;
                    return ix + '. ' + xName + ' sales ' + yName + ', ' + val + '.';
                }
            }
        },
        chart: {
            type: 'heatmap',
            marginTop: 40,
            marginBottom: 80,
            plotBorderWidth: 1
        },
        credits: { enabled: false },
        colorAxis: {
            min: 0,
            minColor: '#FFFFFF',
            maxColor: (Highcharts.getOptions().colors as any[])[0]
        },

        legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 25,
            symbolHeight: 280
        },

        tooltip: {
            formatter: function () {
                return '<b>' +
                    this.point.value + '</b> messages on '+getPointCategoryName(this.point, 'y')+
                    ' ' + getPointCategoryName(this.point, 'x');
            }
        },

        series: [{
            name: 'Messages sent',
            borderWidth: 1,
            data: createData(arrDays, arrHours, data),
            dataLabels: {
                enabled: true,
                color: '#000000'
            }
        }],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    yAxis: {
                        labels: {
                            formatter: function () {
                                return this.value.charAt(0);
                            }
                        }
                    }
                }
            }]
        }
    }
}