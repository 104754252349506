import { ChartComponentTypes, LiveStreamExport } from "../../../types";
import { downloadFile } from "../../../utils/Helper";

const getDateWithFormat = (d) => {
    const date = new Date(d);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    return `${day}-${month}-${year}`
}

export const exportToCsv = (chartData: LiveStreamExport) => {
    let csvString: string = '';
    console.log(`download: ${chartData.cardTitle}`)
    console.log(`exportDate: ${chartData.exportDate}`)
    if (chartData.cardTitle === "table") {

        // csvString = `Date,${chartData.data?.count[0].name},${chartData.data?.count[1].name}\n`;
        // chartData.data?.count[0].data.forEach(([date, postCount], i) => {
        //     const [, commentCount] = chartData.data!.count[1].data[i];
        //     csvString += `${getDateWithFormat(date)},${postCount},${commentCount}\n`;
        // });
        const csvHeaders = [
            'postedUserId',
            'postId',
            'createdAt',
            'reactionsCount',
            'commentsCount',
            'viewerCount',
            'totalPlayTime',
            'averagePlayTime',
          ];
          console.log(chartData.data)
          const csvRows = chartData.data.map((obj) => {
            return csvHeaders.map((header) => {
              let value = obj[header];

              // Handle undefined values
              if (typeof value === 'undefined') {
                value = ''; // Replace with empty string
              }

              // Handle special characters and quoting if needed
              if (typeof value === 'string') {
                value = value.replace(/"/g, '""'); // Escape double quotes
                if (value.includes(',') || value.includes('"') || value.includes('\n')) {
                  value = `"${value}"`; // Enclose in double quotes
                }
              }

              return value;
            }).join(',');
          });


        csvString = `${csvHeaders.join(',')}\n${csvRows.join('\n')}`;
    }


    if (csvString) {
        downloadFile(csvString, `${getDateWithFormat(chartData.exportDate)}_${chartData.cardTitle.trim()}.csv`, 'text/csv');
    }
}