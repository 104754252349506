import React, { useEffect, useState } from "react";
import Select from "react-select";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DataTable from "react-data-table-component";
import * as dayjs from "dayjs";
import {
  getPostTypeStatData,
  getPostUserHistogramByDate,
  getUserPostCountByDate,
} from "../../../services/StatService";
import {
  chartSeriesTypes,
  chartUserSeriesTypes,
  postUserChartTypes,
} from "../../../types";
import { Link, useParams } from "react-router-dom";
import { getPreviousDay } from "../../../utils/Helper";
import Spinner from "../../Spinner";

const intervalOptions = [
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
];

export function SocialUserCharts() {
  const fromDate = dayjs(getPreviousDay(new Date(), 30));
  const untilDate = dayjs(new Date());
  const networkId: string = useParams().networkId as string;
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [postChartData, setPostChartData] = useState({});
  const [reactionChartData, setReactionChartData] = useState({});
  const [PostUserChartData, setPostUserChartData] = useState({});
  const [commentChartData, setCommentChartData] = useState({});
  const [typingTimeOut, setTypingTimeOut] = useState<NodeJS.Timeout>();
  const [searchUserId, setSearchUserId] = useState<string>();
  const [fromPage, setFromPage] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>("postsCount");

  const [userByChat, setUserByChat] = useState<{
    userByPost: {
      user: string;
      flaggedPost: number;
      postCount: number;
      participateCommunity: number;
    }[];
  }>({ userByPost: [] });

  const [tableState, setTableState] = useState<{
    data?: {
      user: string;
      displayName: string;
      followingsCount: number;
      followersCount: number;
      flaggedPost: number;
      postCount: number;
      participateCommunity: number;
    }[];
    loading?: boolean;
    totalRows: number;
    perPage: number;
  }>({
    loading: true,
    totalRows: 0,
    perPage: 10,
  });

  useEffect(() => {
    clearValue();
    setIsPostLoading(true);
    setIsUserLoading(true);
    Promise.all([
      getPostUserHistogramByDate(networkId, "day", fromDate, untilDate),
      getPostTypeStatData(networkId, "day", fromDate, untilDate),
    ]).then(([postStats, postTypeStat]) => {
      console.log("postTypeStat", JSON.stringify(postTypeStat));
      const postPerDay = postStats.data.postPerDay;
      setPostHistogramChart(postPerDay);
    });
  }, [networkId]);

  useEffect(() => {
    if (typingTimeOut) {
      clearTimeout(typingTimeOut);
    }
  }, [networkId]);

  useEffect(() => {
    if (typingTimeOut) {
      clearTimeout(typingTimeOut);
    }
    if (searchUserId && searchUserId.trim().length > 3) {
      setTypingTimeOut(
        setTimeout(() => {
          getUserPostChart();
        }, 1000)
      );
    } else if (!searchUserId) {
      getUserPostChart();
    }
  }, [networkId, searchUserId, fromPage, sortBy]);

  useEffect(() => {
    if (userByChat.userByPost.length > 0) {
      const filteredItems = userByChat.userByPost;
      setTableState({
        data: filteredItems,
        loading: false,
        totalRows: filteredItems.length,
        perPage: tableState.perPage,
      });
    }
  }, [userByChat]);

  const getUserPostChart = () => {
    setIsUserLoading(true);
    setUserByChat({ userByPost: [] });
    Promise.all([
      getUserPostCountByDate(networkId, fromPage, sortBy, searchUserId),
    ]).then(([postUserStats]) => {
      //@ts-ignore
      const userByPost = postUserStats.data.userByPost;
      setuserByPostChart(userByPost);
    });
  };

  const onIntervalChange = (intervalBy: { labe: string; value: string }) => {
    let queryFromDate: any = fromDate;
    let queryUntilDate: any = untilDate;
    if (intervalBy.value == "week" || intervalBy.value == "month") {
      queryFromDate = new Date(
        new Date().getFullYear() - 1,
        new Date().getMonth(),
        new Date().getDate()
      ).getTime();
      queryUntilDate = new Date().getTime();
    }
    setIsPostLoading(true);
    Promise.all([
      getPostUserHistogramByDate(
        networkId,
        intervalBy.value,
        queryFromDate,
        queryUntilDate
      ),
    ]).then(([postStats]) => {
      //@ts-ignore
      const postPerDay = postStats.data.postPerDay;
      setPostHistogramChart(postPerDay);
    });
  };

  const setPostHistogramChart = (postPerDay: object) => {
    const postUserChart: postUserChartTypes = {
      user: {},
      post: {},
      commentsCount: {},
      reactionsCount: {},
      sessionsCount: {},
    };
    const historyList: chartUserSeriesTypes = {
      chart: {
        zoomType: "xy",
      },
      title: {
        text: "",
        style: {
          display: "none",
        },
      },
      xAxis: [
        {
          labels: {
            format: "{value:%d %b %Y}",
            style: {
              fontWeight: "bold",
              fontSize: "12px",
              color: "rgb(100,100,100)",
            },
          },
          categories: [],
          crosshair: true,
        },
      ],
      yAxis: [
        {
          title: {
            text: "",
            style: {
              fontWeight: "bold",
              fontSize: "12px",
              color: "rgb(100,100,100)",
            },
          },
          labels: {
            format: "{value}",
            style: {
              fontWeight: "bold",
              fontSize: "12px",
              color: "rgb(100,100,100)",
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
        headerFormat: "<p>{point.key:%Y-%m-%d}</p> </br>",
      },
      legend: { enabled: false },
      series: [],
      credits: {
        enabled: false,
      },
    };
    const seriesTemp: chartSeriesTypes = {
      name: "Users",
      type: "spline",
      yAxis: 0,
      color: "rgb(100, 100, 100)",
      data: [],
    };
    const seriesTemp_2: chartSeriesTypes = {
      name: "Reactions",
      type: "spline",
      yAxis: 0,
      color: "rgb(85, 185, 185)",
      data: [],
    };
    const seriesTemp_3: chartSeriesTypes = {
      name: "Post",
      type: "spline",
      yAxis: 0,
      color: "rgb(41, 128, 185)",
      data: [],
    };
    const seriesTemp_4: chartSeriesTypes = {
      name: "Comments",
      type: "spline",
      yAxis: 0,
      color: "rgb(210, 77, 87)",
      data: [],
    };
    const seriesTemp_5: chartSeriesTypes = {
      name: "Sessions",
      type: "spline",
      yAxis: 0,
      color: "rgb(128, 185, 42)",
      data: [],
    };

    //@ts-ignore
    postPerDay.forEach((value) => {
      historyList.xAxis[0].categories.push(value.date);
      seriesTemp.data.push(value.uniqueUser);
      seriesTemp_2.data.push(value.reactionsCount);
      seriesTemp_3.data.push(value.postCount);
      seriesTemp_4.data.push(value.commentsCount);
      seriesTemp_5.data.push(value.uniqueUser);
    });

    postUserChart.user = JSON.parse(JSON.stringify(historyList));
    postUserChart.user.yAxis[0].title.text = seriesTemp.name;
    postUserChart.user.yAxis[0].title.style.color = seriesTemp.color;
    postUserChart.user.yAxis[0].labels.style.color = seriesTemp.color;
    postUserChart.user.series = [seriesTemp];

    postUserChart.reactionsCount = JSON.parse(JSON.stringify(historyList));
    postUserChart.reactionsCount.yAxis[0].title.text = seriesTemp_2.name;
    postUserChart.reactionsCount.yAxis[0].title.style.color =
      seriesTemp_2.color;
    postUserChart.reactionsCount.yAxis[0].labels.style.color =
      seriesTemp_2.color;
    postUserChart.reactionsCount.series = [seriesTemp_2];

    postUserChart.post = JSON.parse(JSON.stringify(historyList));
    postUserChart.post.yAxis[0].title.text = seriesTemp_3.name;
    postUserChart.post.yAxis[0].title.style.color = seriesTemp_3.color;
    postUserChart.post.yAxis[0].labels.style.color = seriesTemp_3.color;
    postUserChart.post.series = [seriesTemp_3];

    postUserChart.commentsCount = JSON.parse(JSON.stringify(historyList));
    postUserChart.commentsCount.yAxis[0].title.text = seriesTemp_4.name;
    postUserChart.commentsCount.yAxis[0].title.style.color = seriesTemp_4.color;
    postUserChart.commentsCount.yAxis[0].labels.style.color =
      seriesTemp_4.color;
    postUserChart.commentsCount.series = [seriesTemp_4];

    postUserChart.sessionsCount = JSON.parse(JSON.stringify(historyList));
    postUserChart.sessionsCount.yAxis[0].title.text = seriesTemp_5.name;
    postUserChart.sessionsCount.yAxis[0].title.style.color = seriesTemp_5.color;
    postUserChart.sessionsCount.yAxis[0].labels.style.color =
      seriesTemp_5.color;
    postUserChart.sessionsCount.series = [seriesTemp_5];

    setPostUserChartData(postUserChart.user);
    setReactionChartData(postUserChart.reactionsCount);
    setPostChartData(postUserChart.post);
    setCommentChartData(postUserChart.commentsCount);

    setIsPostLoading(false);
  };

  const setuserByPostChart = (userByPost: object) => {
    setUserByChat({ userByPost: [] });
    //@ts-ignore
    const userByPostData = userByPost.map((ubm) => {
      return {
        user: ubm.userId,
        displayName: ubm.displayName,
        followingsCount: ubm.followingsCount,
        followersCount: ubm.followersCount,
        flaggedPost: ubm.flaggedPosts,
        postCount: ubm.postCount,
        participateCommunity: ubm.participateCommunity,
      };
    });
    setUserByChat({ userByPost: userByPostData });
    setIsUserLoading(false);
  };

  const clearValue = () => {
    setTableState({
      data: [],
      totalRows: 0,
      perPage: 10,
    });
    setUserByChat({ userByPost: [] });

    const emptyList: chartUserSeriesTypes = {
      chart: {
        zoomType: "xy",
      },
      title: {
        text: "",
        style: {
          display: "none",
        },
      },
      xAxis: [
        {
          labels: {
            format: "{value:%d %b %Y}",
            style: {
              fontWeight: "bold",
              fontSize: "12px",
              color: "rgb(100,100,100)",
            },
          },
          categories: [],
          crosshair: true,
        },
      ],
      yAxis: [
        {
          title: {
            text: "",
            style: {
              fontWeight: "bold",
              fontSize: "12px",
              color: "rgb(100,100,100)",
            },
          },
          labels: {
            format: "{value}",
            style: {
              fontWeight: "bold",
              fontSize: "12px",
              color: "rgb(100,100,100)",
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
        headerFormat: "<p>{point.key:%Y-%m-%d}</p> </br>",
      },
      legend: { enabled: false },
      series: [],
      credits: {
        enabled: false,
      },
    };

    setReactionChartData(emptyList);
    setPostChartData(emptyList);
    setPostUserChartData(emptyList);
  };

  const getPage = (page: string) => {
    switch (page) {
      case "prev":
        setFromPage(fromPage - 10);
        break;
      case "next":
        setFromPage(fromPage + 10);
        break;
      default:
        return;
    }
  };
  return (
    <div className="row g-5 g-xl-8">
      <div className="col-xl-12">
        <div className="card card-xl-stretch mb-5 mb-xl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 mb-1">
                Amity Social Trends
              </span>
              <span className="text-muted fw-bold fs-7">
                Trends of Post, Reaction, Comments.
              </span>
            </h3>
            <h3 className="card-title align-items-end flex-column">
              <span className="text-muted fw-bold fs-7">
                <div className="aside-search py-5">
                  <Select
                    //@ts-ignore
                    onChange={onIntervalChange}
                    options={intervalOptions}
                    defaultValue={{
                      label: "Day",
                      value: "day",
                    }}
                    isDisabled={isPostLoading}
                  />
                </div>
              </span>
            </h3>
          </div>
          <div className="card-body">
            {!isPostLoading ? (
              <div className="row">
                <div className="col-xl-6">
                  <HighchartsReact
                    highcharts={Highcharts}
                    allowChartUpdate={true}
                    constructorType={"chart"}
                    options={PostUserChartData}
                  />
                </div>
                <div className="col-xl-6">
                  <HighchartsReact
                    highcharts={Highcharts}
                    allowChartUpdate={true}
                    constructorType={"chart"}
                    options={postChartData}
                  />
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <HighchartsReact
                      highcharts={Highcharts}
                      allowChartUpdate={true}
                      constructorType={"chart"}
                      options={reactionChartData}
                    />
                  </div>
                  <div className="col-xl-6">
                    <HighchartsReact
                      highcharts={Highcharts}
                      allowChartUpdate={true}
                      constructorType={"chart"}
                      options={commentChartData}
                    />
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-xl-6">
                    <HighchartsReact
                      highcharts={Highcharts}
                      allowChartUpdate={true}
                      constructorType={"chart"}
                      options={sessionChartData}
                    />
                  </div>
                </div> */}
              </div>
            ) : (
              <Spinner loading={isPostLoading} />
            )}
          </div>
        </div>
      </div>
      <div className="col-xl-12">
        <div className="card card-xl-stretch">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 mb-1">
                Amity Social Users
              </span>
              <span className="text-muted fw-bold fs-7">
                Users who post in Amity Social.
              </span>
            </h3>
            <h3 className="card-title align-items-end flex-column">
              <span className="text-muted fw-bold fs-7">
                <div className="aside-search py-5">
                  <input
                    type="text"
                    className="form-control w-100"
                    value={searchUserId}
                    onChange={(e) => {
                      setSearchUserId(e.target.value);
                    }}
                    placeholder="Search UserID ..."
                    disabled={isUserLoading}
                  />
                </div>
              </span>
            </h3>
          </div>
          {!isUserLoading ? (
            <div className="card-body">
              {
                <DataTable
                  noHeader={true}
                  columns={[
                    {
                      name: "UserID",
                      cell: (data) => (
                        <Link to={encodeURIComponent(data.user)}>
                          {data.user}
                        </Link>
                      ),
                    },
                    {
                      name: "DisplayName",
                      cell: (data) => (
                        <Link to={encodeURIComponent(data.user)}>
                          {data.displayName}
                        </Link>
                      ),
                    },
                    {
                      name: (
                        <button onClick={() => setSortBy("followingsCount")}>
                          <p>Followings</p>
                        </button>
                      ),
                      selector: (data) => data.followingsCount,
                    },
                    {
                      name: (
                        <button onClick={() => setSortBy("followersCount")}>
                          <p>Followers</p>
                        </button>
                      ),
                      selector: (data) => data.followersCount,
                    },
                    {
                      name: (
                        <button onClick={() => setSortBy("postsCount")}>
                          <p>Post</p>
                        </button>
                      ),
                      selector: (data) => data.postCount,
                    },
                    {
                      name: (
                        <button onClick={() => setSortBy("flaggedPostsCount")}>
                          <p>Flagged Post</p>
                        </button>
                      ),
                      selector: (data) => data.flaggedPost,
                    },
                    {
                      name: (
                        <button onClick={() => setSortBy("communitiesCount")}>
                          <p>Joined Community</p>
                        </button>
                      ),
                      selector: (data) =>
                        data.participateCommunity > 0
                          ? data.participateCommunity
                          : 0,
                    },
                  ]}
                  //@ts-ignore
                  data={tableState.data}
                  progressPending={isUserLoading}
                />
              }
              <nav
                className="text-end"
                style={{ display: tableState.totalRows > 0 ? "" : "none" }}
              >
                <span className="sc-bZkfAO sc-kLLXSd lmGpws OHwJB text-md">{`Items: ${
                  fromPage + 1
                }- ${fromPage + 10}`}</span>
                <div className="sc-ezWOiH jMMJgk">
                  <button
                    onClick={() => {
                      if (fromPage >= 0) getPage("prev");
                    }}
                    id="pagination-previous-page"
                    type="button"
                    aria-label="Previous Page"
                    aria-disabled="true"
                    disabled={false}
                    className="sc-gicCDI bvxQGL"
                    style={{ display: fromPage >= 10 ? `` : `none` }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      if (tableState.totalRows % 10 == 0) getPage("prev");
                      getPage("next");
                    }}
                    id="pagination-next-page"
                    type="button"
                    aria-label="Next Page"
                    aria-disabled="true"
                    disabled={false}
                    className="sc-gicCDI bvxQGL"
                    style={{
                      display: tableState.totalRows % 10 == 0 ? "" : "none",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                  </button>
                </div>
              </nav>
            </div>
          ) : (
            <div className="card-body">
              <Spinner loading={isUserLoading} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
