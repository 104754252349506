import { API } from "aws-amplify";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export interface SocialStatData {
  postByDay: { count: number[][]; unique_user: number[][] };
  commentByDay: { count: number[][]; unique_user: number[][] };
  postByType: { name: string; count: number }[];
  postByTarget: {
    community: { count: number; names: { name: string; count: number }[] };
    user: { count: number; names: { name: string; count: number }[] };
  };
  engagementByPoster: { name: string; count: number }[];
  topPosters: { name: string; count: number }[];
  topCommenters: { name: string; count: number }[];
  topReactors: { name: string; count: number }[];
}

export async function getSocialStatData(
  networkId: string,
  fromDate: dayjs.Dayjs,
  untilDate?: dayjs.Dayjs
): Promise<SocialStatData> {
  // : NetworkInfo[]{
  if (!untilDate) untilDate = dayjs().startOf("day");
  // const timezoneOffset = new Date().getTimezoneOffset();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // (Math.floor(timezoneOffset / 60 * -1)+'').padStart(2,'0')+":"+(Math.abs((timezoneOffset % 60))+'').padStart(2,'0')
  const resp: { data: SocialStatData } = await API.get(
    "dataapi",
    `/stats/social?networkId=${networkId}&fromDate=${fromDate.valueOf()}&untilDate=${untilDate.valueOf()}&timezone=${timezone}`,
    {}
  );
  // Cut unique id suffix out
  return resp.data;
}

export interface MessageStatData {
  messageByDay: {
    count: [number, number];
    unique_user: [number, number];
  };
  messageByChannel: {
    name: string;
    count: number;
    byDay: [number, number];
  }[];
  messageByHour: {
    key: string;
    doc_count: number;
  }[];
  messageByType: {
    name: string;
    count: number;
  }[];
}

export async function getMessageStatData(
  networkId: string,
  fromDate: dayjs.Dayjs,
  untilDate?: dayjs.Dayjs
): Promise<MessageStatData> {
  // : NetworkInfo[]{
  if (!untilDate) untilDate = dayjs().startOf("day");
  // const timezoneOffset = new Date().getTimezoneOffset();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // (Math.floor(timezoneOffset / 60 * -1)+'').padStart(2,'0')+":"+(Math.abs((timezoneOffset % 60))+'').padStart(2,'0')
  const resp: { data: MessageStatData } = await API.get(
    "dataapi",
    `/stats/chat?networkId=${networkId}&fromDate=${fromDate.valueOf()}&untilDate=${untilDate.valueOf()}&timezone=${timezone}`,
    {}
  );
  // Cut unique id suffix out

  return resp.data;
}
export interface ChannelStatData {
  getChannelCount: { type: string; totalCreatedChannels: number; totalDeletedChannels: number; }[];
  channelByType: {
    name: string;
    count: number;
    byDay: [number, number];
  }[];
}

export async function getChannelStatData(
  networkId: string,
  fromDate: dayjs.Dayjs,
  untilDate?: dayjs.Dayjs
): Promise<ChannelStatData> {
  // : NetworkInfo[]{
  if (!untilDate) untilDate = dayjs().startOf("day");
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const resp: { data: ChannelStatData } = await API.get(
    "dataapi",
    `/stats/channel?networkId=${networkId}&fromDate=${fromDate.valueOf()}&untilDate=${untilDate.valueOf()}&timezone=${timezone}`,
    {}
  );
  console.log(`getChannelStatData data: ${JSON.stringify(resp.data)}`)
  return resp.data;
}


///channelCount
// export async function getChannelCiuntStatData(
//   networkId: string,
//   fromDate: dayjs.Dayjs,
//   untilDate?: dayjs.Dayjs
// ): Promise<ChannelStatData> {
//   // : NetworkInfo[]{
//   if (!untilDate) untilDate = dayjs().startOf("day");
//   const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//   const resp: { data: ChannelStatData } = await API.get(
//     "dataapi",
//     `/stats/channel?networkId=${networkId}&fromDate=${fromDate.valueOf()}&untilDate=${untilDate.valueOf()}&timezone=${timezone}`,
//     {}
//   );
//   return resp.data;
// }


export interface EventStatData {
  eventByType: {
    name: string;
    byDay: [number, number];
  }[];
  communityMember: {
    name: string;
    byDay: [number, number, number][];
  }[];
}

export async function getEventStatData(
  networkId: string,
  fromDate: dayjs.Dayjs,
  untilDate?: dayjs.Dayjs
): Promise<EventStatData> {
  // : NetworkInfo[]{
  if (!untilDate) untilDate = dayjs().startOf("day");
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // (Math.floor(timezoneOffset / 60 * -1)+'').padStart(2,'0')+":"+(Math.abs((timezoneOffset % 60))+'').padStart(2,'0')
  const resp: { data: EventStatData } = await API.get(
    "dataapi",
    `/stats/events?networkId=${networkId}&fromDate=${fromDate.valueOf()}&untilDate=${untilDate.valueOf()}&timezone=${timezone}`,
    {}
  );
  // Cut unique id suffix out
  return resp.data;
}

export async function getMessageDateHistogramByHour(
  networkId: string,
  fromDate: dayjs.Dayjs,
  untilDate?: dayjs.Dayjs
): Promise<Array<object>> {
  // : NetworkInfo[]{
  if (!untilDate) untilDate = dayjs().startOf("day");
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // (Math.floor(timezoneOffset / 60 * -1)+'').padStart(2,'0')+":"+(Math.abs((timezoneOffset % 60))+'').padStart(2,'0')
  const resp = await API.get(
    "dataapi",
    `/messages_heatmap?networkId=${networkId}&fromDate=${fromDate.valueOf()}&untilDate=${untilDate.valueOf()}&timezone=${timezone}`,
    {}
  );
  // Cut unique id suffix out
  return resp;
}

export async function getChatUserHistogramByDate(
  networkId: string,
  interval: string,
  fromDate: dayjs.Dayjs,
  untilDate?: dayjs.Dayjs
): Promise<Array<object>> {
  // : NetworkInfo[]{
  if (!untilDate) untilDate = dayjs().startOf("day");
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // (Math.floor(timezoneOffset / 60 * -1)+'').padStart(2,'0')+":"+(Math.abs((timezoneOffset % 60))+'').padStart(2,'0')
  const resp = await API.get(
    "dataapi",
    `/stats/chatHistogram?networkId=${networkId}&fromDate=${fromDate.valueOf()}&untilDate=${untilDate.valueOf()}&timezone=${timezone}&interval=${interval}`,
    {}
  );
  // Cut unique id suffix out)
  return resp;
}

export async function getUserMessagesCountByDate(
  networkId: string,
  from?: number,
  sortBy?: string,
  searchUserId?: string
): Promise<Array<object>> {
  // : NetworkInfo[]{
  console.log(searchUserId);
  let url = `/stats/chatUser?networkId=${networkId}&from=${from}&sortBy=${sortBy}`;
  if (searchUserId) url += `&searchUserId=${searchUserId}`;
  const resp = await API.get("dataapi", url, {});
  // Cut unique id suffix out)
  return resp;
}

export async function getPostUserHistogramByDate(
  networkId: string,
  interval: string,
  fromDate: dayjs.Dayjs,
  untilDate?: dayjs.Dayjs
): Promise<Array<object>> {
  // : NetworkInfo[]{
  if (!untilDate) untilDate = dayjs().startOf("day");
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // (Math.floor(timezoneOffset / 60 * -1)+'').padStart(2,'0')+":"+(Math.abs((timezoneOffset % 60))+'').padStart(2,'0')
  const resp = await API.get(
    "dataapi",
    `/stats/postHistogram?networkId=${networkId}&fromDate=${fromDate.valueOf()}&untilDate=${untilDate.valueOf()}&timezone=${timezone}&interval=${interval}`,
    {}
  );
  // Cut unique id suffix out)
  return resp;
}

export async function getPostTypeStatData(
  networkId: string,
  interval: string,
  fromDate: dayjs.Dayjs,
  untilDate?: dayjs.Dayjs
): Promise<Array<object>> {
  // : NetworkInfo[]{
  if (!untilDate) untilDate = dayjs().startOf("day");
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // (Math.floor(timezoneOffset / 60 * -1)+'').padStart(2,'0')+":"+(Math.abs((timezoneOffset % 60))+'').padStart(2,'0')
  const resp = await API.get(
    "dataapi",
    `/stats/postType?networkId=${networkId}&fromDate=${fromDate.valueOf()}&untilDate=${untilDate.valueOf()}&timezone=${timezone}&interval=${interval}`,
    {}
  );
  // Cut unique id suffix out)
  return resp;
}

export async function getUserPostCountByDate(
  networkId: string,
  from: number = 11,
  sortBy?: string,
  searchUserId?: string
): Promise<Array<object>> {
  // : NetworkInfo[]{
  if (from > 0) {
    from /= 10;
  }
  let url = `/stats/postUser?networkId=${networkId}&page=${from}&sortBy=${sortBy}`;
  if (searchUserId) url += `&searchUserId=${searchUserId}`;
  const resp = await API.get("dataapi", url, {});
  // Cut unique id suffix out)
  return resp;
}

export async function getUserStatByDate(
  networkId: string,
  userId: string,
  fromDate: dayjs.Dayjs,
  untilDate?: dayjs.Dayjs
): Promise<Array<object>> {
  // : NetworkInfo[]{
  if (!untilDate) untilDate = dayjs().startOf("day");
  const resp = await API.get(
    "dataapi",
    `/user/${userId}?networkId=${networkId}&fromDate=${fromDate.valueOf()}&untilDate=${untilDate.valueOf()}`,
    {}
  );
  // Cut unique id suffix out)
  return resp;
}
