import axios from 'axios'
import { Auth } from 'aws-amplify';
import { NetworkInfo } from './AuthService';

const getToken = async () => {
    const userSession = await Auth.currentSession();
    const accessToken = userSession.getAccessToken().getJwtToken();
    return accessToken;
}

export async function getMAU(network: NetworkInfo, type: string, from: string, until: string) {
    let access_token = await getToken()
    let url = type === 'daily' ? 
        `https://ascdashboard.azurewebsites.net/api/mau?network_id=${network.networkId}&region=${network.region}&type=${type}&from=${from}&until=${until}` : 
        `https://ascdashboard.azurewebsites.net/api/mau?network_id=${network.networkId}&region=${network.region}&type=${type}&until=${until}`

    return await axios({
        url: url,
        method: 'get',
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': `application/json`,
            'x-functions-key': `ZJFes4B18JlF32QtRkc9fNS2Zhm5H0A-rc9_lbE6hDlNAzFuE57fkw==`
        }
    });
}

export async function getInactiveUsers(network: NetworkInfo, from: number, page: number, size: number, search: string = '') {
    let access_token = await getToken()
    const url = `https://ascdashboard.azurewebsites.net/api/inactive-users?network_id=${network.networkId}&region=${network.region}&from=${from}&page=${page}&size=${size}&search=${search}`

    return await axios({
        url: url,
        method: 'get',
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': `application/json`,
            'x-functions-key': `IlwHS9q9XA2OrVCPgX74AMfd3trNU8FQlCCGnUA49bq5AzFuEpqFnQ==`
        }
    });
}