import React, { useEffect, useState } from "react";
import Select from "react-select";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DataTable from "react-data-table-component";
import {
  getChatUserHistogramByDate,
  getUserMessagesCountByDate,
} from "../../../services/StatService";
import {
  chartSeriesTypes,
  chartUserSeriesTypes,
  chatUserChartTypes,
} from "../../../types";
import { Link, useParams } from "react-router-dom";
import { getPreviousDay } from "../../../utils/Helper";
import Spinner from "../../Spinner";
import dayjs from "dayjs";

const intervalOptions = [
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
];

export function ChatUserCharts() {
  const fromDate = dayjs(getPreviousDay(new Date(), 30));
  const untilDate = dayjs(new Date());
  const networkId: string = useParams().networkId as string;
  const [isChannelLoading, setIsChannelLoading] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [ChatMessageChartData, setChatMessageChartData] = useState({});
  const [ChatChannelChartData, setChatChannelChartData] = useState({});
  const [ChatUserChartData, setChatUserChartData] = useState({});
  const [ChatFlagedMessagesChartData, setChatFlagedMessagesChartData] =
    useState({});
  const [typingTimeOut, setTypingTimeOut] = useState<NodeJS.Timeout>();
  const [searchUserId, setSearchUserId] = useState<string>();
  const [fromPage, setFromPage] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>("_count");

  const [userByChat, setUserByChat] = useState<{
    userByMessage: {
      user: string;
      flaggedMessages: number;
      msgCount: number;
      participateChannel: number;
    }[];
  }>({ userByMessage: [] });

  const [tableState, setTableState] = useState<{
    data?: {
      user: string;
      flaggedMessages: number;
      msgCount: number;
      participateChannel: number;
    }[];
    loading: boolean;
    totalRows: number;
    perPage: number;
  }>({
    loading: true,
    totalRows: 0,
    perPage: 10,
  });

  useEffect(() => {
    clearValue();
    setIsChannelLoading(true);
    setIsUserLoading(true);
    Promise.all([
      getChatUserHistogramByDate(networkId, "day", fromDate, untilDate),
    ]).then(([chatUserStats]) => {
      const messagePerDay = chatUserStats.data.messagePerDay;
      setMessageHistogramChart(messagePerDay);
    });
  }, [networkId]);

  useEffect(() => {
    if (typingTimeOut) {
      clearTimeout(typingTimeOut);
    }
    if (searchUserId && searchUserId.trim().length > 3) {
      setTypingTimeOut(
        setTimeout(() => {
          getUserMessagesChart();
        }, 1000)
      );
    } else if (!searchUserId) {
      getUserMessagesChart();
    }
  }, [networkId, searchUserId, fromPage, sortBy]);

  useEffect(() => {
    if (userByChat.userByMessage.length > 0) {
      const filteredItems = userByChat.userByMessage;
      setTableState({
        data: filteredItems,
        loading: false,
        totalRows: filteredItems.length,
        perPage: tableState.perPage,
      });
    }
  }, [userByChat]);

  const onIntervalChange = (intervalBy: { labe: string; value: string }) => {
    let queryFromDate: any = fromDate;
    let queryUntilDate: any = untilDate;
    if (intervalBy.value == "week" || intervalBy.value == "month") {
      queryFromDate = new Date(
        new Date().getFullYear() - 1,
        new Date().getMonth(),
        new Date().getDate()
      ).getTime();
      queryUntilDate = new Date().getTime();
    }
    setIsChannelLoading(true);
    Promise.all([
      getChatUserHistogramByDate(
        networkId,
        intervalBy.value,
        queryFromDate,
        queryUntilDate
      ),
    ]).then(([chatUserStats]) => {
      const messagePerDay = chatUserStats.data.messagePerDay;
      setMessageHistogramChart(messagePerDay);
    });
  };

  const getUserMessagesChart = () => {
    setIsUserLoading(true);
    setUserByChat({ userByMessage: [] });
    Promise.all([
      getUserMessagesCountByDate(networkId, fromPage, sortBy, searchUserId),
    ]).then(([messagesUserStats]) => {
      const userByMessage = messagesUserStats.data.userByMessage;
      setUserByMessageChart(userByMessage);
    });
  };

  const setMessageHistogramChart = (messagePerDay: object) => {
    const chatUserChart: chatUserChartTypes = {
      user: {},
      message: {},
      channel: {},
      flagedMessages: {},
    };
    const historyList: chartUserSeriesTypes = {
      chart: {
        zoomType: "xy",
      },
      title: {
        text: "",
        style: {
          display: "none",
        },
      },
      xAxis: [
        {
          labels: {
            format: "{value:%d %b %Y}",
            style: {
              fontWeight: "bold",
              fontSize: "12px",
              color: "rgb(100,100,100)",
            },
          },
          categories: [],
          crosshair: true,
        },
      ],
      yAxis: [
        {
          title: {
            text: "",
            style: {
              fontWeight: "bold",
              fontSize: "12px",
              color: "rgb(100,100,100)",
            },
          },
          labels: {
            format: "{value}",
            style: {
              fontWeight: "bold",
              fontSize: "12px",
              color: "rgb(100,100,100)",
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
        headerFormat: "<p>{point.key:%Y-%m-%d}</p> </br>",
      },
      legend: { enabled: false },
      series: [],
      credits: {
        enabled: false,
      },
    };
    const seriesTemp: chartSeriesTypes = {
      name: "Users",
      type: "spline",
      yAxis: 0,
      color: "rgb(100, 100, 100)",
      data: [],
    };
    const seriesTemp_2: chartSeriesTypes = {
      name: "Channels",
      type: "spline",
      yAxis: 0,
      color: "rgb(85, 185, 185)",
      data: [],
    };
    const seriesTemp_3: chartSeriesTypes = {
      name: "Messages",
      type: "spline",
      yAxis: 0,
      color: "rgb(41, 128, 185)",
      data: [],
    };
    const seriesTemp_4: chartSeriesTypes = {
      name: "Flagged",
      type: "spline",
      yAxis: 0,
      color: "rgb(210, 77, 87)",
      data: [],
    };

    messagePerDay.forEach((message) => {
      historyList.xAxis[0].categories.push(message.date);
      seriesTemp.data.push(message.uniqueUser);
      seriesTemp_2.data.push(message.uniqueChannel);
      seriesTemp_3.data.push(message.msgCount);
      seriesTemp_4.data.push(message.flaggedMessages);
    });

    chatUserChart.user = JSON.parse(JSON.stringify(historyList));
    chatUserChart.user.yAxis[0].title.text = seriesTemp.name;
    chatUserChart.user.yAxis[0].title.style.color = seriesTemp.color;
    chatUserChart.user.yAxis[0].labels.style.color = seriesTemp.color;
    chatUserChart.user.series = [seriesTemp];

    chatUserChart.channel = JSON.parse(JSON.stringify(historyList));
    chatUserChart.channel.yAxis[0].title.text = seriesTemp_2.name;
    chatUserChart.channel.yAxis[0].title.style.color = seriesTemp_2.color;
    chatUserChart.channel.yAxis[0].labels.style.color = seriesTemp_2.color;
    chatUserChart.channel.series = [seriesTemp_2];

    chatUserChart.message = JSON.parse(JSON.stringify(historyList));
    chatUserChart.message.yAxis[0].title.text = seriesTemp_3.name;
    chatUserChart.message.yAxis[0].title.style.color = seriesTemp_3.color;
    chatUserChart.message.yAxis[0].labels.style.color = seriesTemp_3.color;
    chatUserChart.message.series = [seriesTemp_3];

    chatUserChart.flagedMessages = JSON.parse(JSON.stringify(historyList));
    chatUserChart.flagedMessages.yAxis[0].title.text = seriesTemp_4.name;
    chatUserChart.flagedMessages.yAxis[0].title.style.color =
      seriesTemp_4.color;
    chatUserChart.flagedMessages.yAxis[0].labels.style.color =
      seriesTemp_4.color;
    chatUserChart.flagedMessages.series = [seriesTemp_4];

    setChatUserChartData(chatUserChart.user);
    setChatChannelChartData(chatUserChart.channel);
    setChatMessageChartData(chatUserChart.message);
    setChatFlagedMessagesChartData(chatUserChart.flagedMessages);

    setIsChannelLoading(false);
  };

  const setUserByMessageChart = (userByMessage: object) => {
    const userByChatData = userByMessage.map((ubm) => {
      return {
        user: ubm.userId,
        flaggedMessages: ubm.flaggedMessages,
        msgCount: ubm.msgCount,
        participateChannel: ubm.participateChannel,
      };
    });
    setUserByChat({ userByMessage: userByChatData });
    setIsUserLoading(false);
  };

  const clearValue = () => {
    setTableState({
      data: [],
      loading: true,
      totalRows: 0,
      perPage: 10,
    });
    setUserByChat({ userByMessage: [] });

    const emptyList: chartUserSeriesTypes = {
      chart: {
        zoomType: "xy",
      },
      title: {
        text: "",
        style: {
          display: "none",
        },
      },
      xAxis: [
        {
          labels: {
            format: "{value:%d %b %Y}",
            style: {
              fontWeight: "bold",
              fontSize: "12px",
              color: "rgb(100,100,100)",
            },
          },
          categories: [],
          crosshair: true,
        },
      ],
      yAxis: [
        {
          title: {
            text: "",
            style: {
              fontWeight: "bold",
              fontSize: "12px",
              color: "rgb(100,100,100)",
            },
          },
          labels: {
            format: "{value}",
            style: {
              fontWeight: "bold",
              fontSize: "12px",
              color: "rgb(100,100,100)",
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
        headerFormat: "<p>{point.key:%Y-%m-%d}</p> </br>",
      },
      legend: { enabled: false },
      series: [],
      credits: {
        enabled: false,
      },
    };

    setChatChannelChartData(emptyList);
    setChatMessageChartData(emptyList);
    setChatUserChartData(emptyList);
  };

  const getPage = (page: string) => {
    switch (page) {
      case "prev":
        setFromPage(fromPage - 10);
        break;
      case "next":
        setFromPage(fromPage + 10);
        break;
      default:
        return;
    }
  };

  return (
    <div className="row g-5 g-xl-8">
      <div className="col-xl-12">
        <div className="card card-xl-stretch mb-5 mb-xl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 mb-1">
                Amity Chat Trends
              </span>
              <span className="text-muted fw-bold fs-7">
                Trends of messages, users, channels.
              </span>
            </h3>
            <h3 className="card-title align-items-end flex-column">
              <span className="text-muted fw-bold fs-7">
                <div className="aside-search py-5">
                  <Select
                    onChange={onIntervalChange}
                    options={intervalOptions}
                    defaultValue={{
                      label: "Day",
                      value: "day",
                    }}
                    isDisabled={isChannelLoading}
                  />
                </div>
              </span>
            </h3>
          </div>
          <div className="card-body">
            {!isChannelLoading ? (
              <div className="row">
                <div className="col-xl-6">
                  <HighchartsReact
                    highcharts={Highcharts}
                    allowChartUpdate={true}
                    constructorType={"chart"}
                    options={ChatUserChartData}
                  />
                </div>
                <div className="col-xl-6">
                  <HighchartsReact
                    highcharts={Highcharts}
                    allowChartUpdate={true}
                    constructorType={"chart"}
                    options={ChatMessageChartData}
                  />
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <HighchartsReact
                      highcharts={Highcharts}
                      allowChartUpdate={true}
                      constructorType={"chart"}
                      options={ChatChannelChartData}
                    />
                  </div>
                  <div className="col-xl-6">
                    <HighchartsReact
                      highcharts={Highcharts}
                      allowChartUpdate={true}
                      constructorType={"chart"}
                      options={ChatFlagedMessagesChartData}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Spinner loading={isChannelLoading} />
            )}
          </div>
        </div>
      </div>
      <div className="col-xl-12">
        <div className="card card-xl-stretch mb-5 mb-xl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 mb-1">
                Amity Chat Users
              </span>
              <span className="text-muted fw-bold fs-7">
                Users who participated in Amity chat.
              </span>
            </h3>
            <h3 className="card-title align-items-end flex-column">
              <span className="text-muted fw-bold fs-7">
                <div className="aside-search py-5">
                  <input
                    type="text"
                    className="form-control w-100"
                    value={searchUserId}
                    onChange={(e) => {
                      setSearchUserId(e.target.value);
                    }}
                    placeholder="Search UserID ..."
                    disabled={isUserLoading}
                  />
                </div>
              </span>
            </h3>
          </div>
          {!isUserLoading ? (
            <div className="card-body">
              {
                <DataTable
                  title=""
                  columns={[
                    {
                      name: "UserID",
                      cell: (data) => <Link to={data.user}>{data.user}</Link>,
                    },
                    {
                      name: (
                        <button onClick={() => setSortBy("_count")}>
                          <p>Messages</p>
                        </button>
                      ),
                      selector: (data) => data.msgCount,
                    },
                    {
                      name: (
                        <button onClick={() => setSortBy("flaggedMessages")}>
                          <p>Flagged Messages</p>
                        </button>
                      ),
                      selector: (data) => data.flaggedMessages,
                    },
                    {
                      name: (
                        <button onClick={() => setSortBy("participateChannel")}>
                          <p>Joined Channel</p>
                        </button>
                      ),
                      selector: (data) => data.participateChannel,
                    },
                  ]}
                  data={tableState.data}
                  progressPending={isUserLoading}
                  subHeader
                />
              }
              <nav
                className="text-end"
                style={{ display: tableState.totalRows > 0 ? "" : "none" }}
              >
                <span className="sc-bZkfAO sc-kLLXSd lmGpws OHwJB text-md">{`Items: ${
                  fromPage + 1
                }- ${fromPage + 10}`}</span>
                <div className="sc-ezWOiH jMMJgk">
                  <button
                    onClick={() => {
                      if (fromPage >= 0) getPage("prev");
                    }}
                    id="pagination-previous-page"
                    type="button"
                    aria-label="Previous Page"
                    aria-disabled="true"
                    disabled={false}
                    className="sc-gicCDI bvxQGL"
                    style={{ display: fromPage >= 10 ? `` : `none` }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      if (tableState.totalRows % 10 == 0) getPage("prev");
                      getPage("next");
                    }}
                    id="pagination-next-page"
                    type="button"
                    aria-label="Next Page"
                    aria-disabled="true"
                    disabled={false}
                    className="sc-gicCDI bvxQGL"
                    style={{
                      display: tableState.totalRows % 10 == 0 ? "" : "none",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                  </button>
                </div>
              </nav>
            </div>
          ) : (
            <div className="card-body">
              <Spinner loading={isUserLoading} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}