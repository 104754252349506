import React, { useEffect } from "react";
import { useState } from "react"
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Header from "../components/Header";
import { PostList } from "../components/Posts/PostList";
import { userState } from "../state/States";
import { EventEmitter } from 'events';
import debounce from "lodash/debounce";

function FlaggedPostPage() {
    const user = useRecoilValue(userState);
    const { networkId } = useParams();
    const network = user?.networks.filter(n => n.networkId === networkId)[0];
	const [searchPost, setSearchPost] = useState<string>("");
	const [postLoading, setPostLoading] = useState<boolean>(false);
	const [typingTimeOut, setTypingTimeOut] = useState<NodeJS.Timeout>();

	const eventEmitter = new EventEmitter();

	const delayMs = 500; // set the delay time to 500ms

	let timeout: NodeJS.Timeout | null = null;

	const debouncedSearch = debounce((value: string) => {
		setSearchPost(value)
	}, 500);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		debouncedSearch(value);
	  };

	// START WORKING ON IMAGE POST, DUPLICATE FIX FROM DASHBOARD TO ESEARCH EXTENSION, THEN ONTO ANALYTICS
	return (<div>
		<Header pageTitle={"Flagged Posts"} pageDescription={"Flagged posts & comments in "} networkName={network ? network.name : "no network"} networkId={network ? network.networkId : "no network"} />
		<div className="d-flex flex-column flex-column-fluid" id="kt_content">

			<div className="post d-flex flex-column-fluid" id="kt_post">

				

				<div id="kt_content_container" className="container-xxl">

					<div className="row">
						<h3 className="card-title">
							<span className="text-muted fw-bold fs-7">
								<div className="aside-search py-5">
								<input
									type="text"
									className="form-control w-100"
									onChange={handleInputChange}
									placeholder="Search post ..."
								/>
								</div>
							</span>
						</h3>
					</div>

					<div className="row g-5 g-xl-8">
						<PostList sortBy={"flagCount"} search={searchPost} />
						<PostList sortBy={"comment-flagCount"} />
					</div>

				</div>

			</div>

		</div></div>
	)
}

export default FlaggedPostPage