import { AmityChannelFromWebhookEvent } from "../../types";
import { Link } from 'react-router-dom';

function addDisable(classname, channelType) {
    if (channelType == "conversation") {
        classname = classname.replace("text-gray-800", "text-gray-400")
        classname = classname.replace("text-gray-700", "text-gray-400")
        return classname + " disabled"
    } else {
        return classname
    }
}

function viewChat(channelId) {
    return "chat/channels/view" + "?channelId=" + channelId;
}

export function ChannelEntry({ channel }: { channel: AmityChannelFromWebhookEvent }) {
    return (
        <div className="col-md-6 col-xxl-4">
            <div className="card">
                <div className="card-body d-flex flex-center flex-column p-9">
                    <div className="mb-5">
                        <div className="symbol symbol-75px symbol-circle">
                            <span className="symbol-label bg-light-danger text-danger fs-1 fw-bolder">{(channel.displayName) ? channel.displayName.substring(0, 1).toUpperCase() : ""}</span>
                            <div className="symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3"></div>
                        </div>
                    </div>

                    <p className={addDisable("fs-4 text-gray-800 fw-bolder mb-0", channel.type)}>{(channel.displayName) ? channel.displayName : "undefined"}</p>


                    <div className="fw-bold text-gray-400 mb-6">{channel.channelId}</div>
                    <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                        <a className={addDisable("d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2", channel.type)}>

                            <span className="svg-icon svg-icon-4 me-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="black"></path>
                                    <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="black"></path>
                                </svg>
                            </span>
                            {channel.type.substring(0, 1).toUpperCase() + channel.type.substring(1)}
                        </a>
                        <a className={addDisable("d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2", channel.type)}>

                            <span className="svg-icon svg-icon-4 me-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="black"></path>
                                    <path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="black"></path>
                                </svg>
                            </span>
                            {channel.createdAt.substring(0, 16).replace("T", " ")}</a>

                    </div>

                    <div className="d-flex flex-center flex-wrap mb-5">

                        <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-3 mb-3 text-center">
                            <div className={addDisable("fs-6 fw-bolder text-gray-700", channel.type)}>{channel.memberCount}</div>
                            <div className="fw-bold text-gray-400">Members</div>
                        </div>


                        <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-3 mb-3 text-center">
                            <div className={addDisable("fs-6 fw-bolder text-gray-700", channel.type)}>{channel.messageCount}</div>
                            <div className="fw-bold text-gray-400">Messages</div>
                        </div>

                    </div>
                    <Link to={"/chat/channels/view" + "?channelId=" + channel.channelId}>
                        <button className="btn btn-sm btn-light-primary fw-bolder" data-kt-drawer-show="true"
                            data-kt-drawer-target="#kt_drawer_chat" disabled={channel.type === 'conversation'}>
                            View Conversation
                        </button>
                    </Link>

                </div>

            </div>

        </div>
    )
}