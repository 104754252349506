import React, { useEffect, useState } from "react";
import Select from "react-select";
import Highcharts, { dateFormat } from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DataTable from "react-data-table-component";
import * as dayjs from "dayjs";
import {
    getPostTypeStatData,
    getPostUserHistogramByDate,
    getUserPostCountByDate,
} from "../../../services/StatService";
import {
    AmityPost,
    chartSeriesTypes,
    chartUserSeriesTypes,
    postUserChartTypes,
    liveStreamPostChartTypes
} from "../../../types";
import { Link, useParams } from "react-router-dom";
import { getPreviousDay, downloadFile, formatDate } from "../../../utils/Helper";
import Spinner from "../../Spinner";
import { getStreamUrl } from "../../../services/FileService";
import { getViewerData, fetchViewers, } from "../../../services/ViewerService";
import { getFlaggedPostsByUserid, extractMedia } from "../../../services/PostService";
import { userState } from "../../../state/States";
import { useRecoilValue } from "recoil";
import ReactDOM from 'react-dom';
import { getInactiveUsers } from "../../../services/AzureService";
import { Overlay } from "../../Posts/OverLayPost";
import {
    PostListData,
    SortBy,
    getPostsByType,
} from "../../../services/PostService";

import { deletedPostIdsState } from "../../../state/States";
import Modal from 'react-modal';
import { PostEntry } from '../../../components/Posts/PostEntry'
import { exportToCsv } from "./LiveStreamExport";
import UserCard from "./UserCard";
const intervalOptions = [
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
];

const lastMonthIntervalOptions = [
    { value: 1, label: "Since last month" },
    { value: 2, label: "Since 2 months ago" },
    { value: 3, label: "Since 3 months ago" },
    { value: 4, label: "Since 4 months ago" },
    { value: 5, label: "Since 5 months ago" },
    { value: 6, label: "Since 6 months ago" },
];

export function LiveStreamCharts() {
    const fromDate = dayjs(getPreviousDay(new Date(), 30));
    const untilDate = dayjs(new Date());
    const networkId: string = useParams().networkId as string;
    const user = useRecoilValue(userState);
    const networkInfo = user?.networks.filter(
        (n) => n.networkId === networkId
    )[0];
    const network = user.networks.filter(n => n.networkId === networkId)[0];
    const [isPostLoading, setIsPostLoading] = useState(false);
    const [isUserLoading, setIsUserLoading] = useState(false);
    const [isInactiveUserLoading, setIsInactiveUserLoading] = useState(false);
    const [postChartData, setPostChartData] = useState({});
    const [reactionChartData, setReactionChartData] = useState({});
    const [PostUserChartData, setPostUserChartData] = useState({});
    const [sessionChartData, setSessionChartData] = useState({});
    const [commentChartData, setCommentChartData] = useState({});
    const [typingTimeOut, setTypingTimeOut] = useState<NodeJS.Timeout>();
    const [searchUserId, setSearchUserId] = useState<string>();
    const [searchInactiveUserId, setSearchInactiveUserId] = useState<string>();
    const [fromPage, setFromPage] = useState<number>(0);
    const [inactiveFromPage, setInactiveFromPage] = useState<number>(1);
    const [inactiveFromMonth, setInactiveFromMonth] = useState<number>(3);
    const [sortBy, setSortBy] = useState<string>("createdAt");
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);

    const [isViewerModalOpen, setIsViewerModalOpen] = useState(false);

    const handleToggleOverlay = (post) => {
        console.log("handleToggleOverlay")
        setSelectedPost(post);
        setShowOverlay(!showOverlay);
    };



    const [userByChat, setUserByChat] = useState<{
        userByPost: {
            userId: string;
            postId: string;
            createdAt: number;
            reactionCount: number;
            commentCount: number;
            viewerCount: number;
            totalPlayTime: number;
            averagePlayTime: number;
            playBack: String
        }[];
    }>({ userByPost: [] });

    const [inactiveUserResp, setInactiveUserResp] = useState<
        {
            user_id: string;
            max_created_at: string;
        }[]
    >([]);

    type TableDataItem = {
        postedUserId: string;
        postId: string;
        createdAt: number;
        reactionsCount: number;
        commentsCount: number;
        viewerCount: number;
        totalPlayTime: number;
        averagePlayTime: number;
        playBack: string;  // Use lowercase 'string' instead of 'String'
    };
    const [tableState, setTableState] = useState<{
        data?: TableDataItem[];
        loading: boolean;
        totalRows: number;
        perPage: number;
    }>({
        loading: true,
        totalRows: 0,
        perPage: 10,
    });
    interface User {
        postId: string;
        userId: string;
        streamId: string;
        displayName: string;
        viewTime: string; // Assuming viewTime remains a string representation of a number
        joinTimestamp: number; // Keeping as a number (Unix timestamp)
        lastUpdateTimestamp: number; // Similarly, a number (Unix timestamp)
    }
    const sortByViewerCount = (a: TableDataItem, b: TableDataItem): number => {
        return b.viewerCount - a.viewerCount;  // Descending order
    };



    const sortTableByViewerCount = () => {
        if (tableState.data) {
            const sortedData = [...tableState.data].sort(sortByViewerCount);
            setTableState(prevState => ({
                ...prevState,
                data: sortedData
            }));
        }
    };

    type SortableFields = keyof TableDataItem;

    const sortByField = (field: SortableFields, order: 'asc' | 'desc' = 'asc') => {
        return (a: TableDataItem, b: TableDataItem): number => {
            if (typeof a[field] === 'number' && typeof b[field] === 'number') {
                return order === 'asc' ? (a[field] as number) - (b[field] as number) : (b[field] as number) - (a[field] as number);
            } else if (typeof a[field] === 'string' && typeof b[field] === 'string') {
                return order === 'asc' ? (a[field] as string).localeCompare(b[field] as string) : (b[field] as string).localeCompare(a[field] as string);
            }
            return 0;
        };
    };


    const sortTableByField = (field: SortableFields, order: 'asc' | 'desc' = 'asc') => {
        if (tableState.data) {
            const sortedData = [...tableState.data].sort(sortByField(field, 'desc'));
            setTableState(prevState => ({
                ...prevState,
                data: sortedData
            }));
        }
    };





    interface ViewerResponse {
        userList: User[];
        nextPage: string | null;
    }
    const [inactiveTableState, setInactiveTableState] = useState<{
        data?: {
            user_id: string;
            max_created_at: string;
        }[];
        loading: Boolean;
        totalRows: number;
        perPage: number;
    }>({
        loading: true,
        totalRows: 0,
        perPage: 10,
    });

    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>..
    const listId = Math.round(Math.random() * 1000000);
    const [posts, setPosts] = useState<PostListData>({
        data: [],
        children: {},
        count: 0,
    });
    const [postLoading, setPostLoading] = useState<boolean>(false);
    const deletedPostIds = useRecoilValue(deletedPostIdsState);

    const loadMorePosts = async function () {
        console.log("Loading more posts....");
        setPostLoading(true);
        const newPosts = networkId
            ? await getPostsByType(
                networkId,
                sortBy as SortBy,//commentsCount, engagementCount,reactionCount, createdAt
                "liveStream",
                posts.data.length,
                1000
            )
            : { count: 0, data: [], children: [] };
        ///call view API
        console.log("getting data..")
        console.log(newPosts)

        // Remove empty items from newPosts.data
        newPosts.data = newPosts.data.filter((item) => item.postId !== undefined);

        const arrayOfPostIds = newPosts.data.map((item, index) => {

            return {
                ...item,
                postId: newPosts.data[index].postId ?? "emptyPost",

                viewerCount: item.viewerCount,
                totalPlayTime: item.totalPlayTime,
                averagePlayTime: item.averagePlayTime,
            };


        });
        console.log(arrayOfPostIds)
        var productionNetworkIdKTB = process.env.REACT_APP_ENV_KTB_NETWORK_ID || ''
        console.log("ENV:" + productionNetworkIdKTB)
        const viewerData = await getViewerData(arrayOfPostIds, network)

        console.log("")
        console.log(JSON.stringify(viewerData))
        const viewerDataMap = Object.fromEntries(viewerData.map(item => [item.postId, item]));
        const mappedData = newPosts.data.map((item) => {
            const viewerData = viewerDataMap[item.postId] ?? {};
            return {
                ...item,
                createdAt: formatDate(item.createdAt),
                viewerCount: Number(viewerData.viewerCount) || 0,
                totalPlayTime: Number(viewerData.totalViewTime) || 0,
                averagePlayTime: Number(viewerData.averageViewTime) || 0,
            };
        });

        ///call view api
        posts.count = newPosts.count;
        posts.data.push(...mappedData);
        Object.assign(posts.children, newPosts.children);
        console.log("Posts loaded: ", posts);

        setPostLoading(false);
        setPosts(JSON.parse(JSON.stringify(posts)));
        if (userByChat.userByPost.length > 0) {
            const filteredItems = userByChat.userByPost;
            setTableState({
                data: posts.data,
                loading: false,
                totalRows: posts.data.length,
                perPage: posts.data.length,
            });
        }
        setuserByPostChart(posts.data)
    };


    useEffect(() => {
        posts.data = [];
        posts.count = 0;
        posts.children = {};
        loadMorePosts();
    }, [networkId, sortBy]);

    useEffect(() => {
        console.log(
            "Filtering ",
            deletedPostIds,
            " from ",
            posts.data.map((post) => post.postId)
        );
        posts.data = posts.data.filter((p) => deletedPostIds.indexOf(p.postId) < 0);
        console.log(
            "Filtered result ",
            posts.data.map((post) => post.postId)
        );
        setPosts(JSON.parse(JSON.stringify(posts)));
        setuserByPostChart(posts.data)
    }, [deletedPostIds]);
    //   console.log("Rendering with posts ", posts);

    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    useEffect(() => {
        if (typingTimeOut) {
            clearTimeout(typingTimeOut);
        }
        if (searchInactiveUserId && searchInactiveUserId.trim().length > 3) {
            setTypingTimeOut(
                setTimeout(() => {
                    getInactiveUserPostChart();
                }, 1000)
            );
        } else if (!searchInactiveUserId) {
            // getInactiveUserPostChart();
        }
    }, [networkId, searchInactiveUserId, inactiveFromPage, inactiveFromMonth]);

    //   useEffect(() => {
    //     if (inactiveUserResp.length > 0) {
    //       const filteredItems = inactiveUserResp;
    //       setInactiveTableState({
    //         data: filteredItems,
    //         loading: false,
    //         totalRows: posts.data.length,
    //         perPage: posts.data.length,
    //       });
    //     }
    //   }, [inactiveUserResp]);

    useEffect(() => {
        if (typingTimeOut) {
            clearTimeout(typingTimeOut);
        }
        if (searchUserId && searchUserId.trim().length > 3) {
            setTypingTimeOut(
                setTimeout(() => {
                    getUserPostChart();
                }, 1000)
            );
        } else if (!searchUserId) {
            getUserPostChart();
        }
    }, [networkId, searchUserId, fromPage, sortBy]);

    useEffect(() => {
        if (userByChat.userByPost.length > 0) {
            const filteredItems = userByChat.userByPost;
            setTableState({
                data: posts.data,
                loading: false,
                totalRows: posts.data.length,
                perPage: posts.data.length,
            });
        }
    }, [userByChat]);



    const getUserPostChart = () => {
        setIsUserLoading(true);
        setUserByChat({ userByPost: [] });

    };




    const setPostHistogramChart = (postPerDay: liveStreamPostChartTypes[]) => {
        const postUserChart = {
            authorId: {},
            postId: {},
            reactionCount: {},
            commentCount: {},
            createdOn: {},
            ViewCount: {},
        }


        const historyList: chartUserSeriesTypes = {
            chart: {
                zoomType: "xy",
            },
            title: {
                text: "",
                style: {
                    display: "none",
                },
            },
            xAxis: [
                {
                    labels: {
                        format: "{value:%d %b %Y}",
                        style: {
                            fontWeight: "bold",
                            fontSize: "12px",
                            color: "rgb(100,100,100)",
                        },
                    },
                    categories: [],
                    crosshair: true,
                },
            ],
            yAxis: [
                {
                    title: {
                        text: "",
                        style: {
                            fontWeight: "bold",
                            fontSize: "12px",
                            color: "rgb(100,100,100)",
                        },
                    },
                    labels: {
                        format: "{value}",
                        style: {
                            fontWeight: "bold",
                            fontSize: "12px",
                            color: "rgb(100,100,100)",
                        },
                    },
                    opposite: true,
                },
            ],
            tooltip: {
                shared: true,
                headerFormat: "<p>{point.key:%Y-%m-%d}</p> </br>",
            },
            legend: { enabled: false },
            series: [],
            credits: {
                enabled: false,
            },
        };
        const seriesTemp_0: chartSeriesTypes = {
            name: "PostId",
            type: "spline",
            yAxis: 0,
            color: "rgb(128, 185, 42)",
            data: [],
        };
        const seriesTemp: chartSeriesTypes = {
            name: "Users",
            type: "spline",
            yAxis: 0,
            color: "rgb(100, 100, 100)",
            data: [],
        };
        const seriesTemp_2: chartSeriesTypes = {
            name: "Reactions",
            type: "spline",
            yAxis: 0,
            color: "rgb(85, 185, 185)",
            data: [],
        };
        const seriesTemp_3: chartSeriesTypes = {
            name: "Post",
            type: "spline",
            yAxis: 0,
            color: "rgb(41, 128, 185)",
            data: [],
        };
        const seriesTemp_4: chartSeriesTypes = {
            name: "Comments",
            type: "spline",
            yAxis: 0,
            color: "rgb(210, 77, 87)",
            data: [],
        };
        const seriesTemp_5: chartSeriesTypes = {
            name: "Sessions",
            type: "spline",
            yAxis: 0,
            color: "rgb(128, 185, 42)",
            data: [],
        };

        postPerDay.forEach((value) => {
            historyList.xAxis[0].categories.push(value.createdOn);
            seriesTemp_0.data.push(value.postId);
            seriesTemp.data.push(value.authorId);
            seriesTemp_2.data.push(value.reactionCount);
            seriesTemp_3.data.push(value.postId);
            seriesTemp_4.data.push(value.commentCount);
            seriesTemp_5.data.push(value.ViewCount);
        });

        // Rest of the code remains unchanged
        // ...

        setPostUserChartData(postUserChart.authorId);
        setReactionChartData(postUserChart.reactionCount);
        setPostChartData(postUserChart.postId);
        setCommentChartData(postUserChart.commentCount);
        setSessionChartData(postUserChart.ViewCount);

        setIsPostLoading(false);
    };

    const setuserByPostChart = (userByPost: object) => {
        setUserByChat({ userByPost: [] });
        const userByPostData = userByPost.map((ubm) => {
            const media = extractMedia(ubm, posts.children);
            // console.log(ubm.postedUserId)
            // console.log(media)


            return {
                userId: "x",
                postId: "x",
                createdAt: "x",
                reactionCount: 1,
                commentCount: 1,
                viewerCount: 1,
                totalPlayTime: 1,
                averagePlayTime: 1,
                playBack: "x"
            }
        });
        setUserByChat({ userByPost: userByPostData });
        setIsUserLoading(false);
    };

    const [viewers, setViewers] = useState<User[]>([]);
    const [showViewerOverlay, setShowViewerOverlay] = useState(false);
    const [nextPage, setNextPage] = useState<string | null>(null);

    const handleViewerClick = async (newPostId) => {
        setViewers([])
        try {
            // If the postId has changed, reset the viewers list and pagination token
            if (newPostId !== selectedPostId) {
                setViewers([]);               // Reset the viewers
                setNextPage(null);            // Reset the pagination token
            }

            setSelectedPostId(newPostId);  // Update the selectedPostId

            const viewerData = await fetchViewers(newPostId, network, nextPage);

            // If there's a nextPage, append the new users to the existing list, otherwise just set the new list
            setViewers((prevViewers: User[]) => [...prevViewers, ...viewerData.userList]);

            setNextPage(viewerData.nextPage); // Store the nextPage token or URL
            setShowViewerOverlay(true);
        } catch (error) {
            console.error("Error handling viewer click:", error);
        }
    };

    const loadMoreClick = async (newPostId) => {
        try {
            // If the postId has changed, reset the viewers list and pagination token
            if (newPostId !== selectedPostId) {
                setViewers([]);               // Reset the viewers
                setNextPage(null);            // Reset the pagination token
            }

            setSelectedPostId(newPostId);  // Update the selectedPostId

            const viewerData = await fetchViewers(newPostId, network, nextPage);

            // If there's a nextPage, append the new users to the existing list, otherwise just set the new list
            setViewers((prevViewers: User[]) => [...prevViewers, ...viewerData.userList]);

            setNextPage(viewerData.nextPage); // Store the nextPage token or URL
            setShowViewerOverlay(true);
        } catch (error) {
            console.error("Error handling viewer click:", error);
        }
    };


    const [selectedPostId, setSelectedPostId] = useState(null);
    const handleLoadMore = () => {
        console.log("nextPage" + nextPage)
        if (nextPage) {
            loadMoreClick(selectedPostId); // Assuming you have the postId stored somewhere like `selectedPostId`
        }
    };
    return (
        <div className="row g-5 g-xl-8">
            <Overlay isOpen={showOverlay} onClose={handleToggleOverlay}>
                <div className="overlay-content-scrollable">
                    <PostEntry key="pinPost" post={selectedPost!} children={posts.children} />
                </div>
            </Overlay>
            <Overlay isOpen={showViewerOverlay} onClose={() => setShowViewerOverlay(false)}>
                <div className="overlay-content-scrollable">
                    <b>Viewer List</b>
                    {viewers?.length ? (
                        <>
                            <table>
                                <thead>
                                    <tr>
                                        <th>UserId</th>
                                        <th>Display Name</th>
                                        <th>Joined Time</th>
                                        <th>ViewTime</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {viewers.map((viewer) => (
                                        <UserCard key={viewer.userId} user={viewer} />
                                    ))}
                                </tbody>
                            </table>
                            {nextPage && <button
                            className="buttonStyle"
                            onClick={handleLoadMore}>Load More</button>}
                        </>
                    ) : (
                        <p>No viewers available</p>
                    )}
                </div>
            </Overlay>






            <div className="col-xl-12">

            </div>

            <div className="col-xl-12">

                <div className="card card-xl-stretch">
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">

                            <span className="card-label fw-bolder fs-3 mb-1">
                                Amity LiveStream post stats
                            </span>
                            <span className="text-muted fw-bold fs-7">
                                List of Amity LiveStream posts.
                            </span>
                        </h3>
                        {!isUserLoading ? <div className="d-flex align-items-center">
                            <button className="bg-gray-500 text-white px-4 py-2 rounded" onClick={() => exportToCsv({ cardTitle: "table", data: posts.data, exportDate: dayjs() })} disabled={isUserLoading}>
                                <i className="fa fa-download text-white" />
                            </button>
                        </div> :
                            <div className="card-body">

                            </div>}
                        {/* <h3 className="card-title align-items-end flex-column">

                            <span className="text-muted fw-bold fs-7">
                                <div className="aside-search py-5">
                                    <input
                                        type="text"
                                        className="form-control w-100"
                                        value={searchUserId}
                                        onChange={(e) => {
                                            setSearchUserId(e.target.value);
                                        }}
                                        placeholder="Search UserID ..."
                                        disabled={isUserLoading}
                                    />
                                </div>
                            </span>
                        </h3> */}
                    </div>

                    {!isUserLoading ? (
                        <div className="card-body">
                            {
                                <DataTable

                                    noHeader={true}
                                    columns={[

                                        {
                                            name: "PostId",
                                            cell: (data) => data.postId
                                        },
                                        {
                                            name: "UserID",
                                            cell: (data) => data.postedUserId
                                        },
                                        {
                                            name: (
                                                <button onClick={() => sortTableByField("createdAt")}>
                                                    <p>CreateTime</p>
                                                </button>
                                            ),
                                            selector: (data) => data.createdAt,
                                        },
                                        {
                                            name: (
                                                <button onClick={() => sortTableByField("reactionsCount")}>
                                                    <p>Reaction</p>
                                                </button>
                                            ),
                                            selector: (data) => data.reactionsCount,
                                        },
                                        {
                                            name: (
                                                <button onClick={() => sortTableByField("commentsCount")}>
                                                    <p>Comment</p>
                                                </button>
                                            ),
                                            selector: (data) => data.commentsCount,
                                        },
                                        {
                                            name: (
                                                <button
                                                    onClick={() => sortTableByField("viewerCount")}
                                                >
                                                    <p>Viewer</p>
                                                </button>
                                            ),
                                            selector: (data) => data.viewerCount,
                                            cell: (data) => (
                                                <button className="buttonStyle" onClick={() => handleViewerClick(data.postId)}>
                                                {data.viewerCount}
                                            </button>
                                            ),
                                        },
                                        {
                                            name: (
                                                <button
                                                    onClick={() => sortTableByField("totalPlayTime")}
                                                >
                                                    <p>TotalPlayTime</p>
                                                </button>
                                            ),
                                            selector: (data) => secondToHMS(data.totalPlayTime),
                                        },
                                        {
                                            name: (
                                                <button
                                                    onClick={() => sortTableByField("averagePlayTime")}
                                                >
                                                    <p>Avg.PlayTime</p>
                                                </button>
                                            ),
                                            selector: (data) => secondToHMS(data.averagePlayTime),
                                        },

                                        {
                                            name: "Playback",
                                            cell: (data) => (
                                                <button
                                               className="buttonStyle"
                                                onClick={() => handleToggleOverlay(data)}>Open</button>

                                            ),
                                        },
                                    ]}

                                    data={tableState.data}
                                    progressPending={postLoading}
                                />

                            }


                            <nav
                                className="text-end"
                                style={{ display: tableState.totalRows > 0 ? "" : "none" }}
                            >
                                <span className="sc-bZkfAO sc-kLLXSd lmGpws OHwJB text-md">{`Items:1 - ${posts ? posts.data.length : 0}`}</span>
                                <div className="sc-ezWOiH jMMJgk">
                                    <button
                                        onClick={() => {
                                            loadMorePosts()
                                        }}
                                        id="pagination-previous-page"
                                        type="button"
                                        aria-label="Previous Page"
                                        aria-disabled="true"
                                        disabled={false}
                                        className="sc-gicCDI bvxQGL"
                                        style={{ display: fromPage >= 10 ? `` : `none` }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            role="presentation"
                                        >
                                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                        </svg>
                                    </button>
                                    <button
                                        onClick={() => {

                                            loadMorePosts()
                                        }}
                                        id="pagination-next-page"
                                        type="button"
                                        aria-label="Next Page"
                                        aria-disabled="true"
                                        disabled={false}
                                        className="sc-gicCDI bvxQGL"
                                        style={{
                                            display: tableState.totalRows % 10 == 0 ? "" : "none",
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            role="presentation"
                                        >
                                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                        </svg>
                                    </button>
                                </div>
                            </nav>
                        </div>
                    ) : (
                        <div className="card-body">
                            <Spinner loading={isUserLoading} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );



}

const secondToHMS = (seconds: number): string => {
    const wholeSeconds = Math.floor(seconds);
    const hours = Math.floor(wholeSeconds / 3600);
    const minutes = Math.floor((wholeSeconds % 3600) / 60);
    const remainingSeconds = wholeSeconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};
const PopupOverlay = ({ onClose, children }) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <div className="overlay-component">
            <div className="popup-content">
                {children}
                <button onClick={handleClose}>Close</button>
            </div>
        </div>
    );


};

