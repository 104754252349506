import React from "react";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getPosts, PostListData, SortBy } from "../../services/PostService";
import { deletedPostIdsState } from "../../state/States";
import Spinner from "../Spinner";
import { PostEntry } from "./PostEntry";

function getSortByTitle(sortBy: SortBy) {
  if (sortBy === "createdAt") return "Newest posts";
  if (sortBy === "lastActivity") return "Last active posts";
  if (sortBy === "comment-reactionsCount") return "Posts with popular comments";
  if (sortBy === "comment-flagCount")
    return "Posts with comment with most flags";
  if (sortBy === "flagCount") return "Posts with most flags";
  return "Most engaged posts";
}
export function PostList({
  sortBy,
  search,
}: {
  sortBy: SortBy;
  search?: string;
}) {
  const listId = useRef(Math.round(Math.random() * 1000000));
  const { networkId } = useParams();
  const [posts, setPosts] = useState<PostListData>({
    data: [],
    children: {},
    count: 0,
  });
  const [postLoading, setPostLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const deletedPostIds = useRecoilValue(deletedPostIdsState);

  const loadMorePosts = async function () {
    console.log("Loading more posts....");
    setPostLoading(true);
    const newPosts = networkId
      ? await getPosts(networkId, sortBy, posts.data.length, 10, search)
      : { count: 0, data: [], children: [] };
    console.log("Posts beforeLoad: ", posts);
    const updatedPosts = {
      count: newPosts.count,
      data: [...posts.data, ...newPosts.data],
      children: { ...posts.children, ...newPosts.children },
    };
    console.log("Posts loaded: ", updatedPosts);
    setPosts(updatedPosts);
    setPostLoading(false);
    setIsLoading(false);
    console.log("Rendering with posts ", updatedPosts);
  };

  useEffect(() => {
    setIsLoading(true);
    setPosts({
      data: [],
      children: {},
      count: 0,
    });
    setIsLoading(true);
    loadMorePosts();
  }, [networkId, search]);

  useEffect(() => {
    setIsLoading(true);
    console.log(
      "Filtering ",
      deletedPostIds,
      " from ",
      posts.data.map((post) => post.postId)
    );
    const filteredPosts = posts.data.filter(
      (p) => deletedPostIds.indexOf(p.postId) < 0
    );
    console.log(
      "Filtered result ",
      filteredPosts.map((post) => post.postId)
    );
    setPosts((prevPosts) => ({
      ...prevPosts,
      data: filteredPosts,
    }));
  }, [deletedPostIds]);
  
  return (
    <div className="col-xl-6">
      {<Spinner loading={isLoading} />}
      {!isLoading && (
        <div>
          <h2>{getSortByTitle(sortBy)}</h2>
          {posts.data.map((p) => (
            <PostEntry
              key={`entry-${listId.current}-${p.postId}`}
              post={p}
              children={posts.children}
            />
          ))}
          <button
            onClick={loadMorePosts}
            className="btn btn-primary w-100 text-center"
            disabled={postLoading}
            id="kt_widget_5_load_more_btn"
          >
            {postLoading ? (
              <span className="indicator-label">Loading...</span>
            ) : (
              <span className="indicator-label">More Posts</span>
            )}
          </button>
        </div>
      )}
    </div>
  );
}
