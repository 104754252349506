import axios, { AxiosError, AxiosResponse } from "axios";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { NetworkInfo } from "./AuthService";

dayjs.extend(relativeTime);

const splitArray = (array: object[], chunkSize: number): any[][] => {
  const result: any[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
};

export async function getViewerByPostId(postIds: string[],adminToken:String,networkId:string): Promise<any> {
  var path = " https://one-ktb-apiuat.convolab.ai/viewerCount/list";
  var productionNetworkIdKTB = process.env.KTBprodNetworkId || ''
  console.log("ENV:"+productionNetworkIdKTB)
  if(networkId == productionNetworkIdKTB){
    path = " https://one-ktb-api.convolab.ai/viewerCount/list"
  }
  const config = {
    headers: {
      Authorization: 'Bearer '+adminToken
    }
  };

  const queryParam = `postIds=${JSON.stringify(postIds)}`;
  const url = `${path}?${queryParam}`;
  try {
    console.log(url)
    const response: AxiosResponse<any> = await axios.get(url,config);
    console.log(response.data)
    console.log("success")
    return response.data; // Access the data property of the response
  } catch (error) {
    // Handle error
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      console.error("Axios error:", axiosError.message);
      throw axiosError;
    } else {
      console.error("Unknown error:", error);
      throw error;
    }
  }
}

export const arrayToQueryParam = (values: string[]): string => {
  const paramName = "postIds";
  const queryParam = `${paramName}=${values.join("&" + paramName + "=")}`;
  return queryParam;
};

export const getViewerData = async (values: object[],network:NetworkInfo): Promise<any[]> => {
  const resultSplit = splitArray(values, 50);

  let finishResult: any[] = [];
  for (const item of resultSplit) {

    const postIdArray = item.map((item) => item.postId);
    try {
      const mockResult = await getViewerByPostId(postIdArray,network.adminToken,network.networkId);
      const mockResultArray = mockResult.viewerCount
      finishResult = [...finishResult, ...mockResultArray];
    } catch (error) {
      // Handle error or skip the chunk
      console.error("Error in chunk:", error);
    }
  }
  return finishResult;
};

export const fetchViewers = async (
  postId: string,
  network: NetworkInfo,
  nextPageToken: string | null = null
): Promise<ViewerResponse> => {
  try {
      let baseApiUrl = 'https://gpdhfdg67i.execute-api.ap-southeast-1.amazonaws.com/user';
      const productionNetworkIdKTB = process.env.KTBprodNetworkId || '';

      console.log("ENV:" + productionNetworkIdKTB);
      if (network.networkId === productionNetworkIdKTB) {
          baseApiUrl = "https://one-ktb-api.convolab.ai/viewerCount/list";
      }

      const url = new URL(baseApiUrl);
      url.searchParams.append('postId', postId);

      // If there's a nextPageToken, add it as a query parameter
      if (nextPageToken) {
        var token = JSON.stringify(nextPageToken)

          url.searchParams.append('paginationToken', token);
      }

      console.log(url.search);

      const response = await fetch(url.toString(), {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${network.adminToken}`,
          }
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);

      return data;
  } catch (error) {
      console.error("Error fetching viewers:", error);
      throw error;
  }
};


interface User {
  postId: string;
  userId: string;
  streamId: string;
  displayName: string;
  viewTime: string; // Assuming viewTime remains a string representation of a number
  joinTimestamp: number; // Keeping as a number (Unix timestamp)
  lastUpdateTimestamp: number; // Similarly, a number (Unix timestamp)
}

interface ViewerResponse {
  userList: User[];
  nextPage: string | null;
}