'use strict'
import { PollAnswers, PollAnswersResponse } from '../../types'
import { useEffect, useState } from 'react'
import Spinner from '../Spinner'
import React from 'react'

const { downloadFile } = require('../../utils/Helper')
const { getPollAnswersData } = require('../../services/FileService')

const styles = {
  poll: 'styles_poll__1QbHf',
  question: 'styles_question__3qxRo',
  answers: 'styles_answers__17597',
  option: 'styles_option__1sn1C',
  result: 'styles_result__SJJfE',
  purple: 'styles_purple__1m4Cl',
  red: 'styles_red__1nmnb',
  blue: 'styles_blue__3LfCr',
  white: 'styles_white__1BIUt',
  cyan: 'styles_cyan__2Qp2d',
  fill: 'styles_fill__6jfwi',
  labels: 'styles_labels__2rz-F',
  answer: 'styles_answer__1w8xb',
  vote: 'styles_vote__1ltqi',
  votes: 'styles_votes__3IO-o',
}

const PollComponents = ({
  question,
  answers,
}: {
  question: string
  answers: PollAnswers[]
}) => {
  const [answer] = useState<PollAnswers[]>(answers)
  const [totalVotes, setTotalVotes] = useState<number>(0)
  const [ready, setReady] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    loadVotes()
  }, [])

  const loadVotes = () => {
    const totalVotes: number = answer.reduce(
      (total: number, value: PollAnswers) => {
        return total + parseInt(value.votes)
      },
      0,
    )
    setTotalVotes(totalVotes)
    setReady(true)
  }

  const calculatePercent = (votes: number, total: number): string => {
    if (votes === 0 && total === 0) {
      return '0%'
    }
    const percentage: number = (votes / total) * 100
    return percentage.toString() + '%'
  }

  const getAnswersDataByPaging = async (
    answer: PollAnswers,
    next: string | undefined
  ): Promise<PollAnswersResponse[]> => {
    const { data } = await getPollAnswersData(
      answer.network.adminToken,
      answer.pollId,
      answer.key,
      answer.network.region,
      next
    )
  
    if (typeof data == 'object') {
      const jsonResult: PollAnswersResponse[] = []
      const questionName = data.polls[0].question.replaceAll("\n", "").replaceAll(",", "").replaceAll("\t", "");
      for (let i = 0; i < data.answeredUsers.length; i++) {
        jsonResult.push({
          pollId: data.polls[0].pollId,
          question: questionName,
          answerIds: data.answeredUsers[i].answerIds[0],
          userId: data.answeredUsers[i].userId,
          createdAt: data.answeredUsers[i].createdAt,
          answerText: answer.option,
        })
      }
  
      if (data.paging?.next) {
        const pagingData = await getAnswersDataByPaging(answer, data.paging.next);
        jsonResult.push(...pagingData);
      }
  
      setIsLoading(false);
      return jsonResult;
    }
    // handle non-object data case, for example by throwing an error or returning an empty array
    throw new Error("Invalid data");
  };

  const getAnswersData = async (answer) => {
    setIsLoading(true)
    const jsonResult = await getAnswersDataByPaging(answer, undefined)
    const filename = jsonResult[0].pollId + '_' + jsonResult[0].answerText
    exportToCsv(jsonResult, filename)
  }

  const exportToCsv = (data: PollAnswersResponse[], filename: string) => {
    let headers = ['pollId,question,answerIds,answerText,userId,createdAt']

    let answersData = data.reduce((acc, userAnswer) => {
      const {
        pollId,
        question,
        answerIds,
        answerText,
        userId,
        createdAt,
      } = userAnswer
      acc.push(
        [pollId, question, answerIds, answerText, userId, createdAt].join(','),
      )
      return acc
    }, [])

    downloadFile(
      [...headers, ...answersData].join('\n').toString(),
      filename + '.csv',
      'text/csv',
    )
    setReady(true)
  }

  return (
    <article
      className={styles.poll}
      style={{
        textAlign: 'center',
        alignItems: 'center',
      }}
    >
      {
        <Spinner loading={isLoading} />
      }
      <h3
        className={styles.question}
        style={{
          borderWidth: '1px',
          alignSelf: 'center',
          fontWeight: 'bold',
          color: '#303030',
        }}
      >
        {question}
      </h3>
      <ul className={styles.answers}>
        {answer.map((answer) => {
          return (
            <li key={answer.option}>
              <button
                className={styles.result}
                style={{
                  color: '#5674E0',
                  borderColor: '#5674E0',
                  cursor: ready ? 'pointer' : 'default',
                }}
                onClick={() => {
                  if (parseInt(answer.votes) > 0 && ready) {
                    setReady(false)
                    getAnswersData(answer)
                  }
                }}
              >
                <div
                  className={styles.fill}
                  style={{
                    width: calculatePercent(parseInt(answer.votes), totalVotes),
                    backgroundColor: '#5674E03B',
                  }}
                >
                  <div className={styles.labels}>
                    <span style={{ color: '#5674E0' }}>
                      {calculatePercent(parseInt(answer.votes), totalVotes)}
                    </span>
                    <span
                      className={styles.answer}
                      style={{ color: '#5674E0' }}
                    >
                      {answer.option}
                    </span>
                  </div>
                </div>
              </button>
            </li>
          )
        })}
      </ul>
      <p className={styles.votes}>
        {totalVotes + ' vote' + (totalVotes !== 1 ? 's' : '')}
      </p>
    </article>
  )
}

export { PollComponents }
