import axios from "axios";
export async function deletePost(
  adminToken: string,
  region: string,
  postId: string
) {
  console.log(`Deleting post ${postId}`);
  return await axios({
    url: `https://api.${region}.amity.co/api/v3/posts/${postId}`,
    method: "delete",
    headers: {
      Authorization: `Bearer ${adminToken}`,
    },
  });
}
export async function pinPost(networkId: string, postId: string) {
  console.log(`Pining post ${postId}`);
  return await axios({
    url: `https://cpvp6wy03k.execute-api.ap-southeast-1.amazonaws.com/savePinPost`,
    method: "post",
    data: {
      networkId: networkId,
      postId: postId,
    },
  });
}

export async function unPinPost(networkId: string) {
  console.log(`UnPining post`);
  return await axios({
    url: `https://cpvp6wy03k.execute-api.ap-southeast-1.amazonaws.com/deletePinPost`,
    method: "post",
    data: {
      networkId: networkId,
    },
  });
}

export async function deleteStream(
  adminToken: string,
  region: string,
  streamId: string
) {
  console.log(`Deleting post ${streamId}`);
  return await axios({
    url: `https://api.${region}.amity.co/api/v3/video-streaming/${streamId}`,
    method: "delete",
    headers: {
      Authorization: `Bearer ${adminToken}`,
    },
  });
}
export async function deleteComment(
  adminToken: string,
  region: string,
  commentId: string
) {
  console.log(`Deleting comment ${commentId}`);
  return await axios({
    url: `https://api.${region}.amity.co/api/v3/comments/${commentId}`,
    method: "delete",
    headers: {
      Authorization: `Bearer ${adminToken}`,
    },
  });
}
