import { atom } from 'recoil'
import { AuthUser } from '../services/AuthService';
export const userState = atom<AuthUser|null>({
    key: 'userState',
    default: null
});

// export const networkState = atom<NetworkInfo|null>({
//     key: 'networkState',
//     default: null
// })

export const deletedPostIdsState = atom<string[]>({
    key: 'deletedPostIdsState',
    default: []
})

export const pinPostIdState = atom<string|null>({
    key: 'pinPostIdState',
    default: null
})