import { CognitoUser } from "@aws-amplify/auth";
import { Auth, API } from "aws-amplify";
import * as _ from "lodash";

interface NetworkConfig {
  adminToken: string;
  region: string;
  networkId: string;
  roles: string[];
}

export type ROLES =
  | "dashboard.analyst"
  | "dashboard.moderator"
  | "dashboard.admin"
  | "dashboard.external-analyst";
const ROLES_MENU_ACL: { [key: string]: string[] } = {
  "/activity/events": ["dashboard.moderator", "dashboard.admin"],
  "/social/liveStream-posts": ["dashboard.moderator", "dashboard.admin"],
  "/activity/reports": [
    "dashboard.analyst",
    "dashboard.external-analyst",
    "dashboard.admin",
  ],
  "/social/popular-posts": ["dashboard.moderator", "dashboard.admin"],
  "/social/latest-posts": ["dashboard.moderator", "dashboard.admin"],
  "/social/flagged-posts": ["dashboard.moderator", "dashboard.admin"],
  "/social/analytics": [
    "dashboard.analyst",
    "dashboard.external-analyst",
    "dashboard.admin",
  ],
  "/social/communities": [
    "dashboard.analyst",
    "dashboard.external-analyst",
    "dashboard.admin",
  ],
  "/social/users": ["dashboard.moderator", "dashboard.admin"],
  "/chat/analytics": [
    "dashboard.analyst",
    "dashboard.external-analyst",
    "dashboard.admin",
  ],
  "/chat/channels": ["dashboard.moderator", "dashboard.admin"],
  "/chat/channels/view": ["dashboard.moderator", "dashboard.admin"],
  "/chat/users": ["dashboard.moderator", "dashboard.admin"],
  "/chat/users/:userId": ["dashboard.moderator", "dashboard.admin"],
};
const ROLES_DEFAULT_PATH: { [key: string]: string } = {
  "dashboard.external-analyst": "/social/analytics",
  "dashboard.analyst": "/social/analytics",
  "dashboard.admin": "/social/analytics",
  "dashboard.moderator": "/social/popular-posts",
};
export interface NetworkInfo extends NetworkConfig {
  name: string;
}
export interface AuthUser {
  name: string;
  username: string;
  networks: NetworkInfo[];
}
interface DashboardCognitoUser extends CognitoUser {
  attributes: {
    name: string;
    "custom:networks": string;
  };
}
export async function getCurrentUser(
  user: DashboardCognitoUser
): Promise<AuthUser> {
  // const user: DashboardCognitoUser = await Auth.currentAuthenticatedUser({
  //     bypassCache: true
  // });
  return {
    name: user.attributes.name,
    networks: (
      await Promise.all(
        JSON.parse(user.attributes["custom:networks"] || "[]").map(
          async (info: NetworkConfig) => {
            try {
              const name = await getNetworkName(info);
              if (!name) return null;
              return {
                adminToken: info.adminToken,
                region: info.region,
                networkId: info.networkId,
                // Filter only dashboard roles and if roles don't exist add admin role as default
                roles: info.roles.filter((role) =>
                  role.startsWith("dashboard.")
                ) || ["dashboard.admin"],
                name,
              };
            } catch (e) {
              console.error(
                "Error while configuring network ",
                info,
                " error: " + e
              );
              return null;
            }
          }
        )
      )
    ).filter((n) => !!n),
    username: user.getUsername(),
  };
}
export function getRoleName(role: string) {
  return role.replace("dashboard.", "");
}
export async function getNetworkName(network: NetworkConfig) {
  // : NetworkInfo[]{
  try {
    const resp = await API.post("identifyapi", `/identify`, { body: network });
    // Cut unique id suffix out
    return resp.name.length <= 21
      ? resp.name
      : resp.name.substring(0, resp.name.length - 21);
  } catch (e) {
    console.error("Error while identifying network ", network, " error: " + e);
    return null;
  }
}

export async function logOut() {
  const result = await Auth.signOut();
  console.log(result);
  window.location.replace("/");
}
export function getUserRoles(user: AuthUser, networkId: string): string[] {
  for (let network of user.networks) {
    if (network.networkId === networkId) return network.roles;
  }
  return [];
}
export function userIsAccessible(
  user: AuthUser,
  networkId: string,
  path: string
) {
  const roles = getUserRoles(user, networkId);
  for (let p in ROLES_MENU_ACL) {
    const allowedRoles = ROLES_MENU_ACL[p];
    if (p === path) return _.intersection(roles, allowedRoles).length > 0;
  }
  return false;
}
export function getDefaultPage(user: AuthUser, networkId: string) {
  return ROLES_DEFAULT_PATH[getUserRoles(user, networkId)[0]];
}
