import React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsTreemap from "highcharts/modules/treemap";
import highchartsMore from "highcharts/highcharts-more";
import { useEffect, useState } from "react";
import {
  getSocialStatData,
  getEventStatData,
} from "../../../services/StatService";
import { getMAU } from "../../../services/AzureService";
import { extractReadableString } from "../../../services/EventService";
import * as dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { userState } from "../../../state/States";
import { useRecoilValue } from "recoil";
import ChartComponent from "../ChartComponent";
import { MauComponentDataTypes, PostComponentDataTypes } from "../../../types";

highchartsTreemap(Highcharts);
highchartsMore(Highcharts);

const CACHE_KEY = "mauData";

function getInfluencerName(name: string, roles: string[]) {
  const isExternal =
    roles.includes("dashboard.external-analyst") &&
    !roles.includes("dashboard.admin") &&
    !roles.includes("dashboard.analyst");
  return isExternal
    ? name.length > 2
      ? name.substring(0, 2) +
        name
          .substring(2)
          .split("")
          .map((n) => "*")
          .join("")
      : "***"
    : name.charAt(0).toUpperCase() + name.slice(1);
}

export function SocialCharts({
  dateRange: [fromDate, untilDate],
}: {
  dateRange: [dayjs.Dayjs, dayjs.Dayjs];
}) {
  const { networkId } = useParams();
  const user = useRecoilValue(userState);
  const roles = user?.networks.filter((n) => n.networkId === networkId)[0]
    .roles;
  const networkInfo = user?.networks.filter(
    (n) => n.networkId === networkId
  )[0];
  const [isLoading, setIsLoading] = useState(false);
  const [isMauLoading, setIsMauLoading] = useState(false);
  const [mauType, setMauType] = useState("daily");
  const [mauUntil, setMauUntil] = useState(3);
  const [postCommentChartsData, setPostCommentChartsData] =
    useState<PostComponentDataTypes>({
      count: [],
      uniqueUserData: [],
      max: 0,
      postByTypeData: [],
      postByTargetData: [],
      engagementByPosterData: [],
      topPostersData: [],
      topCommentersData: [],
      topReactorsData: [],
    });

  const [mauChartsData, setMauChartsData] = useState<MauComponentDataTypes>({
    date: [],
    mau: [],
  });

  const [eventChartsData, setEventChartsData] = useState<{
    eventByType: {
      name: string;
      data: [number, number];
    }[];
    communityMemberByDay: {
      name: string;
      data: [number, number, number][];
    }[];
  }>({ eventByType: [], communityMemberByDay: [] });
  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getSocialStatData(networkId!, fromDate, untilDate),
      getEventStatData(networkId!, fromDate, untilDate),
    ]).then(([postCommentStats, eventStats]) => {
      let newMax = 0;
      for (let value of postCommentStats.postByDay.count) {
        newMax = Math.max(newMax, value[1]);
      }
      for (let value of postCommentStats.commentByDay.count) {
        newMax = Math.max(newMax, value[1]);
      }
      setPostCommentChartsData({
        count: [
          { name: "Posts", data: postCommentStats.postByDay.count },
          { name: "Comments", data: postCommentStats.commentByDay.count },
        ],
        uniqueUserData: [
          {
            name: "Users who post",
            data: postCommentStats.postByDay.unique_user,
          },
          {
            name: "Users who comment",
            data: postCommentStats.commentByDay.unique_user,
          },
        ],
        postByTypeData: postCommentStats.postByType.map((d) => {
          return {
            name: d.name.charAt(0).toUpperCase() + d.name.slice(1),
            y: d.count,
          };
        }),
        postByTargetData: [
          {
            id: "user",
            name: "User",
            value: postCommentStats.postByTarget.user.count,
          },
          {
            id: "community",
            name: "Community",
            value: postCommentStats.postByTarget.community.count,
          },
          ...postCommentStats.postByTarget.community.names.map((n) => {
            return {
              id: n.name,
              name: n.name,
              value: n.count,
              parent: "community",
            };
          }),
          ...postCommentStats.postByTarget.user.names.map((n) => {
            return { id: n.name, name: n.name, value: n.count, parent: "user" };
          }),
        ],
        topPostersData: postCommentStats.topPosters.map((d) => {
          return { name: getInfluencerName(d.name, roles!), value: d.count };
        }),
        topCommentersData: postCommentStats.topCommenters.map((d) => {
          return { name: getInfluencerName(d.name, roles!), value: d.count };
        }),
        topReactorsData: postCommentStats.topReactors.map((d) => {
          return { name: getInfluencerName(d.name, roles!), value: d.count };
        }),
        engagementByPosterData: postCommentStats.engagementByPoster.map((d) => {
          return { name: getInfluencerName(d.name, roles!), value: d.count };
        }),
        max: newMax,
      });
      const EXCLUDE_EVENTS_SUFFIX = [
        "post.didCreate",
        "comment.didCreate",
        "user.didUpdate",
      ];
      setEventChartsData({
        eventByType: eventStats.eventByType
          .filter((ev) => {
            for (let suffix of EXCLUDE_EVENTS_SUFFIX)
              if (ev.name.endsWith(suffix)) return false;
            return true;
          })
          .map((ev) => {
            return { name: extractReadableString(ev.name), data: ev.byDay };
          }),
        communityMemberByDay: eventStats.communityMember.map((data) => {
          return { name: data.name, data: data.byDay };
        }),
      });
      setIsLoading(false);
    });
  }, [networkId, fromDate, untilDate]);

  const fetchDataFromAPI = async (
    networkInfo,
    mauType,
    fromDate,
    untilDate
  ) => {
    // Make your API call here and return the response data
    const response = await getMAU(
      networkInfo,
      mauType,
      fromDate,
      mauType === "daily" ? untilDate : `${mauUntil}`
    );
    return response.data.data;
  };

  const storeDataInLocalStorage = (mauType, mauMonth, data) => {
    // Store the data in local storage based on mauType
    localStorage.setItem(
      `${CACHE_KEY}-${networkId}-${mauType}-${mauMonth}`,
      JSON.stringify(data)
    );
  };

  const getDataFromLocalStorage = (mauType, mauMonth) => {
    // Retrieve the data from local storage based on mauType
    const cachedData = localStorage.getItem(
      `${CACHE_KEY}-${networkId}-${mauType}-${mauMonth}`
    );
    return cachedData ? JSON.parse(cachedData) : null;
  };

  const clearLocalStorageDaily = () => {
    // Clear the local storage data for "monthly" and "weekly" types daily
    const lastClearedDate = localStorage.getItem("lastClearedDate");
    const currentDate = new Date().toISOString().slice(0, 10);

    if (lastClearedDate !== currentDate) {
      localStorage.removeItem(`${CACHE_KEY}-*-monthly-*`);
      localStorage.removeItem(`${CACHE_KEY}-*-weekly-*`);
      localStorage.setItem("lastClearedDate", currentDate);
    }
  };

  useEffect(() => {
    setIsMauLoading(true);

    const cachedData = getDataFromLocalStorage(mauType, mauUntil);
    if (cachedData) {
      setMauChartsData(cachedData);
      setIsMauLoading(false);
    } else {
      fetchMau();
    }
  }, [networkId, fromDate, untilDate, mauType, mauUntil]);

  useEffect(() => {
    clearLocalStorageDaily();
  }, []);

  const fetchMau = () => {
    setIsMauLoading(true);
    fetchDataFromAPI(
      networkInfo,
      mauType,
      fromDate.format("YYYY-MM-DD"),
      mauType === "daily" ? untilDate.format("YYYY-MM-DD") : `${mauUntil}`
    )
      .then((response) => {
        // const groupedData = {};
        // response.forEach((item) => {
        //     const date = new Date(item.date).getTime();
        //     Object.entries(item.mau).forEach(([module, count]) => {
        //     if (!groupedData[module]) {
        //         groupedData[module] = [];
        //     }
        //     groupedData[module].push([date, count]);
        //     });
        // });
        // const mauData = Object.entries(groupedData).map(([module, data]) => ({
        //     name: module,
        //     data,
        // }));

        // const mauData = Object.keys(response[0].mau).map(key => {
        //     return {
        //         name: key,
        //         data: response.map(obj => obj.mau[key])
        //     };
        // })

        const dates = response.map((obj) => obj.date);

        const mauData = Object.keys(response[0].mau).map((key) => {
          return {
            name: key,
            data: response.map((obj) => obj.mau[key]),
          };
        });

        const transformedData = {
          date: dates,
          mau: mauData,
        };

        setMauChartsData(transformedData);
        setIsMauLoading(false);

        // Store the fetched data in local storage for "monthly" and "weekly" types
        if (mauType === "monthly" || mauType === "weekly") {
          storeDataInLocalStorage(mauType, mauUntil, mauData);
        }
      })
      .catch((error) => {
        console.error("Error fetching MAU data:", error);
        setIsMauLoading(false);
      });
  };

  const handleMauTypeChange = (data) => {
    setMauType(data);
  };

  const handleMauUntilChange = (data) => {
    setMauUntil(data);
  };

  const reloadMau = () => {
    const currentDate = new Date().toISOString().slice(0, 10);
    localStorage.removeItem(`${CACHE_KEY}-*-monthly-*`);
    localStorage.removeItem(`${CACHE_KEY}-*-weekly-*`);
    localStorage.setItem("lastClearedDate", currentDate);

    fetchMau();
  };

  return (
    <div>
      <div className="row g-5 g-xl-8">
       {/*  <div className="col-xl-12">
          <ChartComponent
            chartData={{
              cardTitle: "User Trends",
              subtitle: "# of daily, weekly, monthly active users",
              mauData: mauChartsData,
              mauType: handleMauTypeChange,
              mauMonth: handleMauUntilChange,
              reloadMau: reloadMau,
              isLoading: isMauLoading,
              fromDate: fromDate,
              untilDate: untilDate,
            }}
          />
        </div> */}
        <div className="col-xl-8">
          <ChartComponent
            chartData={{
              cardTitle: "New Posts & Comments",
              subtitle: "# of new posts and comments created",
              postCommentChartsData: postCommentChartsData,
              isLoading: isLoading,
              fromDate: fromDate,
              untilDate: untilDate,
            }}
          />
        </div>
        <div className="col-xl-4">
          <ChartComponent
            chartData={{
              cardTitle: "Post Types",
              subtitle: "# of created posts by types",
              postCommentChartsData: postCommentChartsData,
              isLoading: isLoading,
              fromDate: fromDate,
              untilDate: untilDate,
            }}
          />
        </div>
        <div className="col-xl-5">
          <ChartComponent
            chartData={{
              cardTitle: "Popular Feed",
              subtitle: "Feed with highest number of posts",
              postCommentChartsData: postCommentChartsData,
              isLoading: isLoading,
              fromDate: fromDate,
              untilDate: untilDate,
            }}
          />
        </div>
        <div className="col-xl-7">
          <ChartComponent
            chartData={{
              cardTitle: "Top Influencers",
              subtitle: "Users whose posts have the most engagement",
              postCommentChartsData: postCommentChartsData,
              isLoading: isLoading,
              fromDate: fromDate,
              untilDate: untilDate,
            }}
          />
        </div>
        {/*
        <div className="col-xl-4">
          <ChartComponent
            chartData={{
              cardTitle: "Top Posters",
              subtitle: "Users whose post the most",
              postCommentChartsData: postCommentChartsData,
              isLoading: isLoading,
              fromDate: fromDate,
              untilDate: untilDate,
            }}
          />
        </div>
        <div className="col-xl-4">
          <ChartComponent
            chartData={{
              cardTitle: "Top Commenters",
              subtitle: "Users whose comment the most",
              postCommentChartsData: postCommentChartsData,
              isLoading: isLoading,
              fromDate: fromDate,
              untilDate: untilDate,
            }}
          />
        </div>
        <div className="col-xl-4">
          <ChartComponent
            chartData={{
              cardTitle: "Top Reactors",
              subtitle: "Users whose react the most",
              postCommentChartsData: postCommentChartsData,
              isLoading: isLoading,
              fromDate: fromDate,
              untilDate: untilDate,
            }}
          />
        </div>
         
                <div className="col-xl-8">
                    <ChartComponent chartData={{cardTitle: "Posts, Comments & Reactions", subtitle:"% of users who post, comment & react", postCommentChartsData: postCommentChartsData, isLoading:isLoading, fromDate: fromDate, untilDate: untilDate}} />
                </div>
                <div className="col-xl-4">
                    <ChartComponent chartData={{cardTitle: "Community Member", subtitle:"Overall # of join and leave community", postCommentChartsData: postCommentChartsData, isLoading:isLoading, fromDate: fromDate, untilDate: untilDate}} />
                </div> */}
        {/* <div className="col-xl-12">
                    <ChartComponent chartData={{cardTitle: "Engagement by Post Type", subtitle:"% of user engagement by post type", postCommentChartsData: postCommentChartsData, isLoading:isLoading, fromDate: fromDate, untilDate: untilDate}} />
                </div> */}
        <div className="col-xl-13">
          <ChartComponent
            chartData={{
              cardTitle: "User Engagement",
              subtitle: "# of users who post or comment",
              postCommentChartsData: postCommentChartsData,
              isLoading: isLoading,
              fromDate: fromDate,
              untilDate: untilDate,
            }}
          />
        </div>
        {/* <div className="col-xl-13">
                    <ChartComponent chartData={{cardTitle: "User Engagement Heatmap", subtitle:"# of users who post or comment", postCommentChartsData: postCommentChartsData, isLoading:isLoading, fromDate: fromDate, untilDate: untilDate}} />
                </div> */}
      </div>
    </div>
  );
}
