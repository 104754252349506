import { ChartComponentTypes } from "../../types";
import { downloadFile } from "../../utils/Helper";

const getDateWithFormat = (d) => {
    const date = new Date(d);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    return `${day}-${month}-${year}`
}

export const exportToCsv = (chartData: ChartComponentTypes) => {
    let csvString: string = '';

    if (chartData.cardTitle === "New Posts & Comments") {
        csvString = `Date,${chartData.postCommentChartsData?.count[0].name},${chartData.postCommentChartsData?.count[1].name}\n`;
        chartData.postCommentChartsData?.count[0].data.forEach(([date, postCount], i) => {
            const [, commentCount] = chartData.postCommentChartsData!.count[1].data[i];
            csvString += `${getDateWithFormat(date)},${postCount},${commentCount}\n`;
        });
    }

    if (chartData.cardTitle === "Post Types") {
        console.log(chartData.postCommentChartsData?.postByTypeData)
        csvString = 'Type,Count\n';
        chartData.postCommentChartsData?.postByTypeData.forEach(({ name, y }) => {
            csvString += `${name},${y}\n`;
        });
    }

    if (chartData.cardTitle === "Popular Feed") {
        csvString = 'Feed,Name,Count\n';
        chartData.postCommentChartsData?.postByTargetData.forEach(({ parent, name, value }) => {
            const decodedParent = parent ? decodeURIComponent(parent) : 'ALL';
            const decodedName = decodeURIComponent(name);
            csvString += `${decodedParent},${decodedName},${value}\n`;
        });
    }

    if (chartData.cardTitle === "Top Influencers") {
        csvString = 'Name,Count\n';
        chartData.postCommentChartsData?.engagementByPosterData.forEach(({ name, value }) => {
            const decodedName = decodeURIComponent(name);
            csvString += `${decodedName},${value}\n`;
        });
    }
    if (chartData.cardTitle === "User Engagement") {
        csvString = `Date,${chartData.postCommentChartsData?.uniqueUserData[0].name},${chartData.postCommentChartsData?.uniqueUserData[1].name}\n'`;
        chartData.postCommentChartsData?.uniqueUserData[0].data.forEach(([date, postCount], i) => {
            const [, commentCount] = chartData.postCommentChartsData!.uniqueUserData[1].data[i];
            csvString += `${getDateWithFormat(date)},${postCount},${commentCount}\n`;
        });
    }

    if (chartData.cardTitle === "New Messages by day") {
        csvString = `Date,${chartData.chatChartsData?.messageByDay[0].name}\n`;
        chartData.chatChartsData?.messageByDay[0].data.forEach((data) => {
            csvString += `${getDateWithFormat(data[0])},${data[1]}\n`;
        })
    }

    if (chartData.cardTitle === "Total Messages by Type") {
        console.log("New Messages by day", chartData.chatChartsData?.messageByType);
        csvString = 'Type,Count\n';
        chartData.chatChartsData?.messageByType.forEach(({ name, y }) => {
            csvString += `${name},${y}\n`;
        });
    }

    if (chartData.cardTitle === "Users  by Day") {
        csvString = `Date,${chartData.chatChartsData?.uniqueUserData[0].name}\n`;
        chartData.chatChartsData?.uniqueUserData[0].data.forEach((data) => {
            csvString += `${getDateWithFormat(data[0])},${data[1]}\n`;
        })
    }

    if (chartData.cardTitle === "New Channel by Types") {
        csvString = `Date,${chartData.channelChartsData?.channelByTypeByDay[0].name}\n`;
        chartData.channelChartsData?.channelByTypeByDay[0].data.forEach((data) => {
            csvString += `${getDateWithFormat(data[0])},${data[1]}\n`;
        })
    }

    if (chartData.cardTitle === "Total Messages by Channel") {
        csvString = 'Channel,Count\n';
        chartData.chatChartsData?.messageByChannel.forEach(({ name, y }) => {
            csvString += `${name},${y}\n`;
        });
    }

      if (chartData.cardTitle === "Channels Count by Type") {
        csvString = 'type,totalCreatedChannels,totalDeletedChannels\n';
        chartData.channelChartsData?.getChannelCount.forEach(({ type, totalCreatedChannels, totalDeletedChannels}) => {
            csvString += `${type},${totalCreatedChannels},${totalDeletedChannels}\n`;
        });
    }

    if (chartData.cardTitle === "Messages Heatmap") {
        const hours = [...Array(25).keys()].slice(1);
        csvString = `${['Week', 'Date', ...hours.map(hour => `${hour - 1}:00`)].join(',')}\n`;

        const rows: any = chartData.chatChartsData?.messageByHour.reduce((acc, curr) => {
            const date = new Date(curr.key);
            const week = date.toLocaleDateString('en-US', { weekday: 'short' });
            const formattedDate = getDateWithFormat(curr.key);
            const hour = date.getHours();

            if (!acc[formattedDate]) {
                acc[formattedDate] = Array.from({ length: 9 }).fill('');
                acc[formattedDate][0] = week;
                acc[formattedDate][1] = formattedDate;
            }

            acc[formattedDate][hour + 2] = curr.doc_count;
            return acc;
        }, {});
        csvString += Object.entries(rows).map(([date, values]) => `${values.join(',')}`).join('\n');
    }

    if (csvString) {
        downloadFile(csvString, `${getDateWithFormat(chartData.fromDate)}_${getDateWithFormat(chartData.untilDate)}_${chartData.cardTitle.trim()}.csv`, 'text/csv');
    }
}