import React from "react";
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Header from "../components/Header";
import { PostEntry } from "../components/Posts/PostEntry";
import { PostList } from "../components/Posts/PostList";
import Spinner from "../components/Spinner";
import { getFlaggedPostsByUserid, PostListData } from "../services/PostService";
import { deletedPostIdsState, userState } from "../state/States";

function UserSocialPage() {
	const listId = Math.round(Math.random() * 1000000)
    const user = useRecoilValue(userState);
    const { networkId, userId } = useParams();
    const network = user.networks.filter(n => n.networkId === networkId)[0];
	const [isLoading, setIsLoading] = useState(false);
	const [postLoading, setPostLoading] = useState<boolean>(false)
	const deletedPostIds = useRecoilValue(deletedPostIdsState)

	const [posts, setPosts] = useState<PostListData>({
		data: [],
		children: {},
		count: 0,
	})

	const loadMorePosts = async function () {
		console.log('Loading more posts....')
		setPostLoading(true)
		const newPosts = networkId && userId
		  ? await getFlaggedPostsByUserid(networkId, userId , posts.data.length)
		  : { count: 0, data: [], children: [] }
		console.log('Posts beforeLoad: ', posts)
		posts.count = newPosts.count
		posts.data.push(...newPosts.data)
		Object.assign(posts.children, newPosts.children)
		console.log('Posts loaded: ', posts)
		setPostLoading(false)
		setPosts(JSON.parse(JSON.stringify(posts)))
		setIsLoading(false);
	  }

	useEffect(() => {
		setIsLoading(true);
		posts.data = []
		posts.count = 0
		posts.children = {}
		loadMorePosts()
	}, [networkId])

	useEffect(() => {
		setIsLoading(true);
		console.log(
		  'Filtering ',
		  deletedPostIds,
		  ' from ',
		  posts.data.map((post) => post.postId),
		)
		posts.data = posts.data.filter((p) => deletedPostIds.indexOf(p.postId) < 0)
		console.log(
		  'Filtered result ',
		  posts.data.map((post) => post.postId),
		)
		setPosts(JSON.parse(JSON.stringify(posts)))
	}, [deletedPostIds])

	// START WORKING ON IMAGE POST, DUPLICATE FIX FROM DASHBOARD TO ESEARCH EXTENSION, THEN ONTO ANALYTICS
	return (<div>
		<Header pageTitle={"User Social Page"} pageDescription={`User-${userId}'s flagged posts in `} networkName={network ? network.name : "no network"} networkId={network ? network.networkId : "no network"} />
		<div className="content d-flex flex-column flex-column-fluid" id="kt_content">

			<div className="post d-flex flex-column-fluid" id="kt_post">

				<div id="kt_content_container" className="container-xxl">

					<div className="row g-5 g-xl-8">
					<div className="col-xl-6">
						{
							<Spinner loading={isLoading} />
						}
						{!isLoading && <div>
						<h2>Flagged Posts</h2>
						{posts.data.map((p) => {
							return (
							<PostEntry
								key={'entry-' + listId + '-' + p.postId}
								post={p}
								children={posts.children}
							/>
							)
						})}
						<button
							onClick={loadMorePosts}
							className="btn btn-primary w-100 text-center"
							disabled={postLoading}
							id="kt_widget_5_load_more_btn"
						>
							{postLoading ? (
							<span className="indicator-label">Loading...</span>
							) : (
							<span className="indicator-label">More Posts</span>
							)}
						</button>
						</div>}
						</div>
					</div>

				</div>

			</div>

		</div></div>
	)
}

export default UserSocialPage