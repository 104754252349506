import axios from 'axios'
export function getFileUrl(fileId: string | undefined, region: string, type: 'image' | 'video' = 'image') {
    if (!fileId) return "assets/media/avatars/profile.png";
    if (type === "image") return `https://api.${region}.amity.co/api/v3/files/${fileId}/download?size=medium`;
    return `https://api.${region}.amity.co/api/v3/files/${fileId}/download`;
}

export async function getStreamUrl(accessToken: string, fileId: string | undefined, region: string) {
    return await axios({
        url: `https://api.${region}.amity.co/api/v3/video-streaming/${fileId}`,
        method: 'get',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
}

export async function getPollData(accessToken: string, pollId: string | undefined, region: string) {
    return await axios({
        url: `https://api.${region}.amity.co/api/v3/polls/${pollId}`,
        method: 'get',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
}


export async function getPollAnswersData(accessToken: string, pollId: string | undefined, answerId: string | undefined,region: string, paging: string | undefined) {
    return await axios({
        url: `https://api.${region}.amity.co/api/v3/polls/${pollId}/answers/${answerId}?options[limit]=100${paging ? '&options[token]='+paging : ''}`,
        method: 'get',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
}