import React from 'react';
import dayjs from 'dayjs';
interface User {
    postId: string;
    userId: string;
    streamId: string;
    displayName: string;
    viewTime: number; // Assuming viewTime remains a string representation of a number
    joinTimestamp: number; // Keeping as a number (Unix timestamp)
    lastUpdateTimestamp: number; // Similarly, a number (Unix timestamp)
}
interface UserCardProps {
    user: User;
}
const millisToHMS = (millis: number): string => {
    const seconds = Math.floor(millis / 1000);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};
const UserCard: React.FC<UserCardProps> = ({ user }) => (
    <tr>
        <td>{user.userId}</td>
        <td>{user.displayName}</td>
        <td>{dayjs(user.joinTimestamp).format('DD/MM/YYYY HH:mm:ss')}</td> {/* Formatting the joined time using dayjs */}
        <td>{millisToHMS(user.viewTime)}</td>
    </tr>
);
export default UserCard;
