import { API } from "aws-amplify";
import axios from "axios";
import { AmityComment, AmityPost, AmityStream } from "../types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export type SortBy =
  | "createdAt"
  | "lastActivity"
  | "engagementCount"
  | "comment-reactionsCount"
  | "flagCount"
  | "reactionsCount"
  | "commentsCount"
  | "comment-flagCount";
export interface PostListData {
  data: AmityPost[];
  count: number;
  children: { [key: string]: AmityPost };
}

export async function getPinPost(networkId: string) {
  // : NetworkInfo[]{
  return await axios({
    url: `https://cpvp6wy03k.execute-api.ap-southeast-1.amazonaws.com/getPinPost?networkId=${networkId}`,
    method: "get",
  });
}

export async function getPosts(
  networkId: string,
  sortBy: SortBy,
  from = 0,
  size = 10,
  search = ""
) {
  // : NetworkInfo[]{
  const resp: PostListData = await API.get(
    "dataapi",
    search.trim().length > 0
      ? `/posts?networkId=${networkId}&from=${from}&size=${size}&sortBy=${sortBy}&search=${search}`
      : `/posts?networkId=${networkId}&from=${from}&size=${size}&sortBy=${sortBy}`,
    {}
  );
  // Cut unique id suffix out
  return resp;
}

export async function getPostsByType(
  networkId: string,
  sortBy: SortBy,
  type: string,
  from = 0,
  size = 1000
) {
  // : NetworkInfo[]{
  const resp: PostListData = await API.get(
    "dataapi",
    `/posts?type=${type}&networkId=${networkId}&from=${from}&size=${size}&sortBy=${sortBy}`,
    {}
  );
  // Cut unique id suffix out
  return resp;
}

export async function getPostsById(networkId: string, postId: string) {
  // : NetworkInfo[]{
  const resp: PostListData = await API.get(
    "dataapi",
    `/posts?postId=${postId}&networkId=${networkId}`,
    {}
  );
  // Cut unique id suffix out
  return resp;
}

export async function getFlaggedPostsByUserid(
  networkId: string,
  userId: string,
  from = 0,
  size = 10
) {
  const resp: PostListData = await API.get(
    "dataapi",
    `/flagged_posts?networkId=${networkId}&userId=${userId}&from=${from}&size=${size}`,
    {}
  );

  return resp;
}

export async function getCommentsForPost(
  networkId: string,
  postId: string,
  from = 0,
  size = 100
) {
  // : NetworkInfo[]{
  const resp: { data: AmityComment[]; count: number } = await API.get(
    "dataapi",
    `/comments?networkId=${networkId}&from=${from}&size=${size}&postId=${postId}`,
    {}
  );
  // Cut unique id suffix out
  return resp.data;
}

export function extractText(object: AmityPost | AmityComment) {
  return object.data?.text;
}

export function extractTextFromStream(object: AmityStream) {
  return object.videoStreamings[0].title;
}

export function extractCreatedTime(post: AmityPost) {
  return dayjs(post.createdAt).from(dayjs());
}
export function extractLastActivityTime(post: AmityPost) {
  return dayjs(post.lastActivity).from(dayjs());
}
export function extractCommentCreatedTime(comment: AmityComment) {
  return dayjs(comment.createdAt).from(dayjs());
}

export function extractMedia(
  post: AmityPost,
  childrenData: { [key: string]: AmityPost }
): { type: "image" | "video" | "liveStream"; fileId: string }[] {
  if (!post.children || post.children.length == 0) return [];
  // console.log("POST " ,post);
  // console.log("CHILDRENDATA " ,childrenData);
  return post.children
    .map((childId) => {
      const childPost = childrenData[childId];
      if (!childPost) return null;
      if (childPost.dataType === "video")
        return { type: "video", fileId: childPost.data.videoFileId?.original };
      if (childPost.dataType === "image")
        return { type: "image", fileId: childPost.data.fileId };
      if (childPost.dataType === "liveStream")
        return { type: "liveStream", fileId: childPost.data.streamId };
      if (childPost.dataType === "poll")
        return { type: "poll", pollId: childPost.data.pollId };
      else return null;
    })
    .filter((e) => !!e) as { type: "image" | "video"; fileId: string }[];
}

export function extractTargetName(post: AmityPost) {
  if (post.targetType === "community")
    return post.community ? post.community?.displayName : post.targetId;
  else if (post.targetType === "user") return post.targetId;
  else if (post.targetId === "content") return "content";
  return null;
}
