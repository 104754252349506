import { useRecoilValue } from "recoil";
import Header from "../components/Header";
import { MessagesSearch, getMessagesByChannel } from '../services/MessageService';
import React, { useRef, useEffect, useState } from "react";
import { MessagesShow } from "../components/Chat/MessagesShow";
import { userState } from "../state/States";
import { useParams } from "react-router-dom";

function ChannelChatView() {

    const user = useRecoilValue(userState);
    const { networkId } = useParams();
    const network = user.networks.filter(n => n.networkId === networkId)[0];
    
    const [messages, setMessages] = useState<MessagesSearch>({ data: [], count: 0 });
    const [messagesLoading, setMessagesLoading] = useState<boolean>(false);
    const channelId = new URLSearchParams(window.location.search).get("channelId")
    const loadMoreMessages = async function () {
        console.log("Loading more message....");
        setMessagesLoading(true);
        const newMessages = await getMessagesByChannel(channelId, 'createdAt', messages.data.length, 7);
        messages.data.push(...newMessages.data);
        setMessagesLoading(false);
        setMessages(JSON.parse(JSON.stringify(messages)));
    }

    const el = useRef(null);
    const scrollHandler = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        if (scrollTop === 0) {
            loadMoreMessages();
        }
    };

    useEffect(() => {
        messages.data = [];
        messages.count = 0;
        loadMoreMessages();
        el.current.scrollIntoView({ block: 'end', behavior: 'smooth', inline: 'nearest' });
    }, [network]);

    return (
        <div>
            <div id="kt_header" className="header align-items-stretch">
                <Header pageTitle={"Conversation View"} pageDescription={`Chat history in ${channelId} from `} networkName={network ? network.name : "no network"} networkId={network ? network.networkId : "no network"} />
            </div>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container-xxl">
                        <div className="row g-6 g-xl-9 m-3 chat-box" onScroll={scrollHandler}>
                            <div className="d-flex flex-column-reverse">
                                {messages.data.map(m => <MessagesShow key={m.messageId} message={m} />)}
                                {(messagesLoading) ? <><div className="d-flex flex-center">Loading Message</div></> : <></>}
                            </div>
                            <div id={'el'} ref={el}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="typing-box">
                <input type="text" className="form-control w-100" placeholder="Enter text here..." disabled />
            </div>
        </div>
    )
}

export default ChannelChatView